import React, { Component, Fragment } from 'react';

import { Grid, withStyles, Button } from '@material-ui/core';

const styles = theme => ({
    formHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10
    },
    formHeading: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 24,
        color: '#09926E',
        margin: '0 0 10px',
        textAlign: 'left'
    },
    formNo: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#888888',
        margin: '0 0 10px',
    },
    inputLabel: {
        fontWeight: 'normal',
        marginBottom: 15,
        textAlign: 'left'
    },
    required: {
        color: 'red'
    },
    textField: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ECEFF1',
        color: 'black',
        height: 100,
        width: '100%',
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutline": {
                borderColor: '#ddd',
            }
        }
    },
    notchedOutline: {
        borderColor: '#ddd',
        '&:hover': {
            borderColor: '#ddd',
        }
    },
    focused: {
        "& $notchedOutline": {
            borderColor: '#ddd !important',
        }
    },
    textFieldError: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ECEFF1',
        color: 'black',
        width: '100%',
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutlineError": {
                borderColor: 'red !important',
            }
        }
    },
    notchedOutlineError: {
        borderColor: 'red',
        '&:hover': {
            borderColor: 'red !important',
        }
    },
    focusedError: {
        "& $notchedOutlineError": {
            borderColor: '#ddd !important',
        }
    },
    btn: {
        backgroundColor: '#09926E',
        color: '#fff',
        padding: '10px 35px',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: '#F44336',
            color: '#fff',
            boxShadow: 'none',
        }
    },
    prevBtn: {
        backgroundColor: '#616161',
        color: '#fff',
        padding: '10px 35px',
        boxShadow: 'none',
        marginRight: 10,
        "&:hover": {
            backgroundColor: '#000',
            color: '#fff',
            boxShadow: 'none',
        }
    }
});

class Donation extends Component {

    state = {
        availability: 'weekday',
        intrest: [
            { option: "Administration", checked: false },
            { option: "Events", checked: false },
            { option: "Field work", checked: false },
            { option: "Fundraising", checked: false },
            { option: "Newsletter production", checked: false },
            { option: "Volunteer coordination", checked: false }
        ],
        previousExperience: "",
        specialSkills: "",
        errorObj: {}
    }

    componentDidMount() {
        this.getStoredData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps != this.props)
            this.getStoredData();
    }

    getStoredData = () => {
        if (this.props.data) {
            const {
                availability,
                intrest,
                previousExperience,
                specialSkills
            } = this.props.data;

            this.setState({
                availability: availability,
                intrest: intrest,
                previousExperience: previousExperience,
                specialSkills: specialSkills
            })
        }
    }

    handleClickNext = () => {
        const {
            availability,
            intrest,
            previousExperience,
            specialSkills
        } = this.state;

        if (availability && intrest.length > 0 && previousExperience && specialSkills) {
            this.props.nextHandler('2', { availability, intrest, previousExperience, specialSkills })
        } else {
            this.handleValidation();
        }
    }

    handleChange = e => {
        const { errorObj } = this.state;

        if (errorObj[e.target.name])
            errorObj[e.target.name] = false;

        this.setState({
            [e.target.name]: e.target.value,
            errorObj
        })
    }

    handleValidation = () => {
        const {
            availability,
            intrest,
            previousExperience,
            specialSkills
        } = this.state;
        let tempErrorObj = {}

        if (!availability) {
            tempErrorObj.availability = true
        } else {
            tempErrorObj.availability = false
        }
        if (!intrest.length > 0) {
            tempErrorObj.intrest = true
        } else {
            tempErrorObj.intrest = false
        }
        if (!previousExperience) {
            tempErrorObj.previousExperience = true
        } else {
            tempErrorObj.previousExperience = false
        }
        if (!specialSkills) {
            tempErrorObj.specialSkills = true
        } else {
            tempErrorObj.specialSkills = false
        }


        this.setState({ errorObj: tempErrorObj })

    }

    render() {
        const { classes, nextHandler, backHandler } = this.props;
        const { availability, intrest, specialSkills, previousExperience } = this.state;

        return (
            <Fragment>
                <div className={classes.formHeader}>
                    <h4 className={classes.formHeading}>Availability</h4>
                    <h4 className={classes.formNo}>Step 2 - 4</h4>
                </div>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <label className="container fontsizee">
                                Weekday
                                    <input
                                    style={{ display: 'none' }}
                                    type='radio'
                                    name='availability'
                                    checked={availability === 'weekday'}
                                    value={availability}
                                    onChange={(e) => { this.setState({ availability: "weekday" }) }}
                                />
                                <span className='radiomark'></span>
                            </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <label className="container fontsizee">
                                Weekend
                                 <input
                                    style={{ display: 'none' }}
                                    type='radio'
                                    name='availability'
                                    checked={availability === 'weekend'}
                                    value={availability}
                                    onChange={(e) => { this.setState({ availability: "weekend" }) }}
                                />
                                <span className='radiomark'></span>
                            </label>
                        </Grid>
                    </Grid>
                    <h4 className={classes.formHeading}>Interests</h4>
                    <Grid container spacing={3}>
                        {
                            intrest.length > 0 &&
                            intrest.map((val, i) => (
                                <Grid item xs={12} sm={6}>
                                    <label className="container fontsizee">
                                        {val.option}
                                        <input
                                            style={{ display: 'none' }}
                                            type='checkbox'
                                            name='intrest'
                                            checked={val.checked}
                                            value={val.option}
                                            onChange={(e) => {
                                                let tempArr = intrest;
                                                tempArr[i].checked = !tempArr[i].checked
                                                this.setState({ intrest: tempArr })
                                            }}
                                        />
                                        <span className='checkmark'></span>
                                    </label>
                                </Grid>
                            ))
                        }
                    </Grid>

                    <div className="strange">
                        <h4 className={classes.formHeading}>Previous Volunteer Experience</h4>
                        <input
                            type='text'
                            name='previousExperience'
                            value={previousExperience}
                            onChange={this.handleChange}
                            style={{ height: '100px' }}
                            maxlength='100'
                        />
                        <h4 className={classes.formHeading}>Special Skills Or Qualifications</h4>
                        <input
                            type='text'
                            name='specialSkills'
                            value={specialSkills}
                            onChange={this.handleChange}
                            style={{ height: '100px' }}
                            maxlength='100'
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            className={classes.prevBtn}
                            onClick={backHandler}
                        >
                            previous
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.btn}
                            onClick={this.handleClickNext}
                        >
                            Next
                    </Button>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withStyles(styles)(Donation);