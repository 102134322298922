import React, { Component, Fragment } from 'react';

import { Grid, withStyles, InputLabel, CircularProgress, Button } from '@material-ui/core';

const styles = theme => ({
    formHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10
    },
    formHeading: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#09926E',
        margin: '0 0 10px',
        textAlign: 'left'
    },
    formNo: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#888888',
        margin: '0 0 10px',
    },
    inputLabel: {
        fontWeight: 'normal',
        marginBottom: 15,
        textAlign: 'left'
    },
    required: {
        color: 'red'
    },
    textField: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ECEFF1',
        color: 'black',
        // height: 30,
        width: '100%',
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutline": {
                borderColor: '#ddd',
            }
        }
    },
    notchedOutline: {
        borderColor: '#ddd',
        '&:hover': {
            borderColor: '#ddd',
        }
    },
    focused: {
        "& $notchedOutline": {
            borderColor: '#ddd !important',
        }
    },
    btn: {
        backgroundColor: '#09926E',
        color: '#fff',
        padding: '10px 35px',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: '#F44336',
            color: '#fff',
            boxShadow: 'none',
        }
    },
    prevBtn: {
        backgroundColor: '#616161',
        color: '#fff',
        padding: '10px 35px',
        boxShadow: 'none',
        marginRight: 10,
        "&:hover": {
            backgroundColor: '#000',
            color: '#fff',
            boxShadow: 'none',
        }
    }
});

class Donation extends Component {

    state = {
        photoFile: "",
        signatureFile: "",
        loading: false
    }

    handleSubmit = () => {
        const { photoFile, signatureFile } = this.state;

        if (photoFile && signatureFile) {
            this.setState({ loading: true })
            this.props.submitHandler({ photoFile, signatureFile });
        }
    }

    render() {
        const { classes, backHandler } = this.props;

        return (
            <Fragment>
                <div className={classes.formHeader}>
                    <h4 className={classes.formHeading}>Image Upload:</h4>
                    <h4 className={classes.formNo}>Step 4 - 4</h4>
                </div>
                <div>
                    <div className="strange">
                        <InputLabel className={classes.inputLabel} >Upload Your Photo: <span className={classes.required}>*</span></InputLabel>
                        <input
                            type='file'
                            name='pic'
                            accept='image/*'
                            onChange={(e) => { this.setState({ photoFile: e.target.files[0] }); console.log(e.target.files[0]) }}
                        />

                        <InputLabel className={classes.inputLabel} >Upload Signature Photo: <span className={classes.required}>*</span></InputLabel>
                        <input
                            type='file'
                            name='pic'
                            accept='image/*'
                            onChange={(e) => { this.setState({ signatureFile: e.target.files[0] }) }}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            className={classes.prevBtn}
                            onClick={backHandler}
                        >
                            previous
                        </Button>
                        {
                            !this.state.loading ?
                                <Button
                                    variant="contained"
                                    className={classes.btn}
                                    onClick={this.handleSubmit}
                                >
                                    Submit
                                </Button>
                                :
                                <Button
                                    variant="contained"
                                    className={classes.btn}
                                >
                                    <CircularProgress style={{ color: '#fff', width: 25, height: 25 }} />
                                </Button>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withStyles(styles)(Donation);