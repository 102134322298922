import React, { useState, useContext } from 'react';
import { Container, Grid, makeStyles, TextField, Button, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import baseUrl from '../../Utils/baseUrl';
import swal from 'sweetalert2';
import './Contact.css';
import ContextAPI from '../../ContextAPI/contextAPI';
import message from '../../Assets/Images/img-01.png';

const useStyles = makeStyles(theme => ({
    container: {
        border: '1px solid #d4d2d2',
        borderRadius: 10,
        padding: '90px 130px 88px 148px',
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.down("sm")]: {
            padding: 20,
        }
    },
    btn: {
        color: 'white',
        backgroundColor: '#09926e',
        width: '60%',
        height: 45,
        borderRadius: 25,
        "&:hover": {
            color: 'white',
            backgroundColor: '#161616',
        }
    }
}));

export default function Contact(props) {
    const classes = useStyles();
    const [feedback, setFeedback] = useState({ name: '', email: '', subject: '', message: '' });
    const [loading, setLoading] = useState(false);
    const { siteInfo } = useContext(ContextAPI);

    const handleSubmit = () => {
        setLoading(true);
        Axios({
            url: `${baseUrl}/contact`,
            method: "POST",
            data: {
                name: feedback.name,
                email: feedback.email,
                message: feedback.message
            }
        }).then(res => {
            // console.log(res.data);
            if (res && res.data.code === "400") {
                setLoading(false);
                swal.fire({
                    icon: 'error',
                    text: `${res.data.message}`,
                })
            } else {
                setLoading(false);
                swal.fire({
                    icon: "success",
                    text: "Thank you for your valuable Feedback"
                })
            }
        }).catch(err => {
            console.log(err);
            setLoading(false);
            if (err && err.response) {
                console.log(err, err.response.data);
                swal.fire({
                    icon: 'error',
                    text: `${err.response.data.message}`,
                })
            }
        })
    }

    return (
        <Container>
            <div className={`${classes.container} contact`}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <div
                            className="contact1_pic js_tilt"
                            style={{
                                willChange: 'transform',
                                transform: "perspective(300px) rotateX(0deg) rotateY(0deg)"
                            }}>
                            <img src={message} alt='IMG' />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={0} md={1}></Grid>
                    <Grid item xs={12} sm={5}>
                        <form className="contact1_form validate-form">
                            <span className="contact1_form_title">Feedback</span>
                            <div
                                className="wrap_input1 validate_input"
                                data-validate='Name is required'
                            >
                                <input
                                    className="input1"
                                    placeholder='Name'
                                    onChange={(e) => { setFeedback({ ...feedback, name: e.target.value }) }}
                                />
                                <span className="shadow_input1"></span>
                            </div>
                            <div
                                className="wrap_input1 validate_input"
                                data-validate='Email is required'
                            >
                                <input
                                    className="input1"
                                    type="email"
                                    placeholder='Email'
                                    onChange={(e) => { setFeedback({ ...feedback, email: e.target.value }) }}
                                />
                                <span className="shadow_input1"></span>
                            </div>
                            <div
                                className="wrap_input1 validate_input"
                                data-validate='Subject is required'
                            >
                                <input
                                    className="input1"
                                    placeholder='Subject'
                                    onChange={(e) => { setFeedback({ ...feedback, subject: e.target.value }) }}
                                />
                                <span className="shadow_input1"></span>
                            </div>
                            <div
                                className="wrap_input1 validate_input"
                                data-validate='Message is required'
                            >
                                <textarea
                                    className="input1"
                                    placeholder='Message'
                                    onChange={(e) => { setFeedback({ ...feedback, message: e.target.value }) }}
                                ></textarea>
                                <span className="shadow_input1"></span>
                            </div>
                        </form>
                        <div className="container_contact1_form_btn">
                            {
                                !loading ?
                                    <button onClick={handleSubmit} className="contact1_form_btn">
                                        <span>
                                            Send Email
                                </span>
                                    </button>
                                    :
                                    <button className="contact1_form_btn">
                                        <CircularProgress style={{ color: '#fff', width: 25, height: 25 }} />
                                    </button>
                            }
                        </div>
                    </Grid>
                </Grid>
                <div className="back">
                    <Grid container spacing={3}>
                        <Grid item sm={12} md={4}>
                            <div className="single_div">
                                <h3>Address</h3>
                                <hr style={{ color: 'white', margin: '10px 0' }} />
                                {
                                    siteInfo && siteInfo.address &&
                                    <p className="contact_details">{siteInfo.address}</p>
                                }
                            </div>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <div className="single_div">
                                <h3>Phone</h3>
                                <hr style={{ color: 'white', margin: '10px 0' }} />
                                <p className="contact_details">
                                    {
                                        siteInfo && siteInfo.phone && <a href={`tel:${siteInfo.phone}`} style={{ textDecoration: "none", color: 'inherit' }}>{siteInfo.phone}</a>
                                    }
                                </p>
                            </div>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <div className="single_div">
                                <h3>Email</h3>
                                <hr style={{ color: 'white', margin: '10px 0' }} />
                                <p className="contact_details">
                                    {
                                        siteInfo && siteInfo.email &&
                                        <a href={`mailto:${siteInfo.email}`} style={{ textDecoration: "none", color: 'inherit' }}>{siteInfo.email}</a>
                                    }
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Container>
    )
}