import React, { Fragment, useEffect } from 'react';
import { makeStyles, Button, Dialog, IconButton, CircularProgress, Table, TableBody, TableHead, TableRow, TableCell, Chip, Divider, Grid } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Axios from 'axios';
import baseUrl from '../../Utils/baseUrl';
import ContextAPI from '../../ContextAPI/contextAPI';
import swal from 'sweetalert2';
import moment from 'moment';

const useStles = makeStyles(theme => ({
    divBox: {
        padding: 20
    },
    tableCell: {
        fontFamily: 'inherit',
        backgroundColor: '#09926E',
        color: 'white',
        textAlign: 'left',
        fontWeight: 'bold',

    },
    tableCellBody: {
        textAlign: 'left',
    },
}));

export default function Modal(props) {
    const classes = useStles();
    const {
        children,
        transactionId,
        invoiceNumber,
        date,
        totalAmount,
        donorEmail,
        donorName,
        allData,
        paymentStatus
    } = props;
    const [open, setOpen] = React.useState(false);
    const [loader, setLoader] = React.useState(true);
    const [data, setData] = React.useState({});
    const [pdf, setPdf] = React.useState(null);
    const [pdfLoader, setPdfLoader] = React.useState(false);
    const [paymentMethod, setPaymentMethod] = React.useState("");

    // useEffect(() => {


    // }, []);

    const handleClickOpen = () => {
        Axios({
            url: `${baseUrl}/donation-history-detail`,
            method: "POST",
            params: { transaction_id: transactionId }
        }).then(res => {
            // console.log(res.data);
            setLoader(false)
            if (res && res.data.code === "400") {
                swal.fire({
                    icon: 'error',
                    text: `${res.data.message}`,
                })
            } else {
                if (res.data.data.length > 0) {
                    setData(res.data.data);
                    setPaymentMethod(res.data.data[0].payment_type);
                }
            }

        }).catch(err => {
            console.log(err)
            setLoader(false)
            if (err && err.response) {
                console.log(err.response.data);
                swal.fire({
                    icon: 'error',
                    text: `${err.response.data.message}`,
                })
            }
        })

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const downloadSingleDonationPdf = () => {
        const token = localStorage.getItem("token");
        setPdfLoader(true);

        Axios({
            url: `${baseUrl}/single-donation-pdf/${transactionId}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            // console.log(res.data);
            if (res.data.message === "PDF Generated") {
                setPdfLoader(false);
                window.open(res.data.url, '_blank');
                // setPdf(res.data.url);
            } else {
                swal.fire({
                    icon: 'error',
                    text: `${res.data.message}`,
                })
            }

        }).catch(err => {
            console.log(err)
            if (err && err.response) {
                console.log(err.response.data)
                setPdfLoader(false);
                swal.fire({
                    icon: 'error',
                    text: `${err.response.data.message}`,
                })
            }
        })
    }

    // console.log(allData)

    return (
        <Fragment>
            <div style={{ cursor: "pointer" }} onClick={handleClickOpen}>
                {children}
            </div>
            <Dialog
                fullWidth
                maxWidth="md"
                onClose={handleClose}
                open={open}
            >
                {
                    loader ?
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: 300
                            }}
                        >
                            <CircularProgress style={{ color: '#09926E', width: 80, height: 80 }} />
                        </div>
                        :
                        <div className={classes.divBox}>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon fontSize="medium" />
                                </IconButton>

                            </div>
                            <h2 style={{ textAlign: 'center' }}>Invoice</h2>
                            <div style={{ height: 25 }} />
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <strong style={{ marginRight: 5 }}>Invoice Number:</strong>
                                    <Chip
                                        label={invoiceNumber}
                                        style={{ backgroundColor: '#fd5a5a', color: '#fff', width: 50 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <strong style={{ marginRight: 5 }}>Transaction ID:</strong>{transactionId}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <strong style={{ marginRight: 5 }}>Payment Method:</strong>
                                    <strong style={{ color: '#fd5a5a', fontSize: 14 }}>{paymentMethod}</strong>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <strong style={{ marginRight: 5 }}>Name:</strong>{donorName}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <strong style={{ marginRight: 5 }}>Email:</strong>{donorEmail}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <strong style={{ marginRight: 5 }}>Date:</strong>{date}
                                </Grid>
                            </Grid>
                            {/* <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: "bold", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderBottom: "none" }}>

                                        </TableCell>
                                        <TableCell style={{ fontWeight: "bold", borderBottom: "none" }}>Transaction ID:</TableCell>
                                        <TableCell style={{ borderBottom: "none" }}>{transactionId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: "bold", borderBottom: "none" }}>Donor Name:</TableCell>
                                        <TableCell style={{ borderBottom: "none" }}> {donorName}</TableCell>
                                        <TableCell style={{ fontWeight: "bold", borderBottom: "none" }}>Donor E-Mail Address:</TableCell>
                                        <TableCell style={{ borderBottom: "none" }}>{donorEmail}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table> */}

                            {/* <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: "bold", borderBottom: "none" }}>Date & Time:</TableCell>
                                        <TableCell style={{ borderBottom: "none" }}>{date}</TableCell>
                                        <TableCell style={{ fontWeight: "bold", borderBottom: "none" }}>Total Amount:</TableCell>
                                        <TableCell style={{ borderBottom: "none", fontWeight: "bold", color: "#fd5a5a" }}>${parseFloat(totalAmount).toFixed(2)}</TableCell>
                                        <TableCell style={{ fontWeight: "bold", borderBottom: "none" }}>Payment Method</TableCell>
                                        <TableCell style={{ borderBottom: "none" }}>
                                            <Chip
                                                 label={paymentMethod === "stripe" ? "Credit Card" : "Paypal"}
                                                style={{ backgroundColor: '#09926E', color: '#fff' }}
                                            />
                                        </TableCell> 
                                        <TableCell style={{ fontWeight: "bold", borderBottom: "none" }}>Payment Status:</TableCell>
                                        <TableCell style={{ borderBottom: "none" }}>{paymentStatus}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table> */}
                            <div style={{ height: 20 }} />
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell}>Donation Type</TableCell>
                                        <TableCell className={classes.tableCell}>Donation Sub-Type</TableCell>
                                        <TableCell className={classes.tableCell}>Shareholders</TableCell>
                                        <TableCell className={classes.tableCell} style={{ textAlign: 'right' }}>Amount</TableCell>
                                        <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>Quantity</TableCell>
                                        <TableCell className={classes.tableCell} style={{ textAlign: 'right' }}>Total</TableCell>
                                        {/* <TableCell className={classes.tableCell}>Payment Method</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.length > 0 && data.map((row, index) => (
                                            <TableRow key={index} className={classes.tableRow}>
                                                <TableCell className={classes.tableCellBody}>{row.donation_type}</TableCell>
                                                <TableCell className={classes.tableCellBody}>{row.donation_type_option}</TableCell>
                                                <TableCell className={classes.tableCellBody}>{row.donation_shared_name}</TableCell>
                                                <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>${parseFloat(row.net_donation_amount).toFixed(2)}</TableCell>
                                                <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>{row.quantity}</TableCell>
                                                {/* <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>${(parseFloat(row.net_donation_amount) * parseFloat(row.quantity)).toFixed(2)}</TableCell> */}
                                                <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>${parseFloat(row.donation_amount).toFixed(2)}</TableCell>
                                                {/* <TableCell className={classes.tableCellBody}>
                                                    <strong style={{ color: '#fd5a5a' }}>{row.payment_type}</strong>
                                                </TableCell> */}
                                            </TableRow>
                                        )
                                        )}
                                </TableBody>
                            </Table>
                            <div >
                                <Table style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "none", padding: 3, fontWeight: 'bold' }}>Donation:</TableCell>
                                            <TableCell style={{ borderBottom: "none", textAlign: 'right', padding: 3 }}>${(parseFloat(totalAmount) - parseFloat(allData.merchan_fee) - parseFloat(allData.admin_fee)).toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "none", padding: 3, fontWeight: 'bold' }}>Admin Fee:</TableCell>
                                            <TableCell style={{ borderBottom: "none", textAlign: 'right', padding: 3 }}>${allData.admin_fee}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "none", padding: 3, fontWeight: 'bold' }}>Merchant Fee:</TableCell>
                                            <TableCell style={{ borderBottom: "none", textAlign: 'right', padding: 3 }}>${allData.merchan_fee}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "none", padding: 3, fontWeight: 'bold' }}>Total:</TableCell>
                                            <TableCell style={{ borderBottom: "none", textAlign: 'right', padding: 3 }}>${parseFloat(totalAmount).toFixed(2)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {/* <ul style={{ width: 300 }}>
                                    <li style={{ listStyle: 'none' }}>
                                        <strong style={{ marginRight: 5 }}>Donation:</strong>
                                        <p style={{ textAlign: 'right' }}></p>
                                    </li>
                                    <li style={{ listStyle: 'none' }}>
                                        <strong style={{ marginRight: 5 }}>Admin Fee:</strong>
                                    </li>
                                    <li style={{ listStyle: 'none' }}>
                                        <strong style={{ marginRight: 5 }}>Merchant Fee:</strong>
                                    </li>
                                    <li style={{ listStyle: 'none' }}>
                                        <strong style={{ marginRight: 5 }}>Total Paid:</strong>
                                    </li>
                                </ul> */}
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}>
                                {
                                    pdfLoader ?
                                        <Button
                                            style={{ backgroundColor: "#fd5a5a", color: '#fff' }}
                                            variant="contained"
                                        >
                                            <CircularProgress style={{ color: '#fff', width: 25, height: 25 }} />
                                        </Button>
                                        :
                                        <Button
                                            style={{ backgroundColor: "#fd5a5a", color: '#fff' }}
                                            variant="contained"
                                            onClick={downloadSingleDonationPdf}
                                        >
                                            View PDF
                                        </Button>
                                }
                            </div>
                            {/* <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}>
                                {
                                    pdf ?
                                        <a href={pdf} target="blank" style={{ textDecoration: "none" }}>
                                            <Button
                                                style={{ backgroundColor: "#fd5a5a", color: '#fff' }}
                                                variant="contained"

                                            >
                                                Download PDF
                                        </Button>
                                        </a>
                                        :
                                        pdfLoader ?
                                            <Button
                                                style={{ backgroundColor: "#fd5a5a", color: '#fff' }}
                                                variant="contained"
                                            >
                                                <CircularProgress style={{ color: '#fff', width: 25, height: 25 }} />
                                            </Button>
                                            :
                                            <Button
                                                style={{ backgroundColor: "#fd5a5a", color: '#fff' }}
                                                variant="contained"
                                                onClick={downloadSingleDonationPdf}
                                            >
                                                Generate PDF
                                            </Button>
                                }
                            </div> */}
                        </div>
                }
            </Dialog>
        </Fragment>
    );
}