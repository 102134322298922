import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
	Container,
	Grid,
	Button,
	withStyles,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	CircularProgress,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//Icons
import { FaRegUserCircle } from "react-icons/fa";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import LockIcon from "@material-ui/icons/Lock";

import Axios from "axios";
import baseUrl from "../../Utils/baseUrl";
import ContextAPI from "../../ContextAPI/contextAPI";
import swal from "sweetalert2";
import DonationTable from "./Table";
import "./DonationHistory.css";

const styles = (theme) => ({
	profileNavHeading: {
		listStyle: "none",
		textDecoration: "none",
		color: "#008081",
		fontSize: "21px",
		marginBottom: "20px",
	},
	profileNav: {
		listStyle: "none",
		textDecoration: "none",
		color: "#474747",
		fontSize: "17px",
		margin: "5px",
		cursor: "pointer",
	},
	listItem: {
		backgroundColor: "#fff",
		color: "#666666",
		margin: 3,
		"&:hover": {
			backgroundColor: "#09926E",
			color: "#fff",
		},
	},
	listItemActive: {
		margin: 3,
		backgroundColor: "#087059",
		color: "#fff",
	},
	formHeader: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		marginTop: 10,
	},
	formHeading: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#09926E",
		margin: "0 0 10px",
		textAlign: "left",
	},
	formNo: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#888888",
		margin: "0 0 10px",
	},
	inputLabel: {
		fontWeight: "normal",
		marginBottom: 15,
		textAlign: "left",
	},
	textField: {
		fontFamily: "'Roboto', sans-serif",
		backgroundColor: "#ECEFF1",
		color: "black",
		// height: 30,
		width: "100%",
		marginBottom: 20,
		"&:hover": {
			"& $notchedOutline": {
				borderColor: "#ddd",
			},
		},
	},
	notchedOutline: {
		borderColor: "#ddd",
		"&:hover": {
			borderColor: "#ddd",
		},
	},
	focused: {
		"& $notchedOutline": {
			borderColor: "#ddd !important",
		},
	},
	textFieldError: {
		fontFamily: "'Roboto', sans-serif",
		backgroundColor: "#ECEFF1",
		color: "black",
		width: "100%",
		marginBottom: 20,
		"&:hover": {
			"& $notchedOutlineError": {
				borderColor: "red !important",
			},
		},
	},
	notchedOutlineError: {
		borderColor: "red",
		"&:hover": {
			borderColor: "red !important",
		},
	},
	focusedError: {
		"& $notchedOutlineError": {
			borderColor: "#ddd !important",
		},
	},
	btn: {
		backgroundColor: "#DC3545",
		color: "#fff",
		padding: "10px 35px",
		boxShadow: "none",
		marginTop: "10px",
		width: "100%",
		"&:hover": {
			backgroundColor: "#DC3545",
			color: "#fff",
			boxShadow: "none",
		},
	},
	hide: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
});

class History extends Component {
	state = {
		startDate: "",
		endDate: "",
		donationHistory: "",
		totalDonations: "",
		totalAmountDonated: "",
		loading: true,
		pdf: false,
	};

	static contextType = ContextAPI;

	componentDidMount() {
		setTimeout(() => {
			// const [startDate, setStartDate] = useState(new Date());
			// const [endDate, setEndDate] = useState(new Date());
			this.setState({
				startDate: new Date(),
				endDate: new Date(),
			});
			const { user, isFirebaseUser, isGuestUser } = this.context;
			const token = localStorage.getItem("token");

			if (!user.email) {
				this.props.history.push("/login");
			} else if (!isGuestUser) {
				Axios({
					url: `${baseUrl}/donation-summary`,
					method: "POST",
					// headers: {
					//     Authorization: `Bearer ${token}`
					// },
					params: {
						email: user.email,
						startDate: "",
						endDate: "",
						type: "",
					},
				})
					.then((res) => {
						// console.log(res.data);
						if (res && res.data.code === "400") {
							swal.fire({
								icon: "error",
								text: `${res.data.message}`,
							});
						} else {
							if (res.data.data.length > 0) {
								this.setState({
									donationHistory: res.data.data,
									// totalDonations: res.data.data.total_donation,
									// totalAmountDonated: res.data.data.total_amount,
									loading: false,
								});
							}
						}
					})
					.catch((err) => {
						console.log(err);
						if (err && err.response) {
							console.log(err.response.data);
							this.setState({ loading: false });
							swal.fire({
								icon: "error",
								text: `${err.response.data.message}`,
							});
						}
					});
			} else {
				this.setState({ loading: false });
			}
		}, 3000);
	}

	render() {
		const { classes } = this.props;
		const { user } = this.context;
		const { loading, totalAmountDonated, totalDonations, donationHistory } =
			this.state;

		const handleDate = (endDate) => {
			// e.preventDefault();
			this.setState({ endDate: endDate });
			this.setState({ loading: true });
			Axios({
				url: `${baseUrl}/donation-summary`,
				method: "POST",
				// headers: {
				//     Authorization: `Bearer ${token}`
				// },
				params: {
					email: user.email,
					startDate: this.state.startDate.toISOString().split("T")[0],
					endDate: this.state.endDate.toISOString().split("T")[0],
					type: "",
				},
			})
				.then((res) => {
					// console.log(res.data);
					if (res && res.data.code === "400") {
						swal.fire({
							icon: "error",
							text: `${res.data.message}`,
						});
					} else {
						if (res.data.data.length > 0) {
							this.setState({
								donationHistory: res.data.data,
								// totalDonations: res.data.data.total_donation,
								// totalAmountDonated: res.data.data.total_amount,
								loading: false,
								pdf: true,
							});
						}
					}
				})
				.catch((err) => {
					console.log(err);
					if (err && err.response) {
						console.log(err.response.data);
						this.setState({ loading: false });
						swal.fire({
							icon: "error",
							text: `${err.response.data.message}`,
						});
					}
				});
		};

		const handlePdf = (e) => {
			// e.preventDefault();
			this.setState({ loading: true });
			Axios({
				url: `${baseUrl}/donation-summary-pdf`,
				method: "POST",
				// headers: {
				//     Authorization: `Bearer ${token}`
				// },
				params: {
					email: user.email,
					startDate: this.state.startDate.toISOString().split("T")[0],
					// startDate: "2021-11-01",
					endDate: this.state.endDate.toISOString().split("T")[0],
					// endDate: "2021-11-30",
					// type: "",
				},
			})
				.then((res) => {
					// console.log(res.data);
					if (res && res.data.code === "400") {
						swal.fire({
							icon: "error",
							text: `${res.data.message}`,
						});
					} else {
						console.log(res.data);
						// if (res.data.data.length > 0) {
						this.setState({
							// donationHistory: res.data.data,
							// totalDonations: res.data.data.total_donation,
							// totalAmountDonated: res.data.data.total_amount,
							loading: false,
							pdf: true,
						});
						window.open(res.data.url, "_blank");
						// }
					}
				})
				.catch((err) => {
					console.log(err);
					if (err && err.response) {
						console.log(err.response.data);
						this.setState({ loading: false });
						swal.fire({
							icon: "error",
							text: `${err.response.data.message}`,
						});
					}
				});
		};

		return (
			<Container maxWidth="lg">
				<Grid container spacing={3}>
					<Grid item className={classes.hide} sm={4} md={3}>
						<List>
							<Link
								to="/profile"
								style={{ textDecoration: "none", color: "#666666" }}
							>
								<ListItem
									className={
										window.location.pathname === "profile"
											? classes.listItemActive
											: classes.listItem
									}
								>
									<ListItemIcon
										style={{
											minWidth: "40px",
											width: "40px",
											color: "inherit",
										}}
									>
										<FaRegUserCircle
											style={{ fontSize: 21, color: "inherit" }}
										/>
									</ListItemIcon>
									<ListItemText primary="My Profile" />
								</ListItem>
							</Link>
							<Link
								to="/edit-profile"
								style={{ textDecoration: "none", color: "#666666" }}
							>
								<ListItem
									className={
										window.location.pathname === "/edit-profile"
											? classes.listItemActive
											: classes.listItem
									}
								>
									<ListItemIcon
										style={{
											minWidth: "40px",
											width: "40px",
											color: "inherit",
										}}
									>
										<EditIcon style={{ fontSize: 21, color: "inherit" }} />
									</ListItemIcon>
									<ListItemText primary="Edit Profile" />
								</ListItem>
							</Link>
							<Link
								to="/change-password"
								style={{ textDecoration: "none", color: "#666666" }}
							>
								<ListItem
									className={
										window.location.pathname === "/change-password"
											? classes.listItemActive
											: classes.listItem
									}
								>
									<ListItemIcon
										style={{
											minWidth: "40px",
											width: "40px",
											color: "inherit",
										}}
									>
										<LockIcon style={{ fontSize: 21, color: "inherit" }} />
									</ListItemIcon>
									<ListItemText primary="Change Password" />
								</ListItem>
							</Link>
							<Link
								to="/donation-history"
								style={{ textDecoration: "none", color: "#666666" }}
							>
								<ListItem className={classes.listItem}>
									<ListItemIcon
										style={{
											minWidth: "40px",
											width: "40px",
											color: "inherit",
										}}
									>
										<HistoryIcon style={{ fontSize: 21, color: "inherit" }} />
									</ListItemIcon>
									<ListItemText primary="Donation History" />
								</ListItem>
							</Link>
						</List>
					</Grid>
					<Grid xs={12} sm={8} md={9}>
						<Paper elevation={3} style={{ padding: "50px 30px" }}>
							<Grid container spacing={2}>
								<Grid item md={4}>
									<Paper
										style={{
											width: "100%",
											maxWidth: "250px",
											minWidth: "180px",
											padding: "10px",
										}}
									>
										<b>From Date:</b>
										<DatePicker
											selected={this.state.startDate}
											onChange={(date) => this.setState({ startDate: date })}
											selectsStart
											startDate={this.state.startDate}
											endDate={this.state.endDate}
											showYearDropdown
											dateFormat="yyyy-MM-dd"
											format="yyyy-MM-dd"
											yearDropdownItemNumber={15}
											scrollableYearDropdown
										/>
									</Paper>
								</Grid>
								<Grid item md={4}>
									<Paper
										style={{
											width: "100%",
											maxWidth: "250px",
											minWidth: "180px",
											padding: "10px",
										}}
									>
										<b>To Date:</b>
										<DatePicker
											selected={this.state.endDate}
											onChange={(date) => handleDate(date)}
											selectsEnd
											startDate={this.state.startDate}
											endDate={this.state.endDate}
											minDate={this.state.startDate}
											showYearDropdown
											dateFormat="yyyy-MM-dd"
											format="yyyy-MM-dd"
											yearDropdownItemNumber={15}
											scrollableYearDropdown
										/>
									</Paper>
								</Grid>
								<Grid item md={4}>
									{this.state.pdf ? (
										<Button
											// disabled={this.state.pdf ? "false" : "true"}
											variant="contained"
											className={classes.btn}
											onClick={handlePdf}
										>
											Download Pdf
										</Button>
									) : (
										<div style={{ textAlign: "center" }}>
											<Button
												disabled
												variant="contained"
												className={classes.btn}
												// onClick={handlePdf}
											>
												Download Pdf
											</Button>
											<small>
												<em>Choose date range to Download</em>
											</small>
										</div>
									)}
								</Grid>
							</Grid>
							<div className={classes.formHeader}>
								<h4 className={classes.formHeading}>My Donation History:</h4>
							</div>
							{loading ? (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										flexDirection: "column",
										height: 300,
									}}
								>
									<CircularProgress
										style={{ color: "#09926E", width: 80, height: 80 }}
									/>
								</div>
							) : donationHistory.length > 0 ? (
								<Fragment>
									{/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <h2>Donated Amount: $ {totalAmountDonated}</h2>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <h2>Total Donations/Funds : {totalDonations}</h2>
                                            </div> */}
									<DonationTable donationList={donationHistory} />
								</Fragment>
							) : (
								<h1 style={{ textAlign: "center" }}>No History found</h1>
							)}
							{/* {
                                this.state.loading ?
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            height: 300
                                        }}
                                    >
                                        <CircularProgress style={{ color: '#09926E', width: 80, height: 80 }} />
                                    </div>
                                    :
                                    <h1 style={{ textAlign: 'center' }}>No History found</h1>
                            } */}
							{/* <Grid container>
                                <Grid item xs={12} sm={6}>
                                    Full Name: {user.first_name && `${user.first_name} ${user.last_name}`}
                                </Grid>
                            </Grid> */}
						</Paper>
					</Grid>
				</Grid>
			</Container>
		);
	}
}

export default withStyles(styles)(History);
