import React, { Fragment, useState, useEffect } from "react";
import {
	Container,
	makeStyles,
	Grid,
	CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";

import baseUrl from "../../Utils/baseUrl";

const useStles = makeStyles((theme) => ({
	eventCard: {
		backgroundColor: "#f6f6f6",
		padding: "17px 15px",
		position: "relative",
		marginBottom: 20,
	},
	username: {
		fontFamily: '"IBM Plex Sans", sans serif',
		color: "#646464",
		textTransform: "uppercase",
		fontSize: 14,
		fontWeight: "500",
		display: "block",
		marginBottom: 5,
	},
	calender: {
		backgroundColor: "#fd5a5a",
		color: "#fff",
		position: "absolute",
		top: 0,
		right: 0,
		width: 55,
		height: 60,
		borderRadius: 3,
		textAlign: "center",
		fontSize: 27,
		fontFamily: "'Vidaloka', serif",
		// lineHeight: 25,
		paddingTop: 7,
	},
	month: {
		display: "block",
		fontSize: 14,
		textTransform: "uppercase",
	},
	eventTitle: {
		fontSize: 27,
	},
	imageBox: {
		padding: "5%",
		width: "90%",
	},
	image: {
		display: "block",
		width: "100%",
		height: 300,
	},
	heading: {
		fontFamily: "'vidaloka', serif",
		textAlign: "center",
		marginBottom: 50,
	},
	heading2: {
		fontFamily: "'vidaloka', serif",
		fontSize: 16,
		margin: "5px 0px 10px",
		fontWeight: "bold",
	},
	text: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 16,
		color: "#646464",
		whiteSpace: "pre-line",
		overflowWrap: "anywhere",
	},
	link: {
		"&:hover": {
			color: "#fd5a5a",
			cursor: "pointer",
		},
	},
}));

export default function Events(props) {
	const classes = useStles();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [customKey, setCustomKey] = useState(0);

	useEffect(() => {
		getPhotos();
	}, []);

	const getPhotos = async () => {
		let res = await axios.get(`${baseUrl}/event`);
		let tempArr = [];

		res.data.data.forEach((element) => {
			tempArr.push({ ...element, readMore: false });
		});

		console.log(tempArr);
		setLoading(false);
		setData(tempArr);
	};

	const handleReadMore = (index) => {
		let tempArr = data;

		tempArr[index].readMore = !data[index].readMore;
		setData(tempArr);
		setCustomKey(new Date());
	};

	if (loading) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					height: 500,
				}}
			>
				<CircularProgress style={{ color: "#09926E", width: 80, height: 80 }} />
			</div>
		);
	} else {
		return (
			<Fragment>
				<div className="section-title text-center pb-50 ">
					<span className="span-after">EVENTS YOU CAN BE A PART OF</span>
					<h2>Events</h2>
				</div>
				<div style={{ height: 100 }} />
				<div>
					<Container maxWidth="lg">
						<Grid container spacing={3}>
							{data.length > 0 &&
								data.map((event, index) => {
									console.log(event);
									let date = moment(event.event_date, "YYYY-MM-DD");
									return (
										<Grid item xs={12} sm={6} md={4} key={index}>
											<div className={classes.eventCard}>
												<div className={classes.imageBox}>
													<img
														className={classes.image}
														src={`https://api.edhi.org.au/${event.image_link}`}
														alt="pic"
													/>
												</div>
												<span className={classes.username}>
													{event.userName}
												</span>
												<a
													style={{ backgroundColor: event.color }}
													className={classes.calender}
												>
													{date.format("D")}
													<span className={classes.month}>
														{date.format("MMM")}
													</span>
												</a>
												<h3 className={classes.eventTitle}>{event.title}</h3>
												{event.readMore ? (
													<p className={classes.text}>{event.description}</p>
												) : (
													<p className={classes.text}>
														{event.description.substring(0, 80)}
													</p>
												)}
												<span
													className={classes.link}
													onClick={() => {
														handleReadMore(index);
													}}
												>
													{event.readMore ? "Read Less" : "Read More..."}
												</span>
											</div>
										</Grid>
									);
								})}
						</Grid>
					</Container>
				</div>
			</Fragment>
		);
	}
}
