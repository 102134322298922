import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Button, Dialog, IconButton, CircularProgress, ListItem, ListItemAvatar, ListItemText, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { FaFacebookSquare, FaGooglePlusSquare } from 'react-icons/fa';
import fb from '../../Assets/Icons/facebook.png';
import googleIcon from '../../Assets/Icons/google.png';
import guestIcon from '../../Assets/Icons/guest.png'
import swal from 'sweetalert2';
import Axios from 'axios';
import baseUrl from '../../Utils/baseUrl';
import firebase from '../../Utils/FirebaseConfig';

import './Login.css';
import person from '../../Assets/Images/login.png';
import contextAPI from '../../ContextAPI/contextAPI';

export default function Login(props) {
    const { authStatChange, firebaseUserHandler, guestUserHandler } = useContext(contextAPI);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const { email, password } = formData;
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleLogin = (e) => {
        // e.preventDefault();
        setLoading(true);
        Axios({
            url: `${baseUrl}/login`,
            method: "POST",
            data: {
                email,
                password
            }
        }).then(res => {
            console.log(res.data);
						if (res.data.message == "Invalid Username or Password") {
							swal
								.fire({
									icon: "error",
									text: `${res.data.message}`,
								})
								.then(() => {
									// window.location.reload();
									return;
								});
						}
						if (
							!res.data.data ||
							(res.data.profile == false &&
								res.data.user_id &&
								res.data.success.token)
						) {
							localStorage.setItem("token", res.data.success.token);
							localStorage.setItem("userId", res.data.user_id);
							localStorage.setItem("isProfile", "no");
							authStatChange();
							props.history.push("/edit-profile");
						} else if (res.data.success && res.data.success.token) {
							localStorage.setItem("token", res.data.success.token);
							if (res.data.data.user_id) {
								localStorage.setItem("userId", res.data.data.user_id);
							}
							authStatChange();
							if (props.history.location.pathname == "/reset-password") {
								props.history.push("/");
							} else {
								if (res.data.profile == true) {
									console.log(res.data.profile);
									localStorage.setItem("isProfile", "yes");
									props.history.goBack();
								} else {
									localStorage.setItem("isProfile", "no");
									props.history.push("/edit-profile");
								}
							}
						} else {
							swal
								.fire({
									icon: "error",
									text: `${res.data.message}`,
								})
								.then(() => {
									window.location.reload();
								});
						}
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
            if (err && err.response) {
                swal.fire({
                    icon: "error",
                    text: `${err.response.data.message}`
                })
            }
        })
    };

    const loginWithGoogle = () => {

        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider)
            .then(result => {
                var user = result.user;
                console.log(user);
                // localStorage.setItem("token", user.uid);
                firebaseUserHandler();
                props.history.goBack();
            })
            .catch(error => {
                console.log(error)
            });
    }

    const loginWithFaceBook = () => {

        const provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function (result) {
            var user = result.user;

            // localStorage.setItem("token", user.uid);
            firebaseUserHandler();
            props.history.goBack();
        })
            .catch(error => {
                console.log(error)
            });
    }

    const handleForgetPassword = () => {
        setLoading(true);
        Axios({
            url: `${baseUrl}/password/email`,
            method: "POST",
            data: {
                email
            }
        }).then(res => {
            console.log(res.data)
            if (res.data.code === "200") {
                props.history.push("/reset-password");
            }
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        })
    };

    const loginAsAGuesUser = () => {
        guestUserHandler();
        props.history.goBack();
    }

    return (
        <div className='login_wrapper_unique'>
            <div className='wrapper fadeInDown'>
                <div id='formContent'>
                    <title>Edhi | Foundation</title>
                    <div className='fadeIn first'>
                        <img
                            style={{ width: '60px' }}
                            src={person}
                            id='icon'
                            alt='User Icon'
                        />
                    </div>
                    <h3>Do you want to Login?</h3>
                    <br />
                    {/* <form onSubmit={(e) => onSubmit(e)}> */}
                    <input
                        type='email'
                        id='login'
                        className='fadeIn second'
                        name='email'
                        value={email}
                        onChange={(e) => onChange(e)}
                        placeholder='Email address'
                    />
                    <input
                        type='password'
                        id='password'
                        className='fadeIn third'
                        name='password'
                        value={password}
                        onChange={(e) => onChange(e)}
                        placeholder='password'
                    />
                    {
                        !loading ?
                            <input
                                type="submit"
                                onClick={handleLogin}
                                className='fadeIn fourth'
                                style={{ width: '190px', marginBottom: 10 }}
                                value='Log In'
                            />
                            :
                            <div
                                style={{
                                    backgroundColor: '#09926E',
                                    padding: '10px 20px',
                                    width: 180,
                                    borderRadius: 5,
                                    margin: '0px auto 10px'
                                }}>
                                <CircularProgress style={{ color: '#fff', width: 25, height: 25 }} />
                            </div>
                    }
                    <p style={{ margin: 0 }}>OR</p>
                    <Button style={{ backgroundColor: "transparent", textTransform: "capitalize" }} onClick={loginAsAGuesUser}>
                        <ListItem disableGutters style={{ padding: 0, cursor: "pointer" }}>
                            <ListItemText primary="Continue as Guest User" style={{ marginTop: -10 }} />
                            <ListItemAvatar style={{ minWidth: 20, marginLeft: 5 }}>
                                <img src={guestIcon} alt="fb-icon" style={{ height: 35, width: 35 }} />
                            </ListItemAvatar>
                        </ListItem>
                    </Button>                    {/* </form> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: "80%", margin: "0px auto 0px" }}>
                        <div>
                            <img src={fb} alt="fb-icon" style={{ height: 35, width: 35, cursor: "pointer" }} onClick={loginWithFaceBook} />
                            <img src={googleIcon} alt="fb-icon" style={{ height: 35, width: 35, cursor: "pointer" }} onClick={loginWithGoogle} />
                            {/* <FaFacebookSquare
                                title="Login with Facebook"
                                style={{
                                    color: 'blue',
                                    fontSize: 35,
                                    cursor: 'pointer'
                                }}
                                onClick={loginWithFaceBook}
                            /> 
                            <FaGooglePlusSquare
                                title="Login with Google"
                                style={{
                                    color: 'red',
                                    fontSize: 35,
                                    cursor: 'pointer'
                                }}
                                onClick={loginWithGoogle}
                            />
                            */}
                        </div>
                        <div>
                            {/* <ListItem disableGutters style={{ padding: 0, cursor: "pointer" }}>
                                <ListItemText primary="Login as Guest User" style={{ marginTop: -10 }} />
                                <ListItemAvatar style={{ minWidth: 20, marginLeft: 5 }}>
                                    <img src={guestIcon} alt="fb-icon" style={{ height: 35, width: 35 }} onClick={loginAsAGuesUser} />
                                </ListItemAvatar>
                            </ListItem> */}

                            {/* <AccountCircleIcon
                                titleAccess="Login as guest user"
                                style={{
                                    color: '#4c4cd0',
                                    fontSize: 35,
                                    cursor: 'pointer'
                                }}
                                onClick={loginAsAGuesUser}
                            /> */}
                        </div>
                    </div>
                    <div id='formFooter' onClick={handleClickOpen}>
                        <a
                            className='underlineHover'
                        // href=''
                        >
                            Forgot Password?
                         </a>
                    </div>{' '}
                    <Dialog
                        fullWidth
                        maxWidth="sm"
                        onClose={handleClose}
                        open={open}
                        style={{ height: "45%" }}
                    >
                        <div style={{ padding: 20 }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div className='login_wrapper_unique'>
                                <input
                                    type='email'
                                    id='login'
                                    name='email'
                                    value={email}
                                    onChange={(e) => onChange(e)}
                                    placeholder='Please enter your registered email address'
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }} >
                                <Button
                                    variant="outlined"
                                    color="default"
                                    onClick={handleForgetPassword}
                                    type='button'
                                    className='btn btn-default'
                                >
                                    Proceed
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div >
    );
}
