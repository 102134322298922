import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, withStyles, InputLabel, TextField, Button, List, ListItem, ListItemIcon, ListItemText, Paper, CircularProgress } from '@material-ui/core';
//Icons
import { FaRegUserCircle } from 'react-icons/fa';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import LockIcon from '@material-ui/icons/Lock';
import swal from 'sweetalert2';
import Axios from 'axios';
import ContextAPI from '../../ContextAPI/contextAPI';
import baseUrl from '../../Utils/baseUrl';

const styles = theme => ({
    profileNavHeading: {
        listStyle: 'none',
        textDecoration: 'none',
        color: '#008081',
        fontSize: '21px',
        marginBottom: '20px',
    },
    profileNav: {
        listStyle: 'none',
        textDecoration: 'none',
        color: '#474747',
        fontSize: '17px',
        margin: '5px',
        cursor: 'pointer'
    },
    listItem: {
        backgroundColor: '#fff',
        color: '#666666',
        margin: 3,
        '&:hover': {
            backgroundColor: '#09926E',
            color: '#fff',
        },
    },
    listItemActive: {
        margin: 3,
        backgroundColor: '#087059',
        color: '#fff',
    },
    formHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10
    },
    formHeading: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#09926E',
        margin: '0 0 10px',
        textAlign: 'left'
    },
    formNo: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#888888',
        margin: '0 0 10px',
    },
    inputLabel: {
        fontWeight: 'normal',
        marginBottom: 15,
        textAlign: 'left'
    },
    textField: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ECEFF1',
        color: 'black',
        // height: 30,
        width: '100%',
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutline": {
                borderColor: '#ddd',
            }
        }
    },
    select: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#f9f9f9',
        color: 'black',
        // height: 50,
        padding: "10px 7px",
        width: '100%',
        borderRadius: 5,
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutline": {
                borderColor: 'transparent',
            }
        },
        "&.MuiInput-underline:before": {
            borderBottom: 'none !important'
        },
        "&.MuiSelect-select::placeholder": {
            color: '#b8b6b6',
        }
    },
    notchedOutline: {
        borderColor: '#ddd',
        '&:hover': {
            borderColor: '#ddd',
        }
    },
    focused: {
        "& $notchedOutline": {
            borderColor: '#ddd !important',
        }
    },
    textFieldError: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ECEFF1',
        color: 'black',
        width: '100%',
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutlineError": {
                borderColor: 'red !important',
            }
        }
    },
    notchedOutlineError: {
        borderColor: 'red',
        '&:hover': {
            borderColor: 'red !important',
        }
    },
    focusedError: {
        "& $notchedOutlineError": {
            borderColor: '#ddd !important',
        }
    },
    btn: {
        backgroundColor: '#DC3545',
        color: '#fff',
        padding: '10px 35px',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: '#DC3545',
            color: '#fff',
            boxShadow: 'none',
        }
    },
    hide: {
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    }
});

class ChangePassword extends Component {

    state = {
        old_password: "",
        new_password: "",
        confirm_password: "",
        errorObj: {},
        loading: false
    }

    static contextType = ContextAPI;

    componentDidMount() {
        setTimeout(() => {
            const { user } = this.context;

            if (!user.email) {
                this.props.history.push("/login")
            }
            // console.log(user)
        }, 2000)

    }

    handleChange = e => {
        const { errorObj } = this.state;

        if (errorObj[e.target.name])
            errorObj[e.target.name] = false;

        this.setState({
            [e.target.name]: e.target.value,
            errorObj
        })
    }

    handleValidation = () => {
        const {
            old_password,
            new_password,
            confirm_password
        } = this.state;
        let tempErrorObj = {}

        if (!old_password) {
            tempErrorObj.old_password = true
        } else {
            tempErrorObj.old_password = false
        }
        if (!new_password) {
            tempErrorObj.new_password = true
        } else {
            tempErrorObj.new_password = false
        }
        if (!confirm_password) {
            tempErrorObj.confirm_password = true
        } else {
            tempErrorObj.confirm_password = false
        }


        this.setState({ errorObj: tempErrorObj })

    }

    handleCHangePassword = () => {
        const { old_password, new_password, confirm_password } = this.state;
        const token = localStorage.getItem("token");
        const { user } = this.context;
        this.setState({ loading: true })

        Axios({
            url: `${baseUrl}/changePassword`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                user_id: user.user_id,
                old_password,
                new_password,
                confirm_password,
            }
        }).then(res => {
            this.setState({ loading: false });
            if (res && res.data.code === "200") {
                this.props.history.push('/profile');
            } else {
                swal.fire({
                    icon: 'error',
                    text: `${res.data.message}`,
                });
            }
        }).catch(err => {
            console.log(err)
            if (err && err.response) {
                this.setState({ loading: false });
                swal.fire({
                    icon: 'error',
                    text: `${err.response.data.message}`,
                })
            }
        })
    }

    render() {
        const { classes } = this.props;
        const {
            old_password,
            new_password,
            confirm_password,
            errorObj,
            loading
        } = this.state;

        return (
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item className={classes.hide} sm={4} md={3}>
                        <List>
                            <Link to="/profile" style={{ textDecoration: 'none', color: '#666666' }}>
                                <ListItem className={window.location.pathname === 'profile' ? classes.listItemActive : classes.listItem}>
                                    <ListItemIcon style={{ minWidth: '40px', width: '40px', color: 'inherit' }}>
                                        <FaRegUserCircle style={{ fontSize: 21, color: 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="My Profile" />
                                </ListItem>
                            </Link>
                            <Link to="/edit-profile" style={{ textDecoration: 'none', color: '#666666' }}>
                                <ListItem className={window.location.pathname === '/edit-profile' ? classes.listItemActive : classes.listItem}>
                                    <ListItemIcon style={{ minWidth: '40px', width: '40px', color: 'inherit' }}>
                                        <EditIcon style={{ fontSize: 21, color: 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit Profile" />
                                </ListItem>
                            </Link>
                            <Link to="/change-password" style={{ textDecoration: 'none', color: '#666666' }}>
                                <ListItem className={window.location.pathname === '/change-password' ? classes.listItemActive : classes.listItem}>
                                    <ListItemIcon style={{ minWidth: '40px', width: '40px', color: 'inherit' }}>
                                        <LockIcon style={{ fontSize: 21, color: 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Change Password" />
                                </ListItem>
                            </Link>
                            <Link to="/donation-history" style={{ textDecoration: 'none', color: '#666666' }}>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon style={{ minWidth: '40px', width: '40px', color: 'inherit' }}>
                                        <HistoryIcon style={{ fontSize: 21, color: 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Donation History" />
                                </ListItem>
                            </Link>
                        </List>
                    </Grid>
                    <Grid xs={12} sm={8} md={9}>
                        <Paper elevation={3} style={{ padding: "50px 30px" }}>
                            <div className={classes.formHeader}>
                                <h4 className={classes.formHeading}>Change Password:</h4>
                            </div>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel className={classes.inputLabel} >Old Password: *</InputLabel>
                                    <TextField
                                        variant='outlined'
                                        size='small'
                                        name="old_password"
                                        type="password"
                                        placeholder="Old Password"
                                        value={old_password}
                                        className={errorObj.old_password ? classes.textFieldError : classes.textField}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: errorObj.old_password ? classes.notchedOutlineError : classes.notchedOutline,
                                                focused: errorObj.old_password ? classes.focusedError : classes.focused,
                                            }
                                        }}
                                        onChange={this.handleChange}
                                    />
                                    <InputLabel className={classes.inputLabel} >New Password: *</InputLabel>
                                    <TextField
                                        variant='outlined'
                                        size='small'
                                        type="password"
                                        name="new_password"
                                        placeholder="New Password"
                                        value={new_password}
                                        className={errorObj.new_password ? classes.textFieldError : classes.textField}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: errorObj.new_password ? classes.notchedOutlineError : classes.notchedOutline,
                                                focused: errorObj.new_password ? classes.focusedError : classes.focused,
                                            }
                                        }}
                                        onChange={this.handleChange}
                                    />
                                    <InputLabel className={classes.inputLabel} >Confirm Password: *</InputLabel>
                                    <TextField
                                        variant='outlined'
                                        type="password"
                                        size='small'
                                        name="confirm_password"
                                        placeholder="Confirm Password"
                                        value={confirm_password}
                                        className={errorObj.confirm_password ? classes.textFieldError : classes.textField}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: errorObj.confirm_password ? classes.notchedOutlineError : classes.notchedOutline,
                                                focused: errorObj.confirm_password ? classes.focusedError : classes.focused,
                                            }
                                        }}
                                        onChange={this.handleChange}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {
                                            loading ?
                                                <Button
                                                    variant="contained"
                                                    className={classes.btn}
                                                >
                                                    <CircularProgress style={{ color: '#fff', width: 25, height: 25 }} />
                                                </Button>
                                                :
                                                <Button
                                                    variant="contained"
                                                    className={classes.btn}
                                                    onClick={this.handleCHangePassword}
                                                >
                                                    Change
                                                </Button>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            </Container >
        )
    }
}

export default withStyles(styles)(ChangePassword);