import React, { Component, Fragment } from 'react';
import { Grid, withStyles, InputLabel, TextField, Button, Container, CircularProgress, Select } from '@material-ui/core';
import Axios from 'axios';
import baseUrl from '../../Utils/baseUrl';
import './Register.css';
import swal from 'sweetalert2';

const styles = theme => ({
    container: {
        maxWidth: "838px !important",
        "& .MuiContainer-maxWidthMd": {
            maxWidth: "838px !important"
        }
    },
    card: {
        borderRadius: 10,
        boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.15)',
        boxSizing: 'border-box',
    },
    formHeader: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#09926E',
        color: '#fff',
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        padding: '20px 0'
    },
    formHeading: {
        fontFamily: '"IBM Plex Sans", sans serif',
        fontSize: 24,
        fontWeight: '700',
        color: '#fff',
        margin: 0,
        textTransform: 'uppercase'
    },
    cardBody: {
        padding: 10,
        [theme.breakpoints.up("sm")]: {
            padding: '50px 80px 35px',
            paddingBottom: 73
        }
    },
    formNo: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#888888',
        margin: '0 0 10px',
    },
    inputLabel: {
        fontWeight: 'bold',
        marginTop: 18,
        textAlign: 'left',
        width: 100
    },
    required: {
        color: 'red'
    },
    textField: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#f9f9f9',
        color: 'black',
        // height: 50,
        padding: "7px 0px 7px 0px",
        width: '100%',
        borderRadius: 5,
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutline": {
                borderColor: 'transparent',
            }
        }
    },
    select: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#f9f9f9',
        color: 'black',
        // height: 50,
        padding: "10px 7px",
        width: '100%',
        borderRadius: 5,
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutline": {
                borderColor: 'transparent',
            }
        },
        "&.MuiInput-underline:before": {
            borderBottom: 'none !important'
        },
        "&.MuiSelect-select::placeholder": {
            color: '#b8b6b6',
        }
    },
    notchedOutline: {
        borderColor: 'transparent',
        '&:hover': {
            borderColor: 'transparent',
        }
    },
    focused: {
        "& $notchedOutline": {
            borderColor: 'transparent !important',
        }
    },
    btn: {
        backgroundColor: '#09926E',
        color: '#fff',
        padding: '14px 50px',
        fontWeight: 'bold',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: '#F44336',
            color: '#fff',
            boxShadow: 'none',
        }
    }
});

class Register extends Component {

    state = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        phoneCode: "",
        country: "",
        state: "",
        contactMode: "",
        postalCode: "",

        countries: [],
        states: [],
        loading: false,
    }

    componentDidMount() {

        Axios({
            url: `${baseUrl}/countries`,
            method: "GET"
        }).then(res => {
            console.log(res.data);
            this.setState({ countries: res.data.data });
        }).catch(err => {
            console.log(err)
        })
    }

    handleRegisterUser = () => {
        const {
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
            phoneNumber,
            phoneCode,
            country,
            state,
            contactMode,
            postalCode
        } = this.state;
        let tempPhoneNumber = "";
        this.setState({ loading: true });

        if (password.length > 7) {
            if (password === confirmPassword) {

                if (phoneNumber[0] === "0" || phoneNumber[0] === 0) {
                    tempPhoneNumber = phoneCode + phoneNumber.substring(1);
                } else {
                    tempPhoneNumber = phoneCode + phoneNumber;
                }

                Axios({
                    url: `${baseUrl}/user/create`,
                    method: "POST",
                    data: {
                        first_name: firstName,
                        last_name: lastName,
                        postal_code: postalCode,
                        mobile: tempPhoneNumber,
                        email,
                        city: "not-required",
                        country,
                        state,
                        password,
                        prefered_contact: contactMode,
                    }
                }).then(res => {
                    console.log(res.data);
                    this.setState({ loading: false })
                    if (res && res.data.code === "400") {
                        if (res.data.data.email) {
                            swal.fire({
                                icon: 'error',
                                text: `${res.data.data.email}`,
                            })
                        } else {
                            swal.fire({
                                icon: 'error',
                                text: `${res.data.message}`,
                            })
                        }
                    } else {
                        this.setState({ loading: false }, () => {
                            this.props.history.push('/login');
                        })
                    }
                }).catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                    if (err && err.response) {
                        console.log(err.response.data)
                        swal.fire({
                            icon: 'error',
                            text: `${err.response.data.message}`,
                        })
                    }
                })
            } else {
                this.setState({ loading: false })
                swal.fire({
                    icon: 'error',
                    text: "Password does not match",
                })
            }
        } else {
            this.setState({ loading: false })
            swal.fire({
                icon: 'error',
                text: "Password must be atleast 8 characters long",
            })
        }

    }

    handleSelectCountry = e => {
        const { countries } = this.state;

        countries.forEach(el => {
            if (e.target.value === el.name) {
                this.setState({ country: e.target.value, phoneCode: el.code }, () => {
                    // console.log(el.id)
                    Axios({
                        url: `${baseUrl}/state/${el.id}`,
                        method: "GET"
                    }).then(res => {
                        console.log(res.data);
                        this.setState({ states: res.data.data });
                    }).catch(err => {
                        console.log(err)
                    })
                })
            }
        })
    }

    render() {
        const { classes } = this.props;
        const { contactMode, loading, countries, states, phoneCode } = this.state;

        // console.log(this.state)

        return (
            <Container maxWidth="md" className={`${classes.container} register`}>
                <div className={classes.card}>
                    <div className={classes.formHeader}>
                        <h1 className={classes.formHeading}>REGISTRATION FORM</h1>
                    </div>
                    <div className={classes.cardBody}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                                <InputLabel className={classes.inputLabel} >Name: <span className={classes.required}>*</span></InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    placeholder="First Name"
                                    className={classes.textField}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            focused: classes.focused,
                                        }
                                    }}
                                    onChange={(e) => { this.setState({ firstName: e.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    placeholder="Last Name"
                                    className={classes.textField}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            focused: classes.focused,
                                        }
                                    }}
                                    onChange={(e) => { this.setState({ lastName: e.target.value }) }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                                <InputLabel className={classes.inputLabel} >Email: <span className={classes.required}>*</span></InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    placeholder="Email@example.com"
                                    className={classes.textField}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            focused: classes.focused,
                                        }
                                    }}
                                    onChange={(e) => { this.setState({ email: e.target.value }) }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                                <InputLabel className={classes.inputLabel} >Phone: <span className={classes.required}>*</span></InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    variant='outlined'
                                    // type="number"
                                    size='small'
                                    placeholder="+61"
                                    // disabled
                                    value={phoneCode}
                                    className={classes.textField}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            focused: classes.focused,
                                        }
                                    }}
                                // onChange={(e) => { this.setState({ phoneCode: e.target.value }) }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    placeholder="Mobile Number (Optional)"
                                    // type="number"
                                    className={classes.textField}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            focused: classes.focused,
                                        }
                                    }}
                                    value={this.state.phoneNumber}
                                    onChange={(e) => {
                                        this.setState({ phoneNumber: e.target.value })
                                    }}
                                    inputProps={{ maxLength: "11", pattern: "[0-9]*" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                                <InputLabel className={classes.inputLabel} >Country: <span className={classes.required}>*</span></InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <Select
                                    native
                                    value={this.state.country}
                                    onChange={this.handleSelectCountry}
                                    className={classes.select}
                                    inputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            focused: classes.focused,
                                        }
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    {
                                        countries.length > 0 &&
                                        countries.map((el, i) => (
                                            <option value={el.name}>{el.name}</option>
                                        ))
                                    }
                                </Select>
                                {/* <TextField
                                                variant='outlined'
                                                size='small'
                                                placeholder="Country"
                                                className={classes.textField}
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: classes.notchedOutline,
                                                        focused: classes.focused,
                                                    }
                                                }}
                                                onChange={(e) => { this.setState({ country: e.target.value }) }}
                                            /> */}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                                <InputLabel className={classes.inputLabel} >Address: <span className={classes.required}>*</span></InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    type="number"
                                    placeholder="Post Code"
                                    className={classes.textField}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            focused: classes.focused,
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5);
                                        }
                                        this.setState({ postalCode: e.target.value })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Select
                                    native
                                    value={this.state.state}
                                    onChange={e => { this.setState({ state: e.target.value }) }}
                                    className={classes.select}
                                    inputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            focused: classes.focused,
                                        }
                                    }}
                                >
                                    <option disabled value="">State</option>
                                    {
                                        states.length > 0 &&
                                        states.map((el, i) => (
                                            <option value={el.name}>{el.name}</option>
                                        ))
                                    }
                                </Select>
                                {/* <TextField
                                    variant='outlined'
                                    size='small'
                                    placeholder="State"
                                    className={classes.textField}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            focused: classes.focused,
                                        }
                                    }}
                                    onChange={(e) => { this.setState({ state: e.target.value }) }}
                                /> */}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                                <InputLabel className={classes.inputLabel} >Password : <span className={classes.required}>*</span></InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    type="password"
                                    placeholder="Password"
                                    className={classes.textField}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            focused: classes.focused,
                                        }
                                    }}
                                    onChange={(e) => { this.setState({ password: e.target.value }) }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                                <InputLabel className={classes.inputLabel} >Confirm: <span className={classes.required}>*</span></InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    type="password"
                                    placeholder="Confirm Password"
                                    className={classes.textField}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            focused: classes.focused,
                                        }
                                    }}
                                    onChange={(e) => { this.setState({ confirmPassword: e.target.value }) }}
                                />
                            </Grid>
                        </Grid>
                        <div>
                            <label className="margin55 radiocontainer" >
                                Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input
                                    type='radio'
                                    name='prefered_contact'
                                    checked={contactMode === 'email'}
                                    value='email'
                                    onChange={(e) => { this.setState({ contactMode: e.target.value }) }}
                                />
                                <span className="checkmark"></span>
                            </label>
                            <label className="radiocontainer">
                                SMS
                                <input
                                    type='radio'
                                    name='prefered_contact'
                                    checked={contactMode === 'sms'}
                                    value='sms'
                                    onChange={(e) => { this.setState({ contactMode: e.target.value }) }}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 40 }}>
                            <Button
                                variant="contained"
                                className={classes.btn}
                                style={loading ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                onClick={!loading && this.handleRegisterUser}
                            >
                                {
                                    !loading ?
                                        "Register"
                                        :
                                        <CircularProgress style={{ color: '#fff', width: 25, height: 25 }} />
                                }
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{ height: 50 }} />
            </Container>
        )
    }
}

export default withStyles(styles)(Register);
