import React, { Fragment } from 'react';
import { Button, Table, TableBody, TableHead, TableCell, TableRow, makeStyles } from '@material-ui/core';

import StripeCard from '../Components/StripeCardElement';
import PaypalCard from '../Components/Paypal';

const styles = makeStyles(theme => ({
    formHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10
    },
    formHeading: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#09926E',
        margin: '0 0 10px',
        textAlign: 'left'
    },
    formNo: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#888888',
        margin: '0 0 10px',
    },
    inputLabel: {
        fontWeight: 'normal',
        marginBottom: 15,
        textAlign: 'left'
    },
    tableCellHead: {
        borderBottom: 'none'
    },
    tableCellBody: {
        borderBottom: 'none'
    },
    text: {
        fontFamily: "'Roboto', sans-serif",
        lineHeight: '2',
        fontSize: 16,
        color: '#888888',
        margin: '0 0 10px',
        textAlign: 'left'
    },
    prevBtn: {
        backgroundColor: '#BBBBBB',
        color: '#fff',
        padding: '10px 35px',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: '#BBBBBB',
            color: '#fff',
            boxShadow: 'none',
        }
    }
}));

export default function Preview(props) {
    const { backHandler, data } = props;
    const classes = styles();
    let tempTotal = 0;
    let { adminFee, total } = data;

    if (adminFee) {
        let tempAdminFee = 0;
        let tempMerchantFee = 0;
        let merchantFeePercent = 0.015;
        if (data.paymentMethod === "Pay with Paypal") {
            merchantFeePercent = 0.03;
        }

        tempAdminFee = parseFloat(total) * 0.05;
        tempAdminFee = Number(Math.round(tempAdminFee + "e" + 2) + "e-" + 2);

        tempTotal = parseFloat(total) + tempAdminFee;
        tempTotal = Number(Math.round(tempTotal + "e" + 2) + "e-" + 2);

        tempMerchantFee = parseFloat(tempTotal) * merchantFeePercent;
        tempMerchantFee = Number(Math.round(tempMerchantFee + "e" + 2) + "e-" + 2);

        tempTotal = parseFloat(total) + tempAdminFee + tempMerchantFee;
        tempTotal = Number(Math.round(tempTotal + "e" + 2) + "e-" + 2);
    }
    else {
        let tempMerchantFee = 0;
        let merchantFeePercent = 0.015;
        if (data.paymentMethod === "Pay with Paypal") {
            merchantFeePercent = 0.03;
        }

        tempMerchantFee = parseFloat(total) * merchantFeePercent;
        tempMerchantFee = Number(Math.round(tempMerchantFee + "e" + 2) + "e-" + 2);

        tempTotal = parseFloat(total) + tempMerchantFee;
        tempTotal = Number(Math.round(tempTotal + "e" + 2) + "e-" + 2);
    }

    return (
        <Fragment>
            <div className={classes.formHeader}>
                <h4 className={classes.formHeading}>Preview & Card:</h4>
                <h4 className={classes.formNo}>Step 4 - 4</h4>
            </div>
            <div>
                <p className={classes.text}>First Name: {data.firstName}</p>

                <p className={classes.text}>Last Name: {data.lastName}</p>

                <p className={classes.text}>Email Address: {data.donorEmail}</p>

                <p className={classes.text}>Post Code: {data.postCode}</p>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell}>Donation Type</TableCell>
                            <TableCell className={classes.tableCell}>Donation Subcategory</TableCell>
                            <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>Quantity</TableCell>
                            <TableCell className={classes.tableCell} style={{ textAlign: 'right' }}>Amount</TableCell>
                            <TableCell className={classes.tableCell} style={{ textAlign: 'right' }}>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data && data.donationMethod.length > 0 && data.donationMethod.map((val, index) => (
                                <TableRow key={index} className={classes.tableRow}>
                                    <TableCell className={classes.tableCellBody}>{val.donationType}</TableCell>
                                    <TableCell className={classes.tableCellBody}>{val.donationSubType}</TableCell>
                                    <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>{val.quantity}</TableCell>
                                    <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>${val.amount}</TableCell>
                                    <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>${(parseInt(val.quantity) * parseFloat(val.amount)).toFixed(2)}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <p className={classes.text}>Payment Method: {data.paymentMethod}</p>
                <p className={classes.text}>Total Amount: {Number(Math.round(tempTotal + "e" + 2) + "e-" + 2).toFixed(2)}</p>
                <div style={{ height: 20 }} />

                {
                    data.paymentMethod === "Pay with Paypal" ?
                        <PaypalCard donationData={data} />
                        :
                        <StripeCard donationData={data} />
                }


                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                        variant="contained"
                        className={classes.prevBtn}
                        onClick={backHandler}
                    >
                        previous
                    </Button>
                </div>
            </div>
        </Fragment>
    )
}