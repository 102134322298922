import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyBN9WN6kataAVhzBc2GD_PW5XJWcxr0dPU",
    authDomain: "edhi-login.firebaseapp.com",
    databaseURL: "https://edhi-login.firebaseio.com",
    projectId: "edhi-login",
    storageBucket: "edhi-login.appspot.com",
    messagingSenderId: "791511838692",
    appId: "1:791511838692:web:9140ef39c97d72ba6e21f1",
    measurementId: "G-CBJZXEFKET"
};

firebase.initializeApp(firebaseConfig)

export default firebase;