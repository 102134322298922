import React, { Component, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import {
	Container,
	Grid,
	withStyles,
	InputLabel,
	TextField,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Select,
	CircularProgress,
} from "@material-ui/core";
//Icons
import { FaRegUserCircle } from "react-icons/fa";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import LockIcon from "@material-ui/icons/Lock";
import swal from "sweetalert2";
import Axios from "axios";
import ContextAPI from "../../ContextAPI/contextAPI";
import baseUrl from "../../Utils/baseUrl";

const styles = (theme) => ({
	profileNavHeading: {
		listStyle: "none",
		textDecoration: "none",
		color: "#008081",
		fontSize: "21px",
		marginBottom: "20px",
	},
	profileNav: {
		listStyle: "none",
		textDecoration: "none",
		color: "#474747",
		fontSize: "17px",
		margin: "5px",
		cursor: "pointer",
	},
	listItem: {
		backgroundColor: "#fff",
		color: "#666666",
		margin: 3,
		"&:hover": {
			backgroundColor: "#09926E",
			color: "#fff",
		},
	},
	listItemActive: {
		margin: 3,
		backgroundColor: "#087059",
		color: "#fff",
	},
	formHeader: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		marginTop: 10,
	},
	formHeading: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#09926E",
		margin: "0 0 10px",
		textAlign: "left",
	},
	formNo: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#888888",
		margin: "0 0 10px",
	},
	inputLabel: {
		fontWeight: "normal",
		marginBottom: 15,
		textAlign: "left",
	},
	textField: {
		fontFamily: "'Roboto', sans-serif",
		backgroundColor: "#ECEFF1",
		color: "black",
		// height: 30,
		width: "100%",
		marginBottom: 20,
		"&:hover": {
			"& $notchedOutline": {
				borderColor: "#ddd",
			},
		},
	},
	select: {
		fontFamily: "'Roboto', sans-serif",
		backgroundColor: "#f9f9f9",
		color: "black",
		// height: 50,
		padding: "10px 7px",
		width: "100%",
		borderRadius: 5,
		marginBottom: 20,
		"&:hover": {
			"& $notchedOutline": {
				borderColor: "transparent",
			},
		},
		"&.MuiInput-underline:before": {
			borderBottom: "none !important",
		},
		"&.MuiSelect-select::placeholder": {
			color: "#b8b6b6",
		},
	},
	notchedOutline: {
		borderColor: "#ddd",
		"&:hover": {
			borderColor: "#ddd",
		},
	},
	focused: {
		"& $notchedOutline": {
			borderColor: "#ddd !important",
		},
	},
	textFieldError: {
		fontFamily: "'Roboto', sans-serif",
		backgroundColor: "#ECEFF1",
		color: "black",
		width: "100%",
		marginBottom: 20,
		"&:hover": {
			"& $notchedOutlineError": {
				borderColor: "red !important",
			},
		},
	},
	notchedOutlineError: {
		borderColor: "red",
		"&:hover": {
			borderColor: "red !important",
		},
	},
	focusedError: {
		"& $notchedOutlineError": {
			borderColor: "#ddd !important",
		},
	},
	btn: {
		backgroundColor: "#DC3545",
		color: "#fff",
		padding: "10px 35px",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#DC3545",
			color: "#fff",
			boxShadow: "none",
		},
	},
	hide: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
});

class EditProfile extends Component {
	state = {
		first_name: "",
		last_name: "",
		mobile: "",
		city: "not required",
		prefered_contact: "",
		country: "",
		state: "",
		postal_code: "",
		countries: [],
		states: [],
		errorObj: {},
		loading: false,
		isProfile: true,
	};

	static contextType = ContextAPI;

	componentDidMount() {
		if (localStorage.getItem("isProfile") === "no") {
			this.setState({ isProfile: false });
		}
		setTimeout(() => {
			const { user, isGuestUser } = this.context;
			console.log(this.context);
			console.log(user);
			if (!user.email) {
				this.props.history.push("/login");
			} else {
				console.log(user);
				this.setState({
					first_name: user.first_name,
					last_name: user.last_name,
					mobile: user.mobile,
					prefered_contact: user.prefered_contact,
					country: user.country,
					state: user.state,
					postal_code: user.postal_code,
				});
			}
		}, 2000);

		Axios({
			url: `${baseUrl}/countries`,
			method: "GET",
		})
			.then((res) => {
				console.log(res.data);
				this.setState({ countries: res.data.data });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleChange = (e) => {
		const { errorObj } = this.state;

		if (errorObj[e.target.name]) errorObj[e.target.name] = false;

		this.setState({
			[e.target.name]: e.target.value,
			errorObj,
		});
	};

	handleValidation = () => {
		const {
			first_name,
			last_name,
			mobile,
			prefered_contact,
			country,
			state,
			postal_code,
		} = this.state;
		let tempErrorObj = {};

		if (!first_name) {
			tempErrorObj.first_name = true;
		} else {
			tempErrorObj.first_name = false;
		}
		if (!last_name) {
			tempErrorObj.last_name = true;
		} else {
			tempErrorObj.last_name = false;
		}
		if (!postal_code) {
			tempErrorObj.postal_code = true;
		} else {
			tempErrorObj.postal_code = false;
		}
		if (!mobile) {
			tempErrorObj.mobile = true;
		} else {
			tempErrorObj.mobile = false;
		}
		// if (!prefered_contact) {
		//     tempErrorObj.prefered_contact = true
		// } else {
		//     tempErrorObj.prefered_contact = false
		// }
		if (!country) {
			tempErrorObj.country = true;
		} else {
			tempErrorObj.country = false;
		}
		if (!state) {
			tempErrorObj.state = true;
		} else {
			tempErrorObj.state = false;
		}

		this.setState({ errorObj: tempErrorObj });
	};

	handleUpdateUser = () => {
		const {
			first_name,
			last_name,
			mobile,
			prefered_contact,
			country,
			state,
			postal_code,
			errorObj,
			countries,
			states,
		} = this.state;
		const token = localStorage.getItem("token");
		const userId = localStorage.getItem("userId");
		const { user } = this.context;
		this.setState({ loading: true });

		Axios({
			url: `${baseUrl}/updateUser`,
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
			},
			data: {
				user_id: user.user_id,
				first_name,
				last_name,
				mobile,
				city: "not required",
				prefered_contact,
				country,
				state,
				postal_code,
			},
		})
			.then((res) => {
				this.setState({ loading: false });
				if (res && res.data.code === "400") {
					swal.fire({
						icon: "error",
						text: `${res.data.message}`,
					});
				} else {
					console.log(res.data);
					localStorage.setItem("isProfile", "yes");
					window.location.replace("/profile");
				}
			})
			.catch((err) => {
				console.log(err);
				if (err && err.response) {
					console.log(err.response.data);
					this.setState({ loading: false });
					swal.fire({
						icon: "error",
						text: `${err.response.data.message}`,
					});
				}
			});
	};

	handleSelectCountry = (e) => {
		const { countries } = this.state;

		countries.forEach((el) => {
			if (e.target.value === el.name) {
				this.setState({ country: e.target.value, phoneCode: el.code }, () => {
					Axios({
						url: `${baseUrl}/state/${el.id}`,
						method: "GET",
					})
						.then((res) => {
							console.log(res.data);
							this.setState({ states: res.data.data });
						})
						.catch((err) => {
							console.log(err);
						});
				});
			}
		});
	};

	render() {
		const { classes } = this.props;
		const {
			first_name,
			last_name,
			mobile,
			prefered_contact,
			country,
			state,
			postal_code,
			errorObj,
			countries,
			states,
			loading,
		} = this.state;

		return (
			<Container maxWidth="lg">
				<Grid container spacing={1}>
					<Grid item className={classes.hide} sm={4} md={3}>
						<List>
							<Link
								to="/profile"
								style={{ textDecoration: "none", color: "#666666" }}
							>
								<ListItem
									className={
										window.location.pathname === "profile"
											? classes.listItemActive
											: classes.listItem
									}
								>
									<ListItemIcon
										style={{
											minWidth: "40px",
											width: "40px",
											color: "inherit",
										}}
									>
										<FaRegUserCircle
											style={{ fontSize: 21, color: "inherit" }}
										/>
									</ListItemIcon>
									<ListItemText primary="My Profile" />
								</ListItem>
							</Link>
							<Link
								to="/edit-profile"
								style={{ textDecoration: "none", color: "#666666" }}
							>
								<ListItem
									className={
										window.location.pathname === "/edit-profile"
											? classes.listItemActive
											: classes.listItem
									}
								>
									<ListItemIcon
										style={{
											minWidth: "40px",
											width: "40px",
											color: "inherit",
										}}
									>
										<EditIcon style={{ fontSize: 21, color: "inherit" }} />
									</ListItemIcon>
									<ListItemText primary="Edit Profile" />
								</ListItem>
							</Link>
							<Link
								to="/change-password"
								style={{ textDecoration: "none", color: "#666666" }}
							>
								<ListItem
									className={
										window.location.pathname === "/change-password"
											? classes.listItemActive
											: classes.listItem
									}
								>
									<ListItemIcon
										style={{
											minWidth: "40px",
											width: "40px",
											color: "inherit",
										}}
									>
										<LockIcon style={{ fontSize: 21, color: "inherit" }} />
									</ListItemIcon>
									<ListItemText primary="Change Password" />
								</ListItem>
							</Link>
							<Link
								to="/donation-history"
								style={{ textDecoration: "none", color: "#666666" }}
							>
								<ListItem className={classes.listItem}>
									<ListItemIcon
										style={{
											minWidth: "40px",
											width: "40px",
											color: "inherit",
										}}
									>
										<HistoryIcon style={{ fontSize: 21, color: "inherit" }} />
									</ListItemIcon>
									<ListItemText primary="Donation History" />
								</ListItem>
							</Link>
						</List>
					</Grid>
					<Grid xs={12} sm={6} md={9}>
						<Paper elevation={3} style={{ padding: "50px 30px" }}>
							{this.state.isProfile ? (
								""
							) : (
								<span style={{ color: "red" }}>
									<b>Please complete your profile.</b>
								</span>
							)}
							<div className={classes.formHeader}>
								<h4 className={classes.formHeading}>Edit Profile:</h4>
							</div>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={12} md={6}>
									<InputLabel className={classes.inputLabel}>
										First Name: *
									</InputLabel>
									<TextField
										variant="outlined"
										size="small"
										name="first_name"
										placeholder="First Name"
										value={first_name}
										className={
											errorObj.first_name
												? classes.textFieldError
												: classes.textField
										}
										// InputProps={{
										// 	classes: {
										// 		notchedOutline: errorObj.first_name
										// 			? classes.notchedOutlineError
										// 			: classes.notchedOutline,
										// 		focused: errorObj.first_name
										// 			? classes.focusedError
										// 			: classes.focused,
										// 	},
										// }}
										onChange={this.handleChange}
									/>
									<InputLabel className={classes.inputLabel}>
										Last Name: *
									</InputLabel>
									<TextField
										variant="outlined"
										size="small"
										name="last_name"
										placeholder="Last Name"
										value={last_name}
										className={
											errorObj.last_name
												? classes.textFieldError
												: classes.textField
										}
										// InputProps={{
										// 	classes: {
										// 		notchedOutline: errorObj.last_name
										// 			? classes.notchedOutlineError
										// 			: classes.notchedOutline,
										// 		focused: errorObj.last_name
										// 			? classes.focusedError
										// 			: classes.focused,
										// 	},
										// }}
										onChange={this.handleChange}
									/>
									<InputLabel className={classes.inputLabel}>
										Contact Number: *
									</InputLabel>
									<TextField
										variant="outlined"
										size="small"
										name="mobile"
										placeholder="Mobile Number"
										value={mobile}
										className={
											errorObj.mobile
												? classes.textFieldError
												: classes.textField
										}
										// InputProps={{
										// 	classes: {
										// 		notchedOutline: errorObj.mobile
										// 			? classes.notchedOutlineError
										// 			: classes.notchedOutline,
										// 		focused: errorObj.mobile
										// 			? classes.focusedError
										// 			: classes.focused,
										// 	},
										// }}
										onChange={this.handleChange}
										inputProps={{ maxLength: "11" }}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<InputLabel className={classes.inputLabel}>
										Country: *
									</InputLabel>
									<Select
										native
										value={country}
										selected={country}
										onChange={this.handleSelectCountry}
										className={classes.select}
										style={{ height: 40 }}
										// inputProps={{
										// 	classes: {
										// 		notchedOutline: classes.notchedOutline,
										// 		focused: classes.focused,
										// 	},
										// }}
									>
										<option aria-label="None" value="" />
										{countries.length > 0 &&
											countries.map((el, i) => (
												<option value={el.name}>{el.name}</option>
											))}
									</Select>
									<InputLabel className={classes.inputLabel}>
										State: *
									</InputLabel>
									<Select
										native
										value={state}
										// defaultValue={state}
										onChange={(e) => {
											this.setState({ state: e.target.value });
										}}
										className={classes.select}
										style={{ height: 40 }}
										// inputProps={{
										// 	classes: {
										// 		notchedOutline: classes.notchedOutline,
										// 		focused: classes.focused,
										// 	},
										// }}
									>
										<option disabled value="">
											{state}
										</option>
										{states.length > 0 &&
											states.map((el, i) => (
												<option value={el.name}>{el.name}</option>
											))}
									</Select>
									<InputLabel className={classes.inputLabel}>
										Post Code: *
									</InputLabel>
									<TextField
										variant="outlined"
										size="small"
										name="postal_code"
										placeholder="Post Code"
										value={postal_code}
										className={
											errorObj.postal_code
												? classes.textFieldError
												: classes.textField
										}
										// InputProps={{
										// 	classes: {
										// 		notchedOutline: errorObj.postal_code
										// 			? classes.notchedOutlineError
										// 			: classes.notchedOutline,
										// 		focused: errorObj.postal_code
										// 			? classes.focusedError
										// 			: classes.focused,
										// 	},
										// }}
										onChange={this.handleChange}
										inputProps={{ maxLength: "5" }}
									/>
								</Grid>
							</Grid>
							<div>
								<label>
									Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<input
										type="radio"
										name="prefered_contact"
										checked={prefered_contact === "email"}
										value="email"
										onChange={(e) => {
											this.setState({ prefered_contact: e.target.value });
										}}
									/>
								</label>
								<label>
									SMS
									<input
										type="radio"
										name="prefered_contact"
										checked={prefered_contact === "sms"}
										value="sms"
										onChange={(e) => {
											this.setState({ prefered_contact: e.target.value });
										}}
									/>
								</label>
							</div>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								{loading ? (
									<Button variant="contained" className={classes.btn}>
										<CircularProgress
											style={{ color: "#fff", width: 25, height: 25 }}
										/>
									</Button>
								) : (
									<Button
										variant="contained"
										className={classes.btn}
										onClick={this.handleUpdateUser}
									>
										Save
									</Button>
								)}
							</div>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		);
	}
}

export default withStyles(styles)(EditProfile);
