import React, { Component } from 'react';

import { Container, Grid, withStyles, InputLabel, TextField, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Axios from 'axios';
import baseUrl from '../../Utils/baseUrl';
import swal from 'sweetalert2';
import Personal from './Components/Personal';
import Skills from './Components/Skills';
import Emergency from './Components/Emergency';
import Image from './Components/Image';
import Modal from './Components/SuccessModal';
import Success from './Components/CompletedScreen';

import './Volunteer.css';

const styles = theme => ({
    stepperCard: {
        padding: 25,
        background: '#fff',
        borderRadius: 4,
        boxShadow: '0px 0px 6px 3px #777',
        fontFamily: "'Roboto', sans-serif",
        fontSize: 16,
        fontWeight: '300',
        color: '#888',
        textAlign: 'center',
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: '15%'
        // }
    },
    formHeading1: {
        fontSize: 25,
        color: '#09926e',
        margin: '0 0 5px',
        textAlign: 'center'
    },
    text: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 16,
        color: '#888888',
        margin: '0 0 10px',
        textAlign: 'center'
    },
    formWizard: {
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
        marginTop: 20,
    },
    stepBoxes: {
        width: '25%',
        display: 'inline-block',
        listStyle: 'none',
        paddingTop: 10,
        border: '2px solid #fff',
        background: '#ccc',
        msTransform: 'skewX(-30deg)',
        webkitTransform: 'skewX(-30deg)',
        transform: 'skewX(-30deg)',
    },
    activeStepBoxes: {
        width: '25%',
        display: 'inline-block',
        listStyle: 'none',
        paddingTop: 10,
        border: '2px solid #fff',
        background: '#09926E',
        msTransform: 'skewX(-30deg)',
        webkitTransform: 'skewX(-30deg)',
        transform: 'skewX(-30deg)',
    },
    iconContainer: {
        display: 'inline-block',
        listStyle: 'none',
        backgroundColor: '#DDDDDD',
        borderRadius: '50%',
        // height: 40,
        width: '25%',
        // marginTop: 4,
        // transform: 'skewX(30deg)',
        // display: 'inline-block'
    },
    activeIconContainer: {
        display: 'inline-block',
        listStyle: 'none',
        backgroundColor: '#DDDDDD',
        borderRadius: '50%',
        // height: 40,
        width: '25%',
        // marginTop: 4,
        // transform: 'skewX(30deg)',
        // display: 'inline-block'
    },
    stepTitle: {
        color: '#fff',
        transform: 'skewX(30deg)'
    },
});

class Volunteer extends Component {

    state = {
        activeStep: 0,
        step1Status: false,
        step2Status: false,
        step3Status: false,
        step4Status: false,
        volunteerInformation: {},

        showModal: false,
        isCompleted: false,
    }

    handleNext = (step, data) => {
        const { activeStep, volunteerInformation } = this.state;

        this.setState({
            activeStep: activeStep + 1,
            [`step${step}Status`]: true,
            volunteerInformation: { ...volunteerInformation, ...data }
        });
    };

    handleBack = () => {
        const { activeStep } = this.state;

        this.setState({
            activeStep: activeStep - 1,
        });
    };

    handleSubmit = (data) => {
        const { volunteerInformation } = this.state;
        this.setState({
            loading: true,
            // activeStep: 4,
            volunteerInformation: { ...volunteerInformation, ...data }
        }, () => {
            this.handleRegisterVolunteer();
            // console.log(tempInterest.toString())
        })
    }

    hideModal = () => {
        this.setState({
            showModal: false,
            activeStep: 4,
            isCompleted: true
        })
    }

    handleRegisterVolunteer = () => {
        const { volunteerInformation } = this.state;
        let tempInterest = [];
        const formData = new FormData();

        volunteerInformation.intrest.forEach(el => {
            if (el.checked) {
                tempInterest.push(el.option)
            }
        });

        formData.append("name", volunteerInformation.fullName);
        formData.append("st_add", volunteerInformation.address);
        formData.append("city", volunteerInformation.city);
        formData.append("state", volunteerInformation.state);
        formData.append("country", "Australia");
        formData.append("postal", volunteerInformation.zipCode);
        formData.append("mobile", volunteerInformation.contactNumber);
        formData.append("email", volunteerInformation.email);
        formData.append("pref_contact", volunteerInformation.preferredContact);
        formData.append("skill", volunteerInformation.specialSkills);
        formData.append("pr_exp", volunteerInformation.previousExperience);
        formData.append("availability", volunteerInformation.availability);
        formData.append("intrest", tempInterest.toString());
        formData.append("em_name", volunteerInformation.em_name);
        formData.append("em_postal", volunteerInformation.em_postal);
        formData.append("em_city", volunteerInformation.em_city);
        formData.append("em_state", volunteerInformation.em_state);
        formData.append("em_country", volunteerInformation.em_country);
        formData.append("em_mobile", volunteerInformation.em_mobile);
        formData.append("em_email", volunteerInformation.em_email);
        formData.append("em_preffered_contact", volunteerInformation.em_preffered_contact);
        formData.append("profile_image_link", volunteerInformation.photoFile);
        formData.append("sign_image_link", volunteerInformation.signatureFile);

        console.log(volunteerInformation)

        Axios({
            url: `${baseUrl}/volunteer/create`,
            method: "POST",
            data: formData
        }).then(res => {
            console.log(res.data);
            if (res && res.data.code === "400") {
                swal.fire({
                    icon: 'error',
                    text: `${res.data.message}`,
                })
            } else {
                this.setState({
                    activeStep: 4,
                    isCompleted: true
                });
                setTimeout(() => {
                    window.location.replace("/");
                }, 1000);
            }

        }).catch(err => {
            console.log(err);
            if (err && err.response) {
                console.log(err.response.data)
                swal.fire({
                    icon: 'error',
                    text: `${err.response.data.message}`,
                })
            }
        })
    }

    renderComponents = (stepIndex) => {
        const { volunteerInformation, activeStep } = this.state;

        switch (activeStep) {
            case 0:
                return <Personal
                    nextHandler={this.handleNext}
                    backHandler={this.handleBack}
                    data={(volunteerInformation && volunteerInformation.fullName) && volunteerInformation}
                    stepNumber={activeStep}
                />;
            case 1:
                return <Skills
                    nextHandler={this.handleNext}
                    backHandler={this.handleBack}
                    stepNumber={activeStep}
                    data={(volunteerInformation && volunteerInformation.specialSkills) && volunteerInformation}
                />;
            case 2:
                return <Emergency
                    nextHandler={this.handleNext}
                    backHandler={this.handleBack}
                    stepNumber={activeStep}
                    data={(volunteerInformation && volunteerInformation.em_email) && volunteerInformation}
                />;
            case 3:
                return <Image
                    nextHandler={this.handleNext}
                    backHandler={this.handleBack}
                    stepNumber={activeStep}
                    submitHandler={this.handleSubmit}
                    data={volunteerInformation}
                />;
            default:
                return null;
        }
    }

    render() {
        const { classes } = this.props;
        const { activeStep, isCompleted, showModal } = this.state;

        // console.log(this.state.volunteerInformation);

        return (
            <Container maxWidth="sm">

                <div className="volunteer">
                    <h3 className={classes.formHeading1}>VOLUNTEER APPLICATION</h3>
                    <p className={classes.text}>Fill all form field to go next step</p>
                    <div className={classes.formWizard}>
                        <ul id="progressbar">
                            <li id="personal" className={activeStep >= 0 ? "active" : "custom"}>
                                <strong>Personal</strong>
                            </li>
                            <li id="skills" className={activeStep >= 1 ? "active" : "custom"}>
                                <strong>Skills</strong>
                            </li>
                            <li id="emergency" className={activeStep >= 2 ? "active" : "custom"}>
                                <strong>Emergency</strong>
                            </li>
                            <li id="image" className={activeStep >= 3 ? "active" : "custom"}>
                                <strong>Image</strong>
                            </li>
                        </ul>
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: `${(activeStep + 1) * 20}%` }}
                            />
                        </div>
                    </div>
                    {
                        !isCompleted && this.renderComponents()
                    }
                    {
                        isCompleted && <Success />
                    }
                    {/* {
                        showModal && <Modal {...this.props} hideModal={this.hideModal} />
                    } */}
                </div>
            </Container>
        )
    }
}

export default withStyles(styles)(Volunteer);
