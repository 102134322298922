import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown'

class ItemDescription extends Component {

    render() {
        const { description } = this.props;

        return (
            <div>
                <ReactMarkdown
                    source={description}
                    escapeHtml={false}
                />
            </div>
        );
    }
}

export default ItemDescription;