import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Chip,
	Button,
	CircularProgress,
} from "@material-ui/core";
//React Router
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Swal from "sweetalert2";

import Dialog from "./Modal";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	tableContainer: {
		fontFamily: "inherit",
		// maxHeight: 400,
	},
	tableRow: {
		"&:hover": {
			color: "#09926E",
			backgroundColor: "#f5f5f5",
		},
	},
	tableCell: {
		fontFamily: "inherit",
		backgroundColor: "#09926E",
		color: "white",
		textAlign: "left",
		fontWeight: "bold",
	},
	tableCellBody: {
		textAlign: "left",
	},
	idCell: {
		textAlign: "rigth",
		color: "green",
	},
	btn: {
		backgroundColor: "#09926E",
		color: "#fff",
		fontWeight: "bold",
		"&:hover": {
			backgroundColor: "#09926E",
		},
	},
}));

function Employes(props) {
	const classes = useStyles();
	const { donationList } = props;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [rows, setrows] = useState([]);

	React.useEffect(() => {
		//storing data in state
		setrows(donationList);
	}, [donationList]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<Paper className={classes.root}>
			<TableContainer className={classes.tableContainer}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								className={classes.tableCell}
								style={{ textAlign: "right" }}
							>
								Invoice #
							</TableCell>
							<TableCell
								className={classes.tableCell}
								style={{ textAlign: "center" }}
							>
								Action
							</TableCell>
							<TableCell className={classes.tableCell}>Date</TableCell>
							<TableCell
								className={classes.tableCell}
								style={{ textAlign: "right" }}
							>
								Amount
							</TableCell>
							{/* <TableCell className={classes.tableCell}>Payment Method</TableCell> */}
							<TableCell className={classes.tableCell}>
								Payment Status
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => {
								let date = moment(row.payment_date).format("dddd, DD/MM/YY");
								return (
									<TableRow key={index} className={classes.tableRow}>
										<TableCell
											className={classes.tableCellBody}
											style={{ textAlign: "right" }}
										>
											{row.invoice_number}
											{/* <Dialog
                                            transactionId={row.trasaction_id}
                                            invoiceNumber={row.invoice_number}
                                            date={date}
                                            totalAmount={row.donation_amount}
                                            donorEmail={row.donor_email}
                                            donorName={`${row.donor_first_name} ${row.donor_last_name}`}
                                            allData={row}
                                            paymentStatus={row.status}
                                        ></Dialog> */}
										</TableCell>
										<TableCell
											className={classes.tableCellBody}
											style={{ textAlign: "right" }}
										>
											<Dialog
												transactionId={row.trasaction_id}
												invoiceNumber={row.invoice_number}
												date={date}
												totalAmount={row.donation_amount}
												donorEmail={row.donor_email}
												donorName={`${row.donor_first_name} ${row.donor_last_name}`}
												allData={row}
												paymentStatus={row.status}
											>
												<Button variant="outlined">Download Invoice</Button>
											</Dialog>
										</TableCell>
										<TableCell
											style={{ color: "#fd5a5a" }}
											className={classes.tableCellBody}
										>
											{date}
										</TableCell>
										<TableCell
											className={classes.tableCellBody}
											style={{ textAlign: "right" }}
										>
											${parseFloat(row.donation_amount).toFixed(2)}
										</TableCell>
										{/* <TableCell className={classes.tableCellBody}>
                                        <Chip
                                            label={row.payment_method === "stripe" ? "Credit Card" : "Paypal"}
                                            style={{ backgroundColor: '#09926E', color: '#fff' }}
                                        />
                                    </TableCell> */}
										<TableCell
											className={classes.tableCellBody}
											style={{ textAlign: "center" }}
										>
											{row.status.toUpperCase()}
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 15, 20]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</Paper>
	);
}

export default withRouter(Employes);
