import React, { useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Description from '../../Components/Markdown';

const useStles = makeStyles(theme => ({
    imageBox: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 70
        }
    },
    heading: {
        fontFamily: "'vidaloka', serif",
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            marginBottom: 50,
            fontSize: 40
        },
        marginBottom: 10,
        fontSize: 30
    },
    text: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 16,
        color: '#646464'
    }
}));



export default function PrivacyPolicy() {
    const classes = useStles();

    return (
        <Container maxWidth="lg">
            <h1 className={classes.heading}>
                {'Privacy Policy'}
            </h1>
            <Grid container spacing={3}>
                <Grid item sm={12} md={12}>
                    <div>
                        <div style={{fontFamily: "Arial, sans-serif", lineHeight: 1.6, color: "#333", maxWidth: "800px"}}>
                            <p style={{marginBottom: "20px"}}><strong>Privacy Policy for Abdul Sattar Edhi International Foundation</strong></p>
                            <p style={{marginBottom: "20px"}}>At Abdul Sattar Edhi International Foundation, we are committed to protecting the privacy and security of our donors, supporters, volunteers, and website visitors. This privacy policy sets out how we collect, use, and protect your personal information.</p>
                            <p style={{marginBottom: "20px"}}><strong>Information We Collect</strong></p>
                            <p style={{marginBottom: "20px"}}>We collect personal information from you when you donate online, register to volunteer, sign up for our newsletter, or fill out a contact form on our website. This information may include your name, address, email address, phone number, and payment details.</p>
                            <p style={{marginBottom: "20px"}}>We also collect information about your computer or mobile device, including your IP address, browser type, and operating system, for the purposes of website analytics and improving our website.</p>
                            <p style={{marginBottom: "20px"}}><strong>How We Use Your Information</strong></p>
                            <p style={{marginBottom: "20px"}}>We use the personal information you provide to process your donations, send you our newsletter, and respond to your inquiries. We may also use your information to contact you about upcoming events and fundraising activities.</p>
                            <p style={{marginBottom: "20px"}}>We do not sell, rent, or lease your personal information to third parties. We may share your information with trusted third-party service providers who assist us in operating our website, processing donations, and communicating with you.</p>
                            <p style={{marginBottom: "20px"}}>If you choose to make a donation through our website, your payment information is securely processed by our third-party payment processor, and we do not store your payment details.</p>
                            <p style={{marginBottom: "20px"}}><strong>How We Protect Your Information</strong></p>
                            <p style={{marginBottom: "20px"}}>We take the security of your personal information seriously and have implemented appropriate technical and organizational measures to protect your information from unauthorized access, loss, misuse, or alteration.</p>
                            <p style={{marginBottom: "20px"}}><strong>Your Rights</strong></p>
                            <p style={{marginBottom: "20px"}}>You have the right to request access to the personal information we hold about you, and to request that we correct or delete any inaccurate or incomplete information. You also have the right to object to the processing of your personal information for marketing purposes.</p>
                            <p style={{marginBottom: "20px"}}>To exercise any of these rights or if you have any questions or concerns about our privacy policy, please contact us at <a href="mailto:info@edhifoundation.org.au" style={{color: "#007bff", textDecoration: "none"}}>info@edhifoundation.org.au</a>.</p>
                            <p style={{marginBottom: "20px"}}><strong>Changes to This Privacy Policy</strong></p>
                            <p style={{marginBottom: "20px"}}>We may update this privacy policy from time to time. Any changes we make will be posted on this page, and we encourage you to review this policy periodically.</p>
                            <p style={{marginBottom: "20px"}}><strong>Last Updated: April 25, 2023.</strong></p>
                        </div>

                    </div>
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>
        </Container>
    )
}
