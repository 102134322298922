import React, { Fragment, useEffect, useState } from 'react';
import { Container, makeStyles, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import baseUrl from '../../Utils/baseUrl';
import Description from '../../Components/Markdown';

const useStles = makeStyles(theme => ({
    imageBoxParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    imageBox: {
        border: '1px solid rgba(167, 167, 167, 0.5)',
        color: '#444',
        textAlign: 'center',
        padding: 10,
        width: '60%'
    },
    image: {
        display: 'block',
        width: '100%',
        height: 'auto'
    },
    heading: {
        fontFamily: "'vidaloka', serif",
        textAlign: 'center',
        marginBottom: 50
    },
    heading2: {
        fontFamily: "'vidaloka', serif",
        fontSize: 16,
        margin: "5px 0px 10px",
        fontWeight: 'bold'
    },
    list: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 16,
        color: '#646464',
        listStyle: 'none'
    },
    text: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 16,
        color: '#646464'
    }
}));

export default function EdhiAwards() {
    const classes = useStles();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        Axios({
            url: `${baseUrl}/general-pages`,
            method: "POST",
            data: {
                type: "recognition"
            }
        }).then(res => {
            setData(res.data.data[0]);
            setLoading(false)
        }).catch(err => {
            console.log(err.response.data);
            setLoading(false)
        })

    }, []);

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: 500
                }}
            >
                <CircularProgress style={{ color: '#09926E', width: 80, height: 80 }} />
            </div>
        )
    }
    else {
        return (
            <Fragment>
                <h1 className={classes.heading}>
                    {data.title}
                </h1>
                <div style={{ backgroundColor: '#f8f7f7' }}>
                    <Container maxWidth="lg">
                        <div className={classes.imageBoxParent}>
                            <div className={classes.imageBox}>
                                <img
                                    className={classes.image}
                                    // style={{ width: '365px' }}
                                    src={data.image}
                                    alt='banner'
                                />
                                <p className={classes.text}>EDHI, International and National Recognition / Awards</p>
                            </div>
                        </div>
                        <div>
                            <Description description={data.description} />
                        </div>
                    </Container >
                </div>

            </Fragment>
        )
    }
}