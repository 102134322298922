import React, { Component, Fragment } from 'react';
import { withStyles, InputLabel, TextField, Button, Table, TableHead, TableBody, TableRow, TableCell, IconButton } from '@material-ui/core';
import Axios from 'axios';
import baseUrl from '../../../Utils/baseUrl';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';

const styles = (theme) => ({
	formHeader: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		marginTop: 10,
	},
	formHeading: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#09926E",
		margin: "0 0 10px",
		textAlign: "left",
	},
	formNo: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#888888",
		margin: "0 0 10px",
	},
	inputLabel: {
		fontWeight: "normal",
		marginBottom: 15,
		textAlign: "left",
	},
	textField: {
		fontFamily: "'Roboto', sans-serif",
		backgroundColor: "#ECEFF1",
		color: "black",
		// height: 30,
		width: "100%",
		marginBottom: 20,
		"&:hover": {
			"& $notchedOutline": {
				borderColor: "#ddd",
			},
		},
	},
	notchedOutline: {
		borderColor: "#ddd",
		"&:hover": {
			borderColor: "#ddd",
		},
	},
	focused: {
		"& $notchedOutline": {
			borderColor: "#ddd !important",
		},
	},
	btn: {
		backgroundColor: "#DC3545",
		color: "#fff",
		padding: "10px 35px",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#DC3545",
			color: "#fff",
			boxShadow: "none",
		},
	},
	prevBtn: {
		backgroundColor: "#BBBBBB",
		color: "#fff",
		padding: "10px 35px",
		boxShadow: "none",
		marginRight: 10,
		"&:hover": {
			backgroundColor: "#BBBBBB",
			color: "#fff",
			boxShadow: "none",
		},
	},
	cancelBtn: {
		backgroundColor: "#5CB85C",
		color: "#fff",
		padding: "10px 35px",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "red",
			color: "#fff",
			boxShadow: "none",
		},
	},
	table: {
		width: "100%",
		// display: 'flex',
		marginTop: theme.spacing(3),
		overflowX: "auto",
		// msOverflowStyle: 'none',
		// scrollbarWidth: 'none',
		// "&::-webkit-scrollbar": {
		//     display: 'none',
		//     msOverflowStyle: 'none',
		//     scrollbarWidth: 'none'
		// }
	},
	tableCell: {
		[theme.breakpoints.down("sm")]: {
			padding: "3px !important",
		},
		"& .MuiTableCell-root": {
			[theme.breakpoints.down("sm")]: {
				fontSize: "14px !important",
				padding: "0px !important",
			},
		},
	},
});

class Method extends Component {
	state = {
		donationCategories: [],
		donationType: "",
		donationSubType: "",
		quantity: "",
		amount: "",
		total: 0,
		donationMethod: [],
		is_editable_amount: "yes",
		is_editable_quantity: "yes",
		subcategory_status: false,
		category_subcategory: [],
		onBehalf: [],
		onBehalfArray: [],
		showShareHolder: false,
		selectedOption: "One-Time",
		recurringValue: "https://buy.stripe.com/6oE6qM05E6dw54Q3cd",
	};

	componentDidMount() {
		this.getStoredData();
		this.getDonationCategories();
		if (this.props.option) {
			this.getDonationTypeFromLanding(this.props.option);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps != this.props) {
			this.getStoredData();
			this.getDonationCategories();

			if (this.props.option) {
				this.getDonationTypeFromLanding(this.props.option);
			}
		}
	}

	getDonationCategories = () => {
		Axios({
			url: `${baseUrl}/categories`,
			method: "GET",
		})
			.then((res) => {
				if (res.data.data.category) {
					this.setState({ donationCategories: res.data.data.category });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	getStoredData = () => {
		if (this.props.data) {
			const { total, donationMethod } = this.props.data;
			let tempBool = false;
			donationMethod.forEach((el) => {
				if (el.onBehalf) {
					tempBool = true;
				}
			});

			this.setState({
				donationMethod: donationMethod,
				total: total,
				showShareHolder: tempBool,
			});
		}
	};

	getDonationTypeFromLanding = (option) => {
		const { donationCategories } = this.state;

		donationCategories.forEach((el) => {
			if (option === el.category_title) {
				if (el.subcategory_status === "true" && el.category_subcategory) {
					this.setState({
						donationType: el.category_title,
						type: el.category_type,
						amount: el.category_amount,
						is_editable_amount: el.is_editable_amount,
						subcategory_status: el.subcategory_status,
						category_subcategory: el.category_subcategory,
					});
				} else {
					this.setState({
						donationType: el.category_title,
						type: el.category_type,
						amount: el.category_amount,
						is_editable_amount: el.is_editable_amount,
						subcategory_status: el.subcategory_status,
					});
				}
			}
		});
	};

	getDonationType = (e) => {
		const { donationCategories } = this.state;

		if (e.target.value === "Qurban") {
			this.setState({ onBehalf: [""], onBehalfArray: [""] });
		} else {
			this.setState({ onBehalf: [], onBehalfArray: [] });
		}

		donationCategories.forEach((el) => {
			if (e.target.value === el.category_title) {
				// console.log(parseInt(el.category_amount));
				if (el.subcategory_status === "true" && el.category_subcategory) {
					this.setState({
						donationType: el.category_title,
						type: el.category_type,
						amount: parseInt(el.category_amount) > 0 ? el.category_amount : "",
						quantity: 1,
						is_editable_quantity: el.is_editable_quantity,
						is_editable_amount: el.is_editable_amount,
						subcategory_status: el.subcategory_status,
						category_subcategory: el.category_subcategory,
					});
				} else {
					this.setState({
						donationType: el.category_title,
						type: el.category_type,
						amount: parseInt(el.category_amount) > 0 ? el.category_amount : "",
						quantity: 1,
						is_editable_quantity: el.is_editable_quantity,
						is_editable_amount: el.is_editable_amount,
						subcategory_status: el.subcategory_status,
					});
				}
			}
		});
	};

	getDonationSubCategory = (e) => {
		const { category_subcategory } = this.state;

		category_subcategory.forEach((el) => {
			if (e.target.value === el.subcategory_title) {
				this.setState({
					donationSubType: el.subcategory_title,
					amount: el.subcategory_amount,
				});
			}
			if (e.target.value === "Full Cow") {
				this.setState({
					onBehalf: ["", "", "", "", "", "", ""],
				});
			}
		});
	};

	handleClickAdd = () => {
		const {
			donationType,
			quantity,
			amount,
			total,
			donationMethod,
			donationSubType,
			onBehalf,
		} = this.state;
		let tempArr = donationMethod;
		let tempOnBehalfOf = "";
		onBehalf.forEach((el) => {
			if (el !== "") {
				tempOnBehalfOf = tempOnBehalfOf + ", " + el;
			}
		});

		if (onBehalf.length > 0) {
			this.setState({ showShareHolder: true });
		}

		// console.log(tempOnBehalfOf.substring(1));

		if (donationType && amount) {
			if (donationSubType) {
				tempArr.push({
					donationType,
					donationSubType,
					quantity,
					amount: parseFloat(amount).toFixed(2),
					onBehalf: tempOnBehalfOf.substring(1),
				});
			} else {
				tempArr.push({
					donationType,
					quantity,
					amount: parseFloat(amount).toFixed(2),
				});
			}

			this.setState({
				donationMethod: tempArr,
				total: parseFloat(total) + parseInt(quantity) * parseFloat(amount),
				donationType: "",
				donationSubType: "",
				quantity: "",
				amount: 0,
				subcategory_status: false,
				onBehalf: [],
			});
		} else {
			alert("please fill all the fields");
		}
	};

	onValueChange = (event) => {
		this.setState({
			selectedOption: event.target.value,
		});
	};

	handleClickCancle = (index) => {
		const { total, donationMethod } = this.state;
		const tempArray = [...donationMethod];
		let tempTotal =
			parseFloat(total) -
			parseFloat(tempArray[index].quantity) *
				parseFloat(tempArray[index].amount);

		tempArray.splice(index, 1);
		this.setState({
			donationMethod: tempArray,
			total: tempTotal,
		});
	};

	handleClickNext = () => {
		const { donationMethod, total } = this.state;

		if (donationMethod.length > 0) {
			this.props.nextHandler("2", { donationMethod, total });
		} else {
			alert("please fill all the fields");
		}
	};

	handleChange = (e) => {
		const { errorObj } = this.state;

		if (errorObj[e.target.name]) errorObj[e.target.name] = false;

		this.setState({
			[e.target.name]: e.target.value,
			errorObj,
		});
	};

	handleValidation = () => {
		const { donationType, quantity, amount } = this.state;
		let tempErrorObj = {};

		if (!donationType) {
			tempErrorObj.donationType = true;
		} else {
			tempErrorObj.donationType = false;
		}
		if (!quantity) {
			tempErrorObj.quantity = true;
		} else {
			tempErrorObj.quantity = false;
		}
		if (!amount) {
			tempErrorObj.amount = true;
		} else {
			tempErrorObj.amount = false;
		}
		// if (!postCode) {
		//     tempErrorObj.postCode = true
		// } else {
		//     tempErrorObj.postCode = false
		// }

		this.setState({ errorObj: tempErrorObj });
	};

	handleChangeShare(i, event) {
		let onBehalf = [...this.state.onBehalf];
		onBehalf[i] = event.target.value;
		this.setState({ onBehalf });
	}

	addOnBehalfOf = (quantity) => {
		const { onBehalf } = this.state;
		let tempArr = [...onBehalf];

		if (quantity > tempArr.length) {
			const newQuantity = quantity - tempArr.length;
			for (var i = 0; i < newQuantity; i++) {
				tempArr.push("");
			}
			this.setState({ onBehalf: tempArr });
		} else if (quantity < tempArr.length) {
			const newQuantity = tempArr.length - quantity;
			for (var i = 0; i < newQuantity; i++) {
				let tempIndex = tempArr.length - 1;
				tempArr.splice(tempIndex, 1);
			}
			this.setState({ onBehalf: tempArr });
		}
		// if (quantity > tempArr2.length) {
		//     const newQuantity = quantity - tempArr2.length;
		//     for (var i = 0; i < newQuantity; i++) {
		//         tempArr2.push("");
		//     }
		//     this.setState({ onBehalf: tempArr2 });
		// } else if (quantity < tempArr2.length) {
		//     const newQuantity = tempArr2.length - quantity;
		//     for (var i = 0; i < newQuantity; i++) {
		//         let tempIndex = tempArr2.length - 1;
		//         tempArr2.splice(tempIndex, 1)
		//     }
		//     this.setState({ onBehalf: tempArr2 });
		// }
	};

	removeClick(i) {
		let onBehalf = [...this.state.onBehalf];
		onBehalf[i] = "";
		this.setState({ onBehalf });
	}

	render() {
		const { classes, backHandler } = this.props;
		const {
			donationMethod,
			total,
			donationCategories,
			amount,
			is_editable_amount,
			is_editable_quantity,
			subcategory_status,
			category_subcategory,
			donationType,
			donationSubType,
			showShareHolder,
			onBehalfArray,
		} = this.state;
		// console.log(this.state.onBehalf)

		return (
			<Fragment>
				<div className={classes.formHeader}>
					<h4 className={classes.formHeading}>Method:</h4>
					<h4 className={classes.formNo}>Step 2 - 4</h4>
				</div>
				<div>
					<div className="form-group">
						<div>
							<InputLabel className={classes.inputLabel}>
								Donation Type: *
							</InputLabel>
							<select
								style={{
									backgroundColor: "#ECEFF1",
									height: 41,
									borderColor: "#ddd",
									width: "100%",
									marginBottom: 20,
								}}
								name="input_3"
								className="form-control required"
								aria-invalid="false"
								onChange={this.getDonationType}
								value={donationType}
							>
								<option value="" selected="selected" className="gf_placeholder">
									** Select Donation Type **
								</option>
								{donationCategories.length > 0 &&
									donationCategories.map((cat, index) => (
										<option key={index} value={cat.category_title}>
											{cat.category_title}
										</option>
									))}
							</select>
						</div>
					</div>
					{donationType === "General Donation" ? (
						<div>
							<div className="radio">
								<label>
									<input
										type="radio"
										value="One-Time"
										checked={this.state.selectedOption === "One-Time"}
										onChange={this.onValueChange}
									/>
									One-Time
								</label>
							</div>
							<div className="radio">
								<label>
									<input
										type="radio"
										value="Recurring"
										checked={this.state.selectedOption === "Recurring"}
										onChange={this.onValueChange}
									/>
									Monthly Recurring
								</label>
							</div>
						</div>
					) : (
						""
					)}
					{subcategory_status === "true" && (
						<div className="form-group">
							<div>
								<InputLabel className={classes.inputLabel}>
									Donation Subcategory: *
								</InputLabel>
								<select
									style={{
										backgroundColor: "#ECEFF1",
										height: 41,
										borderColor: "#ddd",
										width: "100%",
										marginBottom: 20,
									}}
									name="input_3"
									className="form-control required"
									aria-invalid="false"
									onChange={this.getDonationSubCategory}
									value={donationSubType}
								>
									<option
										value=""
										selected="selected"
										className="gf_placeholder"
									>
										** Select Donation Subcategory **
									</option>
									{category_subcategory.length > 0 &&
										category_subcategory.map((subcat, index) => (
											<option key={index} value={subcat.subcategory_title}>
												{subcat.subcategory_title}
											</option>
										))}
								</select>
							</div>
						</div>
					)}
					{(is_editable_quantity === "yes" ||
						is_editable_quantity === "Yes") && (
						<Fragment>
							<InputLabel className={classes.inputLabel}>
								Enter Quantity: *
							</InputLabel>
							<TextField
								variant="outlined"
								size="small"
								type="number"
								placeholder="1"
								value={this.state.quantity}
								className={classes.textField}
								InputProps={{
									classes: {
										notchedOutline: classes.notchedOutline,
										focused: classes.focused,
									},
								}}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
									if (donationType === "Qurban") {
										if (donationSubType === "Full Cow") {
											let totalLength = parseInt(e.target.value) * 7;
											// for (i; i > totalLength; i++){
											this.addOnBehalfOf(totalLength);
											// }
										} else {
											this.addOnBehalfOf(parseInt(e.target.value));
										}
									}
								}}
							/>
						</Fragment>
					)}
					{this.state.onBehalf.length > 0 && (
						<Fragment>
							<InputLabel className={classes.inputLabel}>
								Sacrifice on behalf of: *
							</InputLabel>
							{this.state.onBehalf.map((el, i) => (
								<div
									key={i}
									style={{ display: "flex", justifyContent: "space-between" }}
								>
									<div style={{ width: "90%" }}>
										<TextField
											variant="outlined"
											size="small"
											placeholder="Sacrifice on behalf of"
											value={el || ""}
											onChange={this.handleChangeShare.bind(this, i)}
											className={classes.textField}
											InputProps={{
												classes: {
													notchedOutline: classes.notchedOutline,
													focused: classes.focused,
												},
											}}
										/>
									</div>
									<IconButton
										style={{ height: 50, width: 50 }}
										onClick={this.removeClick.bind(this, i)}
									>
										<CancelIcon />
									</IconButton>
								</div>
							))}
						</Fragment>
					)}
					{donationType === "General Donation" &&
					this.state.selectedOption === "One-Time" ? (
						<div>
							<InputLabel className={classes.inputLabel}>
								Enter Amount: *
							</InputLabel>
							<TextField
								variant="outlined"
								size="small"
								placeholder="0.00"
								type="number"
								value={amount}
								disabled={
									is_editable_amount === "no" || is_editable_amount === "No"
								}
								className={classes.textField}
								InputProps={{
									classes: {
										notchedOutline: classes.notchedOutline,
										focused: classes.focused,
									},
								}}
								onChange={(e) => {
									this.setState({ amount: e.target.value });
								}}
							/>
						</div>
					) : donationType === "General Donation" &&
					  this.state.selectedOption === "Recurring" ? (
						<div>
							<InputLabel className={classes.inputLabel}>
								Recurring Donation Amount: *
							</InputLabel>
							<select
								style={{
									backgroundColor: "#ECEFF1",
									height: 41,
									borderColor: "#ddd",
									width: "100%",
									marginBottom: 20,
								}}
								// name="input_3"
								className="form-control required"
								aria-invalid="false"
								onChange={(e) => {
									this.setState({ recurringValue: e.target.value });
								}}
								// value={donationType}
							>
								<option
									selected="selected"
									value="https://buy.stripe.com/6oE6qM05E6dw54Q3cd"
								>
									1.00
								</option>
								<option value="https://buy.stripe.com/dR64iEbOmatM7cY7su">
									5.00
								</option>
								<option value="https://buy.stripe.com/6oE5mI8Ca6dwbtedQT">
									10.00
								</option>
								<option value="https://buy.stripe.com/eVabL67y66dwgNy3cg">
									15.00
								</option>
								<option value="https://buy.stripe.com/3cs8yUg4C45o2WI4gl">
									20.00
								</option>
								<option value="https://buy.stripe.com/8wM4iE6u21Xg8h26ou">
									25.00
								</option>
								<option value="https://buy.stripe.com/fZecPa19IbxQ9l6fZ5">
									50.00
								</option>
								<option value="https://buy.stripe.com/28o02oaKi8lE9l6cMU">
									100.00
								</option>
								<option value="https://buy.stripe.com/28o4iE4lUcBUgNydQZ">
									250.00
								</option>
							</select>
						</div>
					) : (
						<div>
							<InputLabel className={classes.inputLabel}>
								Enter Amount: *
							</InputLabel>
							<TextField
								variant="outlined"
								size="small"
								placeholder="0.00"
								type="number"
								value={amount}
								disabled={
									is_editable_amount === "no" || is_editable_amount === "No"
								}
								className={classes.textField}
								InputProps={{
									classes: {
										notchedOutline: classes.notchedOutline,
										focused: classes.focused,
									},
								}}
								onChange={(e) => {
									this.setState({ amount: e.target.value });
								}}
							/>
						</div>
					)}
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						{donationType === "General Donation" &&
						this.state.selectedOption === "Recurring" ? (
							<Button
								style={{ backgroundColor: "#09926E" }}
								variant="contained"
								className={classes.btn}
								onClick={() => {
									window.location.href = `${
										this.state.recurringValue
									}?prefilled_email=${localStorage.getItem(
										"userEmail"
									)}&client_reference_id=${localStorage.getItem("userId")}`;
								}}
							>
								Create Recurring Donation
							</Button>
						) : (
							""
						)}
						{(donationType === "General Donation" &&
							this.state.selectedOption === "One-Time") ||
						donationType !== "General Donation" ? (
							<Button
								style={{ backgroundColor: "#09926E" }}
								variant="contained"
								className={classes.btn}
								onClick={this.handleClickAdd}
							>
								Add Donation
							</Button>
						) : (
							""
						)}
					</div>
					<div className={classes.table}>
						<Table style={{ minWidth: 340 }}>
							<TableHead>
								<TableRow>
									<TableCell
										size="small"
										className={classes.tableCell}
										style={{ textAlign: "left", padding: 10 }}
									>
										Donation Type
									</TableCell>
									<TableCell
										className={classes.tableCell}
										style={{ textAlign: "left", padding: 10 }}
									>
										Donation Subcategory
									</TableCell>
									<TableCell
										className={classes.tableCell}
										style={{ textAlign: "center", padding: 10 }}
									>
										Quantity
									</TableCell>
									{showShareHolder && (
										<TableCell
											className={classes.tableCell}
											style={{ textAlign: "center", padding: 10 }}
										>
											S/ on behalf of
										</TableCell>
									)}
									<TableCell
										className={classes.tableCell}
										style={{ textAlign: "right", padding: 10 }}
									>
										Amount
									</TableCell>
									<TableCell
										className={classes.tableCell}
										style={{ textAlign: "right", padding: 10 }}
									>
										Total
									</TableCell>
									<TableCell
										className={classes.tableCell}
										style={{ textAlign: "left", padding: 10 }}
									>
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{donationMethod.length > 0 &&
									donationMethod.map((val, index) => (
										<TableRow key={index} className={classes.tableRow}>
											<TableCell
												className={classes.tableCellBody}
												style={{ textAlign: "left", padding: 10 }}
											>
												{val.donationType}
											</TableCell>
											<TableCell
												className={classes.tableCellBody}
												style={{ textAlign: "left", padding: 10 }}
											>
												{val.donationSubType}
											</TableCell>
											<TableCell
												className={classes.tableCellBody}
												style={{ textAlign: "center", padding: 10 }}
											>
												{val.quantity}
											</TableCell>
											{showShareHolder && (
												<TableCell
													className={classes.tableCellBody}
													style={{ textAlign: "center", padding: 10 }}
												>
													{val.onBehalf}
												</TableCell>
											)}
											<TableCell
												className={classes.tableCellBody}
												style={{ textAlign: "right", padding: 10 }}
											>
												${val.amount}
											</TableCell>
											<TableCell
												className={classes.tableCellBody}
												style={{ textAlign: "right", padding: 10 }}
											>
												$
												{(
													parseFloat(val.quantity) * parseFloat(val.amount)
												).toFixed(2)}
											</TableCell>
											<TableCell
												className={classes.tableCellBody}
												style={{ textAlign: "left", padding: 10 }}
											>
												<DeleteIcon
													style={{ color: "red", cursor: "pointer" }}
													onClick={() => {
														this.handleClickCancle(index);
													}}
												/>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</div>
					<br />
					<InputLabel className={classes.inputLabel}>Subtotal: </InputLabel>
					<h4
						className={classes.formNo}
						style={{ fontSize: 19, textAlign: "left" }}
					>
						<strong>{total.toFixed(2)}</strong> AUD
					</h4>
					<br />
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button
							variant="contained"
							className={classes.prevBtn}
							onClick={backHandler}
						>
							previous
						</Button>
						<Button
							variant="contained"
							className={classes.btn}
							onClick={this.handleClickNext}
						>
							Next
						</Button>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default withStyles(styles)(Method);
