import React, { Component, Fragment } from 'react';

import {
	withStyles,
	InputLabel,
	TextField,
	Checkbox,
	Button,
} from "@material-ui/core";

import ContextAPI from "../../../ContextAPI/contextAPI";

const styles = (theme) => ({
	formHeader: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		marginTop: 10,
	},
	formHeading: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#09926E",
		margin: "0 0 10px",
		textAlign: "left",
	},
	formNo: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#888888",
		margin: "0 0 10px",
	},
	inputLabel: {
		fontWeight: "normal",
		marginBottom: 15,
		textAlign: "left",
	},
	textField: {
		fontFamily: "'Roboto', sans-serif",
		backgroundColor: "#ECEFF1",
		color: "black",
		// height: 30,
		width: "100%",
		marginBottom: 20,
		"&:hover": {
			"& $notchedOutline": {
				borderColor: "#ddd",
			},
		},
	},
	notchedOutline: {
		borderColor: "#ddd",
		"&:hover": {
			borderColor: "#ddd",
		},
	},
	focused: {
		"& $notchedOutline": {
			borderColor: "#ddd !important",
		},
	},
	textFieldError: {
		fontFamily: "'Roboto', sans-serif",
		backgroundColor: "#ECEFF1",
		color: "black",
		width: "100%",
		marginBottom: 20,
		"&:hover": {
			"& $notchedOutlineError": {
				borderColor: "red !important",
			},
		},
	},
	notchedOutlineError: {
		borderColor: "red",
		"&:hover": {
			borderColor: "red !important",
		},
	},
	focusedError: {
		"& $notchedOutlineError": {
			borderColor: "#ddd !important",
		},
	},
	btn: {
		backgroundColor: "#DC3545",
		color: "#fff",
		padding: "10px 35px",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#DC3545",
			color: "#fff",
			boxShadow: "none",
		},
	},
});

class Personal extends Component {
	state = {
		isAnonymous: false,
		firstName: "",
		lastName: "",
		donorEmail: "",
		postCode: "",
		errorObj: {},
		disableInput: false,
	};

	static contextType = ContextAPI;

	componentDidMount() {
		this.getStoredData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps != this.props) this.getStoredData();
	}

	getStoredData = () => {
		const { user } = this.context;
		if (this.props.data) {
			const { firstName, lastName, donorEmail, postCode } = this.props.data;

			this.setState({
				firstName: firstName,
				lastName: lastName,
				donorEmail: donorEmail,
				postCode: postCode,
			});

			localStorage.setItem("userEmail", donorEmail);
		} else {
			if (user.first_name) {
				this.setState({
					firstName: user.first_name,
					lastName: user.last_name,
					donorEmail: user.email,
					postCode: user.postal_code,
					disableInput: true,
				});
				localStorage.setItem("userEmail", user.email);
				localStorage.setItem("userId", user.id);
			}
		}
	};

	handleClickNext = () => {
		const { isAnonymous, firstName, lastName, donorEmail, postCode } =
			this.state;

		if (donorEmail) {
			this.props.nextHandler("1", {
				isAnonymous,
				firstName,
				lastName,
				donorEmail,
				postCode,
			});
		} else {
			this.handleValidation();
		}
	};

	handleChange = (e) => {
		const { errorObj } = this.state;

		if (errorObj[e.target.name]) errorObj[e.target.name] = false;

		if (e.target.name === "isAnonymous") {
			var fName = document.getElementById("firstName");
			var lName = document.getElementById("lastName");

			if (e.target.checked === true) {
				fName.setAttribute("disabled", true);
				fName.setAttribute("style", "opacity:0.25");
				lName.setAttribute("disabled", true);
				lName.setAttribute("style", "opacity:0.25");
				this.setState({
					[e.target.name]: true,
					errorObj,
				});
			} else {
				fName.removeAttribute("disabled");
				fName.setAttribute("style", "opacity:1");
				lName.removeAttribute("disabled");
				lName.setAttribute("style", "opacity:1");
				this.setState({
					[e.target.name]: false,
					errorObj,
				});
			}
		} else {
			this.setState({
				[e.target.name]: e.target.value,
				errorObj,
			});
		}
	};

	handleValidation = () => {
		const { firstName, lastName, donorEmail, postCode } = this.state;
		let tempErrorObj = {};

		// if (!firstName) {
		//     tempErrorObj.firstName = true
		// } else {
		//     tempErrorObj.firstName = false
		// }
		// if (!lastName) {
		//     tempErrorObj.lastName = true
		// } else {
		//     tempErrorObj.lastName = false
		// }
		if (!donorEmail) {
			tempErrorObj.donorEmail = true;
		} else {
			tempErrorObj.donorEmail = false;
		}
		// if (!postCode) {
		//     tempErrorObj.postCode = true
		// } else {
		//     tempErrorObj.postCode = false
		// }

		this.setState({ errorObj: tempErrorObj });
	};

	render() {
		const { classes } = this.props;
		const {
			isAnonymous,
			firstName,
			lastName,
			donorEmail,
			postCode,
			errorObj,
			disableInput,
		} = this.state;
		const { isGuestUser } = this.context;

		return (
			<Fragment>
				<div className={classes.formHeader}>
					<h4 className={classes.formHeading}>Personal Information:</h4>
					<h4 className={classes.formNo}>Step 1 - 4</h4>
				</div>
				<div>
					{isGuestUser ? (
						<InputLabel
							className={classes.inputLabel}
							style={{ display: "inline-block" }}
						>
							<Checkbox
								variant="outlined"
								name="isAnonymous"
								onChange={this.handleChange}
								inputProps={{ "aria-label": "isAnonymous" }}
							/>
							Donate Anonymously
						</InputLabel>
					) : (
						""
					)}
					{isGuestUser ? (
						<InputLabel className={classes.inputLabel}>First Name:</InputLabel>
					) : (
						<InputLabel className={classes.inputLabel}>
							First Name: *
						</InputLabel>
					)}
					<TextField
						variant="outlined"
						size="small"
						id="firstName"
						name="firstName"
						placeholder="First Name"
						value={firstName}
						disabled={disableInput}
						className={
							errorObj.firstName ? classes.textFieldError : classes.textField
						}
						InputProps={{
							classes: {
								notchedOutline: errorObj.firstName
									? classes.notchedOutlineError
									: classes.notchedOutline,
								focused: errorObj.firstName
									? classes.focusedError
									: classes.focused,
							},
						}}
						onChange={this.handleChange}
					/>
					{isGuestUser ? (
						<InputLabel className={classes.inputLabel}>Last Name: </InputLabel>
					) : (
						<InputLabel className={classes.inputLabel}>Last Name: *</InputLabel>
					)}
					<TextField
						variant="outlined"
						size="small"
						id="lastName"
						name="lastName"
						placeholder="Last Name"
						value={lastName}
						disabled={disableInput}
						className={
							errorObj.lastName ? classes.textFieldError : classes.textField
						}
						InputProps={{
							classes: {
								notchedOutline: errorObj.lastName
									? classes.notchedOutlineError
									: classes.notchedOutline,
								focused: errorObj.lastName
									? classes.focusedError
									: classes.focused,
							},
						}}
						onChange={this.handleChange}
					/>
					<InputLabel className={classes.inputLabel}>Donor Email: *</InputLabel>
					<TextField
						variant="outlined"
						size="small"
						name="donorEmail"
						placeholder="Donor Email"
						value={donorEmail}
						disabled={disableInput}
						className={
							errorObj.donorEmail ? classes.textFieldError : classes.textField
						}
						InputProps={{
							classes: {
								notchedOutline: errorObj.donorEmail
									? classes.notchedOutlineError
									: classes.notchedOutline,
								focused: errorObj.donorEmail
									? classes.focusedError
									: classes.focused,
							},
						}}
						onChange={this.handleChange}
					/>
					{isGuestUser ? (
						<InputLabel className={classes.inputLabel}>Post Code: </InputLabel>
					) : (
						<InputLabel className={classes.inputLabel}>Post Code: *</InputLabel>
					)}
					<TextField
						variant="outlined"
						size="small"
						name="postCode"
						placeholder="Post Code"
						value={postCode}
						disabled={disableInput}
						className={
							errorObj.postCode ? classes.textFieldError : classes.textField
						}
						InputProps={{
							classes: {
								notchedOutline: errorObj.postCode
									? classes.notchedOutlineError
									: classes.notchedOutline,
								focused: errorObj.postCode
									? classes.focusedError
									: classes.focused,
							},
						}}
						onChange={this.handleChange}
						inputProps={{ maxLength: "5" }}
					/>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button
							variant="contained"
							className={classes.btn}
							onClick={this.handleClickNext}
						>
							Next
						</Button>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default withStyles(styles)(Personal);
