import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
	makeStyles,
	Grid,
	Button,
	Dialog,
	IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import parse from "html-react-parser";
import moment from "moment";

import classes from "../Landing.module.css";

const useStyles = makeStyles((theme) => ({
	text: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 16,
		color: "#646464",
		whiteSpace: "pre-line",
		overflowWrap: "anywhere",
	},
	eventCard: {
		backgroundColor: "#f6f6f6",
		padding: "17px 15px",
		position: "relative",
		marginBottom: 20,
		height: "500px",
	},
	eventTime: {
		textTransform: "uppercase",
		fontSize: 14,
		fontWeight: "500",
		display: "block",
		marginBottom: 5,
	},
	calender: {
		backgroundColor: "#fd5a5a",
		color: "#fff",
		position: "absolute",
		top: 0,
		right: 0,
		width: 55,
		height: 60,
		borderRadius: 3,
		textAlign: "center",
		fontSize: 27,
		fontFamily: "'Vidaloka', serif",
		// lineHeight: 25,
		paddingTop: 7,
	},
	eventTitle: {
		fontSize: 27,
		// cursor: "pointer",
		// "&:hover": {
		// 	color: "#fd5a5a",
		// },
	},
	img: {
		height: "auto",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			height: "auto",
			width: 345,
		},
	},
	imgNewsContain: {
		height: "300px",
		width: "100%",
		margin: "auto",
	},
	imgNews: {
		height: "100%",
		width: "100%",
		borderRadius: "5px 5px 0 0",
	},
}));

export default function CustomCards(props) {
	const classes = useStyles();
	const { news } = props;
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	let date = moment(news.date, "YYYY-MM-DD");

	// if (data.title == "COVID-19 Appeal") {

	return (
		<Fragment>
			<div
				// key={index}
				className={classes.eventCard}
				style={{
					borderRadius: "5px",
					boxShadow: "rgba(0,0,0,0.2) 1px 5px 15px",
				}}
			>
				<div className={classes.imgNewsContain}>
					<img
						src={news.image ? `https://api.edhi.org.au/${news.image}` : ""}
						className={classes.imgNews}
						alt={news.title}
					/>
				</div>
				<span className={classes.eventTime}>
					&nbsp;
					{/* TIME: {moment(news.date).format("hh:mm:ss A")} */}
				</span>
				<a
					// style={{ backgroundColor: event2.color }}
					className={classes.calender}
				>
					{date.format("D")}
					<span
						style={{
							display: "block",
							fontSize: 14,
							textTransform: "uppercase",
						}}
					>
						{date.format("MMM")}
					</span>
				</a>
				<h3 className={classes.eventTitle}>{news.title}</h3>
				<hr />
				<p className={classes.text}>{news.excerpt}</p>

				<span
					onClick={handleClickOpen}
					style={{
						fontWeight: "bold",
						float: "right",
						marginTop: "-1em",
					}}
				>
					<a href="javascript:void" style={{ color: "rgb(0, 0, 0)" }}>
						Read More >
					</a>
				</span>
			</div>
			<Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
				{/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
															<IconButton className={classes.closeButton} onClick={handleClose}>
																<CloseIcon />
															</IconButton>
														</div> */}
				<div style={{ padding: 20 }}>
					<img
						style={{
							height: "auto",
							width: "100%",
							margin: "0px auto",
						}}
						className={classes.image}
						src={`https://api.edhi.org.au/${news.image}`}
						alt={news.title}
					/>
					<div style={{ padding: 5 }}>
						<h4 className={classes.eventTitle}>{news.title}</h4>
						<hr />
						<span className={classes.eventTime}>
							<b>DATED: {moment(news.date).format("DD MMMM, YYYY")}</b>
						</span>
						<p className={classes.text}>{parse(news.content)}</p>
					</div>
				</div>
			</Dialog>
		</Fragment>
	);
	// }
	// else {
	//     let path = "/";

	//     if (data.title == "Ambulance Fund") {
	//         path = "/ambulance-fund";
	//     }
	//     else if (data.title == "Fight against hunger") {
	//         path = "/fight-against-hunger";
	//     }
	//     else if (data.title == "Home and Orphanage") {
	//         path = "/home-and-orphanage";
	//     }
	//     else if (data.title == "Patient Care") {
	//         path = "/patient-care";
	//     }
	//     else if (data.title == "Rehabilitation Centers") {
	//         path = "/rehabilitation-center";
	//     }

	//     return (
	//         <Fragment>
	//             <div className={classes.card}>
	//                 <Link to={`${path}`} style={{ textDecoration: 'none', color: ' inherit' }}>
	//                     <div className='single-work-img'>
	//                         <img src={data.image} alt={data.image} />
	//                     </div>
	//                 </Link>
	//                 <div className={classes.cardContentBox}>
	//                     <h3 className={classes.cardTitle}>
	//                         <Link to='#' style={{ textDecoration: 'none', color: ' inherit' }}>{data.title}</Link>
	//                     </h3>
	//                     <div className={classes.cardContent}>
	//                         <p>{data.description}
	//                             <Link to={`${path}`} style={{ textDecoration: 'none', color: ' #000', fontWeight: 'bold' }}> read more</Link>
	//                         </p>
	//                     </div>
	//                     <Link to='/donation' style={{ textDecoration: 'none', color: ' inherit' }}>
	//                         <Button className={classes.cardButton}>
	//                             Donate Now
	//                         </Button>
	//                     </Link>
	//                 </div>
	//             </div>
	//         </Fragment>
	//     )
	// }
}
