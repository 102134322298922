import React, { Fragment, useEffect, useState } from "react";
import {
	Container,
	makeStyles,
	Button,
	CircularProgress,
} from "@material-ui/core";
import Axios from "axios";
import baseUrl from "../../Utils/baseUrl";
import Description from "../../Components/Markdown";

const useStles = makeStyles((theme) => ({
	imageBoxParent: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		marginBottom: 40,
	},
	imageBox: {
		textAlign: "center",
		color: "#444",
		width: "100%",
		[theme.breakpoints.up("md")]: {
			border: "1px solid rgba(167, 167, 167, 0.5)",
			padding: 10,
			width: "60%",
		},
	},
	image: {
		display: "block",
		width: "100%",
		height: "auto",
	},
	heading: {
		fontFamily: "'vidaloka', serif",
		textAlign: "center",
		[theme.breakpoints.up("md")]: {
			marginBottom: 50,
			fontSize: 40,
		},
		marginBottom: 10,
		fontSize: 30,
	},
	heading2: {
		fontFamily: "'vidaloka', serif",
		fontSize: 16,
		margin: "5px 0px 10px",
		fontWeight: "bold",
	},
	list: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 16,
		color: "#646464",
		listStyle: "none",
	},
	text: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 16,
		color: "#646464",
	},
	btn: {
		backgroundColor: "#09926e",
		color: "#fff",
		border: "2px solid transparent",
		boxShadow: "none",
		borderRadius: 0,
		padding: "14px 22px",
		marginTop: 10,
		"&:hover": {
			backgroundColor: "transparent",
			color: "#161616",
			border: "2px solid #161616",
			boxShadow: "none",
		},
	},
}));

export default function FloodRelief(props) {
	const classes = useStles();
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		Axios({
			url: `${baseUrl}/general-pages`,
			method: "POST",
			data: {
				type: "flood_relief",
			},
		})
			.then((res) => {
				setData(res.data.data[0]);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err.response.data);
				setLoading(false);
			});
	}, []);

	if (loading) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					height: 500,
				}}
			>
				<CircularProgress style={{ color: "#09926E", width: 80, height: 80 }} />
			</div>
		);
	} else {
		return (
			<Fragment>
				<h1 className={classes.heading}>{data.title}</h1>
				<div style={{ backgroundColor: "#f8f7f7" }}>
					<Container maxWidth="lg">
						<div className={classes.imageBoxParent}>
							<div className={classes.imageBox}>
								<img className={classes.image} src={data.image} alt="banner" />
								<Button
									variant="contained"
									className={classes.btn}
									onClick={() => {
										props.history.push("/donation");
									}}
								>
									Donate now
								</Button>
							</div>
						</div>
						<div>
							<Description description={data.description} />
						</div>
					</Container>
					<div style={{ height: 1 }} />
				</div>
			</Fragment>
		);
	}
}
