import { Container, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const useStles = makeStyles(theme => ({
    imageBox: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 70
        }
    },
    heading: {
        fontFamily: "'vidaloka', serif",
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            marginBottom: 50,
            fontSize: 40
        },
        marginBottom: 10,
        fontSize: 30
    },
    text: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 16,
        color: '#646464'
    }
}));
const TermsAndConditions = () => {
    const classes = useStles();
    return (
        <Container maxWidth="lg">
            <h1 className={classes.heading}>
                {'Privacy Policy'}
            </h1>
            <Grid container spacing={3}>
                <Grid item sm={12} md={12}>
        <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', lineHeight: '1.6' }}>
            <h2 style={{ textAlign: 'center' }}>Terms and Conditions for Abdul Sattar Edhi International Foundation Australia</h2>
            <div>
                <p><strong>1. Acceptance of Terms:</strong> By accessing or using the Platform, you affirm that you are of legal age to enter into these Terms and agree to comply with all applicable laws and regulations.</p>
                <p><strong>2. Donation Process:</strong> You may donate to the Foundation through the Platform using various payment methods provided. All donations made through the Platform are final and non-refundable. The Foundation reserves the right to refuse or return any donation at its discretion.</p>
                <p><strong>3. User Accounts:</strong> To access certain features of the Platform, such as viewing donation history, you may be required to create a user account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary to maintain its accuracy. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
                <p><strong>4. Donation History:</strong> Registered users may access their donation history through the Platform. The Foundation will make reasonable efforts to ensure that donation history is accurate, but does not guarantee the accuracy or completeness of such information.</p>
                <p><strong>5. Anonymous Donations:</strong> Users have the option to donate anonymously if they wish to do so. Anonymous donations will not be associated with any user account and will not appear in the donor's donation history.</p>
                <p><strong>6. Privacy Policy:</strong> Your use of the Platform is also governed by our Privacy Policy, which is incorporated herein by reference. By using the Platform, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.</p>
                <p><strong>7. Intellectual Property:</strong> All content and materials on the Platform, including but not limited to text, graphics, logos, and images, are the property of the Foundation or its licensors and are protected by copyright and other intellectual property laws.</p>
                <p><strong>8. Limitation of Liability:</strong> To the fullest extent permitted by law, the Foundation shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Platform.</p>
                <p><strong>9. Modifications to Terms:</strong> The Foundation reserves the right to modify these Terms at any time without prior notice. Any changes to these Terms will be effective immediately upon posting. Your continued use of the Platform after any such modifications constitutes your acceptance of the revised Terms.</p>
                <p><strong>10. Governing Law:</strong> These Terms shall be governed by and construed in accordance with the laws of Australia.</p>
                <p><strong>11. Contact Us:</strong> If you have any questions or concerns about these Terms or the Platform, please contact us at <a href='tel:+61(0)424296848' style={{color: "#007bff", textDecoration: "none"}}>+61(0)424296848</a>/<a href='mailTo:edhi@edhi.org.au' style={{color: "#007bff", textDecoration: "none"}}>edhi@edhi.org.au</a></p>
            </div>
        </div>
        </Grid>
                <Grid item md={1}></Grid>
            </Grid>
        </Container>
    );
};

export default TermsAndConditions;
