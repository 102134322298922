import React, { Component, Fragment } from "react";
import { Button, CircularProgress, withStyles } from "@material-ui/core";
import {
	Elements,
	ElementsConsumer,
	CardElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Axios from "axios";
import baseUrl from "../../../Utils/baseUrl";
import swal from "sweetalert2";

import ContextAPI from "../../../ContextAPI/contextAPI";

const CARD_ELEMENT_OPTIONS = {
	style: {
		input: {
			color: "#646464",
			fontSize: "16px",
			fontFamily: "sans-serif",
			fontSmoothing: "antialiased",
			"::placeholder": {
				color: "#CFD7DF",
			},
		},
		invalid: {
			color: "#e5424d",
			":focus": {
				color: "#303238",
			},
		},
	},
};

const styles = (theme) => ({
	btn: {
		backgroundColor: "#DC3545",
		color: "#fff",
		padding: "10px 35px",
		boxShadow: "none",
		marginTop: 15,
		"&:hover": {
			backgroundColor: "#DC3545",
			color: "#fff",
			boxShadow: "none",
		},
	},
	text: {
		fontFamily: "'Roboto', sans-serif",
		lineHeight: "2",
		fontSize: 16,
		color: "#888888",
		margin: "0 0 10px",
		textAlign: "left",
	},
});

class StripeCard extends Component {
	state = {
		stripeKey: null,
		loading: false,
	};

	static contextType = ContextAPI;

	componentDidMount() {
		this.getStripeCredential();
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.getStripeCredential();
		}
	}

	getStripeCredential = () => {
		const stripe_key_live =
			"pk_live_51I6miQK2g4L7p0Av2aHpZZFq62IdRTNMwGeSEycvLwTTZDdTYkEvkeU7ijolq3cqcVT7qLbuzSD6b9OBgZwSLGns00pMu2Qx56";
		const stripe_key_test =
			"pk_test_51I6miQK2g4L7p0AvlqRFJvL1p9m0vyEwnTgb1VdPdLqekpLw2eYOQ7k15ckwOdfxEC5RbWkk5viZGGlQfmC9qAcU00Ol0ACtqT";

		const stripePromise = loadStripe(stripe_key_live);
		this.setState({ stripeKey: stripePromise });
		// Axios({
		// 	url: `${baseUrl}/stripe-credentials`,
		// 	method: "GET",
		// })
		// 	.then((res) => {
		// 		// console.log(res.data);
		// 		if (res.data.stripe_key) {
		// 			const stripePromise = loadStripe(res.data.stripe_key);
		// 			this.setState({ stripeKey: stripePromise });
		// 		}
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 	});
	};

	handleSubmitCard = async (stripe, elements) => {
		this.setState({ loading: true });

		if (!stripe || !elements) {
			return;
		}

		const card = elements.getElement(CardElement);
		const result = await stripe.createToken(card);

		if (result.error) {
			console.log(result.error.message);
			this.setState({ loading: false });
		} else {
			console.log(result);
			this.handlePayment(result.token.id);
		}
	};

	handlePayment = (tokenId) => {
		const { donationData } = this.props;
		const { isGuestUser, user } = this.context;
		let tempTotal = 0;
		let tempAdminFee = 0;
		let tempMerchantFee = 0;

		if (donationData.adminFee) {
			tempAdminFee = parseFloat(donationData.total) * 0.05;
			tempAdminFee = Number(Math.round(tempAdminFee + "e" + 2) + "e-" + 2);
			tempTotal = parseFloat(donationData.total) + tempAdminFee;
			tempTotal = Number(Math.round(tempTotal + "e" + 2) + "e-" + 2);
			tempMerchantFee = parseFloat(tempTotal) * 0.015;
			tempMerchantFee = Number(
				Math.round(tempMerchantFee + "e" + 2) + "e-" + 2
			);
			tempTotal =
				parseFloat(donationData.total) + tempAdminFee + tempMerchantFee;
			tempTotal = Number(Math.round(tempTotal + "e" + 2) + "e-" + 2);
		} else {
			tempMerchantFee = parseFloat(donationData.total) * 0.015;
			tempMerchantFee = Number(
				Math.round(tempMerchantFee + "e" + 2) + "e-" + 2
			);
			tempTotal = parseFloat(donationData.total) + tempMerchantFee;
			tempTotal = Number(Math.round(tempTotal + "e" + 2) + "e-" + 2);
		}

		// const item_details = donationData.donationMethod.map((item, index) => {
		//     let donationAmount = (parseFloat(item.amount) * parseFloat(item.quantity));
		//     let merchantFee = 0;
		//     let adminFee = 0;
		//     let subTotal = 0;

		//     if (donationData.adminFee) {
		//         adminFee = parseFloat(donationAmount) * 0.05;
		//         adminFee = Number(Math.round(adminFee + "e" + 2) + "e-" + 2);
		//         subTotal = parseFloat(donationAmount) + adminFee;
		//         subTotal = Number(Math.round(subTotal + "e" + 2) + "e-" + 2);
		//         merchantFee = parseFloat(subTotal) * 0.015;
		//         merchantFee = Number(Math.round(merchantFee + "e" + 2) + "e-" + 2);
		//         subTotal = parseFloat(donationAmount) + adminFee + merchantFee;
		//         subTotal = Number(Math.round(subTotal + "e" + 2) + "e-" + 2);
		//     }
		//     else {
		//         merchantFee = parseFloat(donationAmount) * 0.015;
		//         merchantFee = Number(Math.round(merchantFee + "e" + 2) + "e-" + 2);
		//         subTotal = parseFloat(donationAmount) + merchantFee;
		//         subTotal = Number(Math.round(subTotal + "e" + 2) + "e-" + 2);
		//     }

		//     return ({
		//         donation_type: item.donationType,
		//         donation_amount: subTotal,
		//         donation_type_option: item.donationSubType || "",
		//         quantity: item.quantity,
		//         admin_fee_amount: tempAdminFee,
		//         merchant_fee: tempMerchantFee,
		//         net_donation_amount: parseFloat(item.amount)
		//     })
		// });

		const item_details = donationData.donationMethod.map((item, index) => {
			let donationAmount = parseFloat(item.amount) * parseFloat(item.quantity);

			return {
				donation_type: item.donationType,
				donation_amount: donationAmount,
				donation_type_option: item.donationSubType || "",
				quantity: item.quantity,
				admin_fee_amount: tempAdminFee,
				merchant_fee: tempMerchantFee,
				net_donation_amount: parseFloat(item.amount),
				donation_shared_name:
					item?.onBehalf && item?.onBehalf?.length > 0 ? item.onBehalf : "",
			};
		});

		const data = {
			amount: tempTotal,
			stripeToken: tokenId,
			user_id: user.user_id || "0",
			payment_method: "stripe",
			payment_type: donationData.paymentMethod,
			donor_first_name: donationData.isAnonymous
				? "Anonymous"
				: donationData.firstName,
			donor_last_name: donationData.isAnonymous
				? "User"
				: donationData.lastName
				? donationData.lastName
				: "",
			donor_email: donationData.donorEmail,
			item_details: JSON.stringify(item_details),
		};

		console.log(data);

		if (data.amount < 1) {
			alert("Minimum donation amount allowed is $1");
			this.setState({ loading: false });
		} else {
			Axios({
				url: `${baseUrl}/payment`,
				method: "POST",
				data: data,
			})
				.then((res) => {
					// console.log(res.data);
					this.setState({ loading: false });
					if (res && res.data.code === "400") {
						swal.fire({
							icon: "error",
							text: `${res.data.message}`,
						});
					} else {
						swal
							.fire({
								icon: "success",
								text: `${res.data.message}`,
							})
							.then(() => window.location.replace("/"));
					}
				})
				.catch((err) => {
					this.setState({ loading: false });
					if (err && err.response) {
						// console.log(err, err.response.data);
						swal.fire({
							icon: "error",
							text: `${err.response.data.message}`,
						});
					}
				});
		}
	};

	render() {
		const { stripeKey, loading } = this.state;
		const { classes } = this.props;

		return (
			<Fragment>
				{stripeKey ? (
					<Fragment>
						<p className={classes.text}>Enter Card Number: </p>
						<Elements stripe={stripeKey}>
							<ElementsConsumer>
								{({ stripe, elements }) => (
									<Fragment>
										<CardElement options={CARD_ELEMENT_OPTIONS} />
										{/* <CardNumberElement />
                                            <CardCvcElement className={classes.cvc} options={CARD_ELEMENT_OPTIONS} />
                                            <CardExpiryElement /> */}

										<div
											style={{ display: "flex", justifyContent: "flex-end" }}
										>
											{!loading ? (
												<Button
													variant="contained"
													className={classes.btn}
													onClick={() => {
														this.handleSubmitCard(stripe, elements);
													}}
												>
													Pay
												</Button>
											) : (
												<Button variant="contained" className={classes.btn}>
													<CircularProgress
														style={{ color: "#fff", width: 25, height: 25 }}
													/>
												</Button>
											)}
										</div>
									</Fragment>
								)}
							</ElementsConsumer>
						</Elements>
					</Fragment>
				) : (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							height: 200,
						}}
					>
						<CircularProgress
							style={{ color: "#09926E", width: 80, height: 80 }}
						/>
					</div>
				)}
			</Fragment>
		);
	}
}

export default withStyles(styles)(StripeCard);
