import React, { Component, Fragment, useEffect, useState } from "react";
//React Router
import { withRouter, Link } from "react-router-dom";
//Material UI
import {
	Container,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Button,
	Dialog,
	Divider,
	AppBar,
	IconButton,
	Slide,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
//Icons
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import { FaBell, FaRegBell, FaRegBellSlash } from "react-icons/fa";
import Moment from "react-moment";
import "moment-timezone";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import Axios from "axios";
import baseUrl from "../../Utils/baseUrl";
import ExpansionPanel from "../ExpansionPanel";
import logo from "../../Assets/Images/logo.png";
import ContextAPI from "../../ContextAPI/contextAPI";
import navbar from "./Navbar.module.css";
import "./Navbar.module.css";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
	appBar: {
		position: "relative",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	listItem: {
		backgroundColor: "#fff",
		color: "#646464",
		"&:hover": {
			backgroundColor: "#09926E",
			color: "#fff",
		},
	},
	listItemActive: {
		margin: 3,
		backgroundColor: "#09926E",
		color: "#fff",
	},
	textField: {
		backgroundColor: "white",
		color: "black",
		width: "60%",
		[theme.breakpoints.down("md")]: {
			width: "50%",
		},
		[theme.breakpoints.down("sm")]: {
			width: "40%",
		},
		border: "none",
		"&:hover": {
			"& $notchedOutline": {
				border: "none",
			},
		},
		marginBottom: "10px",
	},
	notchedOutline: {
		border: "none",
	},
	focused: {
		"& $notchedOutline": {
			borderColor: "none",
		},
	},
});

// Set the timezone for every instance.
Moment.globalTimezone = "Australia/Sydney";

// Set the output timezone for local for every instance.
Moment.globalLocal = true;

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

class NavMenu extends Component {
	state = {
		open: false,
		data: [],
	};
	static contextType = ContextAPI;

	getNotifications = async () => {
		Axios({
			url: `${baseUrl}/get-notifications`,
			method: "GET",
		})
			.then((res) => {
				if (this.state.data.length === 0) {
					console.log("Flag A:" + this.state.data.length);
					// console.log("Flag B:" + res.data.data);
					this.setState({ data: res.data.data });
					var target = document.querySelector(".customTrigger");
					var target2 = document.querySelector(".customTrigger2");
					var target3 = document.querySelector(".customTrigger3");

					target3.setAttribute("style", "opacity:0.9;");

					target.addEventListener("click", showHide, false);
					function showHide() {
						if (target2.style.display === "none") {
							target2.style.display = "block";
							target3.setAttribute("style", "opacity:0;");
						} else {
							target2.style.display = "none";
						}
					}
				}

				if (this.state.data[0].message === res.data.data) {
					console.log("Flag AA:" + this.state.data[0].message);
					console.log("Flag BB:" + res.data.data);
					this.setState({ data: res.data.data });
					var target = document.querySelector(".customTrigger");
					var target2 = document.querySelector(".customTrigger2");
					var target3 = document.querySelector(".customTrigger3");

					target3.setAttribute("style", "opacity:0.9;");

					target.addEventListener("click", showHide, false);
					function showHide() {
						if (target2.style.display === "none") {
							target2.style.display = "block";
							target3.setAttribute("style", "opacity:0;");
						} else {
							target2.style.display = "none";
						}
					}
				}
			})
			.catch((err) => {
				// if (err) {
				// 	console.log(err.response);
				// }
			});
	};

	componentDidMount() {
		if (window.innerWidth < 960) {
			this.getNotifications();

			const interval = setInterval(() => {
				this.getNotifications();
			}, 60000);

			return () => clearInterval(interval);
		}
	}

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleButtonPress = (path) => {
		this.props.history.push(path);
		this.handleClose();
	};

	handleLoginClick = () => {
		this.props.history.push("/login");
		this.setState({ open: false });
	};

	renderMenu() {
		const { classes } = this.props;
		const { open } = this.state;
		const { user, isFirebaseUser, logoutHandler, firebaseLogout, isGuestUser } =
			this.context;

		return (
			<React.Fragment>
				{console.log("DataLength: " + this.state.data.length)}
				<div className={navbar.dropdown2 + " customTrigger"}>
					<span>
						<FaBell
							title="Notifications"
							style={{ color: "#09926E" }}
							className={
								this.state.data.length > 0
									? navbar.notifications + " " + navbar.animation
									: navbar.notifications
							}
						/>
						<span
							className={
								this.state.data.length > 0
									? navbar.redDot2 + " " + navbar.show + " customTrigger3"
									: navbar.redDot2
							}
						></span>
					</span>
					<div className={navbar.dropdownContent2 + " customTrigger2"}>
						<h3
							style={{
								padding: "1em",
								fontFamily: "unset",
								fontWeight: "bold",
							}}
						>
							Notifications
						</h3>
						<SimpleBar
							forceVisible="y"
							autoHide={false}
							style={{ maxHeight: 400 }}
						>
							<ul>
								{this.state.data.length === 0 ? (
									<li>
										<FaRegBellSlash
											style={{
												marginRight: 5,
												marginLeft: -19,
												color: "#09926e",
											}}
										/>
										<span style={{ color: "#929494" }}>
											No New Notifications
										</span>
									</li>
								) : (
									this.state.data.length > 0 &&
									this.state.data.map((val, index) => (
										<li>
											<FaRegBell
												style={{
													marginRight: 5,
													marginLeft: -19,
													color: "#09926e",
												}}
											/>
											<span
												style={{
													color: "#666",
													fontWeight: 600,
													fontSize: "0.9rem",
												}}
											>
												{val.title}
											</span>
											<br />
											<span style={{ color: "#929494", fontSize: "0.9rem" }}>
												{val.message}
											</span>
											<br />
											<small style={{ color: "#b3c3c3", fontSize: "0.75rem" }}>
												<Moment fromNow ago tz="Australia/Sydney">
													{new Date(val.created_at).setHours(
														new Date(val.created_at).getHours() + 6
													)}
												</Moment>{" "}
												ago
											</small>
										</li>
									))
								)}
							</ul>
						</SimpleBar>
					</div>
				</div>
				<IconButton edge="end" color="white" onClick={this.handleClickOpen}>
					<MenuIcon style={{ color: "#09926E" }} />
				</IconButton>
				<Dialog
					fullScreen
					open={open}
					onClose={this.handleClose}
					TransitionComponent={Transition}
				>
					<AppBar
						className={classes.appBar}
						style={{
							backgroundColor: "#fff",
							fontFamily: '"Noto Sans KR", sans-serif',
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
							}}
						>
							<Link to="/">
								<img src={logo} alt="logo" />
							</Link>
							<IconButton
								edge="start"
								color="black"
								onClick={this.handleClose}
								aria-label="close"
							>
								<CloseIcon style={{ color: "#09926E" }} />
							</IconButton>
						</div>
					</AppBar>
					<React.Fragment>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								this.handleButtonPress("/");
							}}
						>
							<ListItemText primary="Home" />
						</ListItem>

						<ExpansionPanel {...this.props} handleClose={this.handleClose} />
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								this.handleButtonPress("/edhi-world");
							}}
						>
							<ListItemText primary="Edhi&nbsp;World" />
						</ListItem>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								this.handleButtonPress("/gallery");
							}}
						>
							<ListItemText primary="Gallery" />
						</ListItem>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								this.handleButtonPress("/events");
							}}
						>
							<ListItemText primary="Events" />
						</ListItem>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								this.handleButtonPress("/contact");
							}}
						>
							<ListItemText primary="Contact" />
						</ListItem>

						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								this.handleButtonPress("/donation");
							}}
						>
							<ListItemText
								primary={
									<Typography style={{ color: "#fd5a5a" }}>
										Donate Now
									</Typography>
								}
							/>
						</ListItem>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								this.handleButtonPress("/donation-history");
							}}
						>
							<ListItemText
								primary={
									<Typography style={{ color: "rgb(120, 150, 110)" }}>
										Donation History
									</Typography>
								}
							/>
						</ListItem>
						<Divider />
						{(user && user.first_name) || isGuestUser ? (
							<>
								<ListItem
									button
									className={classes.listItem}
									onClick={() => {
										this.handleButtonPress("/profile");
									}}
								>
									<ListItemText primary="My Profile" />
								</ListItem>
								<ListItem
									button
									className={classes.listItem}
									onClick={() => {
										this.handleButtonPress("/edit-profile");
									}}
								>
									<ListItemText primary="Edit Profile" />
								</ListItem>
								<ListItem
									button
									className={classes.listItem}
									onClick={() => {
										this.handleButtonPress("/change-password");
									}}
								>
									<ListItemText primary="Change Password" />
								</ListItem>
								<ListItem
									button
									className={classes.listItem}
									onClick={() => {
										this.handleButtonPress("/donation-history");
									}}
								>
									<ListItemText primary="Donation History" />
								</ListItem>
								<Divider />
								<ListItem
									button
									onClick={isFirebaseUser ? firebaseLogout : logoutHandler}
									className={classes.listItem}
								>
									<ListItemText primary="Logout" />
								</ListItem>
							</>
						) : (
							<div>
								<ListItem
									button
									className={classes.listItem}
									onClick={() => {
										this.handleButtonPress("/login");
									}}
								>
									<ListItemText primary="Login" />
								</ListItem>
								<ListItem
									button
									className={classes.listItem}
									onClick={() => {
										this.handleButtonPress("/register");
									}}
								>
									<ListItemText primary="Register" />
								</ListItem>
							</div>
						)}
					</React.Fragment>
				</Dialog>
			</React.Fragment>
		);
	}

	render() {
		const { classes } = this.props;
		const { keyword } = this.state;

		return (
			<Fragment>
				<AppBar
					className={classes.mobileAppBar}
					style={{ backgroundColor: "#fff" }}
					position="fixed"
				>
					<Container maxWidth="sm">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Link to="/">
								<img style={{ maxWidth: 180 }} src={logo} alt="logo" />
							</Link>
							{this.renderMenu()}
						</div>
					</Container>
				</AppBar>
				<div style={{ height: 100 }} />
			</Fragment>
		);
	}
}

export default withRouter(withStyles(styles)(NavMenu));
