import React, { Fragment } from "react";
import { makeStyles, Button, Dialog, IconButton } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

const useStles = makeStyles((theme) => ({
	card: {
		border: "1px solid rgba(167, 167, 167, 0.5)",
		color: "#444",
		textAlign: "center",
		padding: "10px 10px 0px 10px",
		minHeight: 400,
		// margin: 0.5
		"&:hover": {
			border: "1px solid #161616",
			cursor: "pointer",
		},
	},
	image: {
		display: "block",
		width: "100%",
		height: "auto",
	},
	title: {
		fontFamily: '"IBM Plex Sans", sans serif',
		fontSize: 22,
		color: "#646464",
		margin: 0,
		textAlign: "center",
	},
	closeButton: {
		float: "right",
	},
	text: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 16,
		color: "#646464",
	},
}));

export default function GalleryCard(props) {
	const classes = useStles();
	const { data } = props;
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Fragment>
			<div className={classes.card} onClick={handleClickOpen}>
				<div style={{ width: 280, height: 280, background: "lightgrey" }}>
					<img
						className={classes.image}
						src={`https://api.edhi.org.au/${data.image_link}`}
						alt="banner"
					/>
				</div>
				<div style={{ padding: 15 }}>
					<h4 className={classes.title}>{data.title}</h4>
				</div>
			</div>
			<Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
				{/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div> */}
				<div style={{ padding: 20 }}>
					<img
						style={{ height: "auto", width: "100%", margin: "0px auto" }}
						className={classes.image}
						src={`https://api.edhi.org.au/${data.image_link}`}
						alt="gallery-view"
					/>
					<div style={{ padding: 15 }}>
						<h4 className={classes.title}>{data.title}</h4>
					</div>
					<p className={classes.text}>{data.description}</p>
				</div>
			</Dialog>
		</Fragment>
	);
}
