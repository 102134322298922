import React from "react";
import { Button, CircularProgress, withStyles } from "@material-ui/core";
import DropIn from "braintree-web-drop-in-react";
import Axios from "axios";
import baseUrl from "../../../Utils/baseUrl";
import swal from "sweetalert2";

import ContextAPI from "../../../ContextAPI/contextAPI";

const styles = (theme) => ({
	btn: {
		backgroundColor: "#DC3545",
		color: "#fff",
		padding: "10px 35px",
		boxShadow: "none",
		marginTop: 15,
		"&:hover": {
			backgroundColor: "#DC3545",
			color: "#fff",
			boxShadow: "none",
		},
	},
	text: {
		fontFamily: "'Roboto', sans-serif",
		lineHeight: "2",
		fontSize: 16,
		color: "#888888",
		margin: "0 0 10px",
		textAlign: "left",
	},
});

class Store extends React.Component {
	instance;

	state = {
		clientToken: null,
		loading: false,
	};

	static contextType = ContextAPI;

	componentDidMount() {
		this.getBraintreeToken();
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.getBraintreeToken();
		}
	}

	getBraintreeToken = () => {
		Axios({
			url: `${baseUrl}/generateToken`,
			method: "GET",
		})
			.then((res) => {
				// console.log(res.data);
				this.setState({ clientToken: res.data.clientToken });
			})
			.catch((err) => {
				console.log(err);
			});
	};

	handlePayment = async () => {
		const { donationData } = this.props;
		const { isGuestUser, user } = this.context;
		const { nonce } = await this.instance.requestPaymentMethod();
		let tempTotal = 0;

		this.setState({ loading: true });

		if (donationData.adminFee) {
			tempTotal =
				parseFloat(donationData.total) * 0.03 +
				parseFloat(donationData.total) * 0.05 +
				parseFloat(donationData.total);
		} else {
			tempTotal =
				parseFloat(donationData.total) * 0.03 + parseFloat(donationData.total);
		}

		const item_details = donationData.donationMethod.map((item, index) => {
			let donationAmount = parseFloat(item.amount) * parseFloat(item.quantity);
			let merchantFee = donationAmount * 0.03;
			let adminFee = 0;
			let subTotal = 0;
			if (donationData.adminFee) {
				adminFee = donationAmount * 0.05;
				subTotal = donationAmount + merchantFee + adminFee;
			} else {
				adminFee = 0.0;
				subTotal = donationAmount + merchantFee;
			}

			return {
				donation_type: item.donationType,
				donation_amount: subTotal,
				donation_type_option: item.donationSubType || "",
				quantity: item.quantity,
				admin_fee_amount: adminFee,
				merchant_fee: merchantFee,
				net_donation_amount: parseFloat(item.amount),
				donation_shared_name:
					item?.onBehalf && item?.onBehalf?.length > 0 ? item.onBehalf : "",
			};
		});

		console.log("total is:", tempTotal);

		const data = {
			amount: tempTotal.toFixed(2),
			braintree_nonce: nonce,
			user_id: user.user_id || "0",
			payment_method: "braintree",
			payment_type: donationData.paymentMethod,
			donor_first_name: donationData.isAnonymous
				? "Anonymous"
				: donationData.firstName,
			donor_last_name: donationData.isAnonymous
				? "User"
				: donationData.lastName
				? donationData.lastName
				: "",
			donor_email: donationData.donorEmail,
			item_details: JSON.stringify(item_details),
		};
		// console.log(data);
		if (data.amount < 1) {
			alert("Minimum donation amount allowed is $1");
			this.setState({ loading: false });
		} else {
			Axios({
				url: `${baseUrl}/payment`,
				method: "POST",
				data: data,
			})
				.then((res) => {
					// console.log(res.data);
					this.setState({ loading: false });
					if (res && res.data.code === "400") {
						swal.fire({
							icon: "error",
							text: `${res.data.message}`,
						});
					} else {
						swal
							.fire({
								icon: "success",
								text: `${res.data.message}`,
							})
							.then(() => window.location.replace("/"));
					}
				})
				.catch((err) => {
					this.setState({ loading: false });
					if (err && err.response) {
						// console.log(err, err.response.data);
						swal.fire({
							icon: "error",
							text: `${err.response.data.message}`,
						});
					}
				});
		}
	};

	render() {
		const { clientToken, loading } = this.state;
		const { classes } = this.props;
		let paypalTotal = 0;

		if (this.props.donationData.adminFee) {
			paypalTotal =
				parseFloat(this.props.donationData.total) * 0.03 +
				parseFloat(this.props.donationData.total) * 0.05 +
				parseFloat(this.props.donationData.total);
		} else {
			paypalTotal =
				parseFloat(this.props.donationData.total) * 0.03 +
				parseFloat(this.props.donationData.total);
		}

		if (!clientToken) {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						height: 200,
					}}
				>
					<CircularProgress
						style={{ color: "#09926E", width: 80, height: 80 }}
					/>
				</div>
			);
		} else {
			return (
				// console.log(paypalTotal),
				<div>
					<p className={classes.text}>
						Enter Card Number or Pay with Paypal Account:{" "}
					</p>
					<DropIn
						options={{
							authorization: clientToken,
							card: false,
							paypal: {
								flow: "checkout",
								amount: paypalTotal,
								currency: "AUD",
							},
						}}
						onInstance={(instance) => (this.instance = instance)}
					/>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						{!loading ? (
							<Button
								id="payBtn"
								variant="contained"
								className={classes.btn}
								onClick={this.handlePayment}
							>
								Finish
							</Button>
						) : (
							<Button variant="contained" className={classes.btn}>
								<CircularProgress
									style={{ color: "#fff", width: 25, height: 25 }}
								/>
							</Button>
						)}
					</div>
				</div>
			);
		}
	}
}

export default withStyles(styles)(Store);
