import React, { Component, Fragment } from 'react';

import { Grid, withStyles, InputLabel, TextField, Button } from '@material-ui/core';

const styles = theme => ({
    formHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10
    },
    formHeading: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#09926E',
        margin: '0 0 10px',
        textAlign: 'left'
    },
    formNo: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#888888',
        margin: '0 0 10px',
    },
    inputLabel: {
        fontWeight: 'normal',
        marginBottom: 15,
        textAlign: 'left'
    },
    required: {
        color: 'red'
    },
    textField: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ECEFF1',
        color: 'black',
        // height: 30,
        width: '100%',
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutline": {
                borderColor: '#ddd',
            }
        }
    },
    notchedOutline: {
        borderColor: '#ddd',
        '&:hover': {
            borderColor: '#ddd',
        }
    },
    focused: {
        "& $notchedOutline": {
            borderColor: '#ddd !important',
        }
    },
    textFieldError: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ECEFF1',
        color: 'black',
        width: '100%',
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutlineError": {
                borderColor: 'red !important',
            }
        }
    },
    notchedOutlineError: {
        borderColor: 'red',
        '&:hover': {
            borderColor: 'red !important',
        }
    },
    focusedError: {
        "& $notchedOutlineError": {
            borderColor: '#ddd !important',
        }
    },
    btn: {
        backgroundColor: '#09926E',
        color: '#fff',
        padding: '10px 35px',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: '#F44336',
            color: '#fff',
            boxShadow: 'none',
        }
    },
    prevBtn: {
        backgroundColor: '#616161',
        color: '#fff',
        padding: '10px 35px',
        boxShadow: 'none',
        marginRight: 10,
        "&:hover": {
            backgroundColor: '#000',
            color: '#fff',
            boxShadow: 'none',
        }
    }
});

class Donation extends Component {

    state = {
        em_name: "",
        em_postal: "",
        em_city: "",
        em_state: "",
        em_country: "",
        em_mobile: "",
        em_email: "",
        em_preffered_contact: "email",
        errorObj: {},
    }

    componentDidMount() {
        this.getStoredData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps != this.props)
            this.getStoredData();
    }

    getStoredData = () => {
        if (this.props.data) {
            const {
                em_name,
                em_postal,
                em_city,
                em_state,
                em_mobile,
                em_email,
                em_preffered_contact,
                em_country,
                errorObj
            } = this.props.data;

            this.setState({
                em_name: em_name,
                em_postal: em_postal,
                em_city: em_city,
                em_state: em_state,
                em_country: em_country,
                em_mobile: em_mobile,
                em_email: em_email,
                em_preffered_contact: em_preffered_contact,
            })
        }
    }

    handleClickNext = () => {
        const {
            em_name,
            em_postal,
            em_city,
            em_state,
            em_country,
            em_mobile,
            em_email,
            em_preffered_contact
        } = this.state;

        if (em_name && em_postal && em_city && em_state && em_country && em_mobile && em_email && em_preffered_contact) {
            this.props.nextHandler('3', { em_name, em_postal, em_city, em_country, em_state, em_mobile, em_email, em_preffered_contact })
        } else {
            this.handleValidation();
        }
    }

    handleChange = e => {
        const { errorObj } = this.state;

        if (errorObj[e.target.name])
            errorObj[e.target.name] = false;

        this.setState({
            [e.target.name]: e.target.value,
            errorObj
        })
    }

    handleValidation = () => {
        const {
            em_name,
            em_postal,
            em_city,
            em_state,
            em_mobile,
            em_email,
            em_country,
            em_preffered_contact
        } = this.state;
        let tempErrorObj = {}

        if (!em_name) {
            tempErrorObj.em_name = true
        } else {
            tempErrorObj.em_name = false
        }
        if (!em_postal) {
            tempErrorObj.em_postal = true
        } else {
            tempErrorObj.em_postal = false
        }
        if (!em_city) {
            tempErrorObj.em_city = true
        } else {
            tempErrorObj.em_city = false
        }
        if (!em_state) {
            tempErrorObj.em_state = true
        } else {
            tempErrorObj.em_state = false
        }
        if (!em_country) {
            tempErrorObj.em_country = true
        } else {
            tempErrorObj.em_country = false
        }
        if (!em_mobile) {
            tempErrorObj.em_mobile = true
        } else {
            tempErrorObj.em_mobile = false
        }
        if (!em_email) {
            tempErrorObj.em_email = true
        } else {
            tempErrorObj.em_email = false
        }

        this.setState({ errorObj: tempErrorObj })

    }

    render() {
        const { classes, nextHandler, backHandler } = this.props;
        const {
            em_name,
            em_postal,
            em_city,
            em_state,
            em_country,
            em_mobile,
            em_email,
            em_preffered_contact,
            errorObj
        } = this.state;

        return (
            <Fragment>
                <div className={classes.formHeader}>
                    <h4 className={classes.formHeading}>Person To Contact In Emergency</h4>
                    <h4 className={classes.formNo}>Step 3 - 4</h4>
                </div>
                <div>
                    <InputLabel className={classes.inputLabel} >Name: <span className={classes.required}>*</span></InputLabel>
                    <TextField
                        variant='outlined'
                        size='small'
                        name="em_name"
                        placeholder="Name"
                        value={em_name}
                        className={errorObj.em_name ? classes.textFieldError : classes.textField}
                        InputProps={{
                            classes: {
                                notchedOutline: errorObj.em_name ? classes.notchedOutlineError : classes.notchedOutline,
                                focused: errorObj.em_name ? classes.focusedError : classes.focused,
                            }
                        }}
                        onChange={this.handleChange}
                    />
                    <InputLabel className={classes.inputLabel} >Email: <span className={classes.required}>*</span></InputLabel>
                    <TextField
                        variant='outlined'
                        size='small'
                        name="em_email"
                        placeholder="Email@exapmle.com"
                        value={em_email}
                        className={errorObj.em_email ? classes.textFieldError : classes.textField}
                        InputProps={{
                            classes: {
                                notchedOutline: errorObj.em_email ? classes.notchedOutlineError : classes.notchedOutline,
                                focused: errorObj.em_email ? classes.focusedError : classes.focused,
                            }
                        }}
                        onChange={this.handleChange}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={classes.inputLabel} >Contact Number: <span className={classes.required}>*</span></InputLabel>
                            <TextField
                                variant='outlined'
                                size='small'
                                name="em_mobile"
                                placeholder="Contact Number"
                                value={em_mobile}
                                className={errorObj.em_mobile ? classes.textFieldError : classes.textField}
                                InputProps={{
                                    classes: {
                                        notchedOutline: errorObj.em_mobile ? classes.notchedOutlineError : classes.notchedOutline,
                                        focused: errorObj.em_mobile ? classes.focusedError : classes.focused,
                                    }
                                }}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: "11" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={classes.inputLabel} >State: <span className={classes.required}>*</span></InputLabel>
                            <TextField
                                variant='outlined'
                                size='small'
                                name="em_state"
                                placeholder="State"
                                value={em_state}
                                className={errorObj.em_state ? classes.textFieldError : classes.textField}
                                InputProps={{
                                    classes: {
                                        notchedOutline: errorObj.em_state ? classes.notchedOutlineError : classes.notchedOutline,
                                        focused: errorObj.em_state ? classes.focusedError : classes.focused,
                                    }
                                }}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={classes.inputLabel} >City: <span className={classes.required}>*</span></InputLabel>
                            <TextField
                                variant='outlined'
                                size='small'
                                name="em_city"
                                placeholder="Country"
                                value={em_city}
                                className={errorObj.em_city ? classes.textFieldError : classes.textField}
                                InputProps={{
                                    classes: {
                                        notchedOutline: errorObj.em_city ? classes.notchedOutlineError : classes.notchedOutline,
                                        focused: errorObj.em_city ? classes.focusedError : classes.focused,
                                    }
                                }}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={classes.inputLabel} >Post Code: <span className={classes.required}>*</span></InputLabel>
                            <TextField
                                variant='outlined'
                                size='small'
                                name="em_postal"
                                placeholder="Post Code"
                                value={em_postal}
                                className={errorObj.em_postal ? classes.textFieldError : classes.textField}
                                InputProps={{
                                    classes: {
                                        notchedOutline: errorObj.em_postal ? classes.notchedOutlineError : classes.notchedOutline,
                                        focused: errorObj.em_postal ? classes.focusedError : classes.focused,
                                    }
                                }}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: "5" }}
                            />
                        </Grid>
                    </Grid>
                    <InputLabel className={classes.inputLabel} >Country: <span className={classes.required}>*</span></InputLabel>
                    <TextField
                        variant='outlined'
                        size='small'
                        name="em_country"
                        placeholder="Country"
                        value={em_country}
                        className={errorObj.em_country ? classes.textFieldError : classes.textField}
                        InputProps={{
                            classes: {
                                notchedOutline: errorObj.em_country ? classes.notchedOutlineError : classes.notchedOutline,
                                focused: errorObj.em_country ? classes.focusedError : classes.focused,
                            }
                        }}
                        onChange={this.handleChange}
                    />
                    <div>
                        <InputLabel className={classes.inputLabel} >Preferred Contact: <span className={classes.required}>*</span></InputLabel>
                        <label className="container fontsizee">
                            Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                 <input
                                style={{ display: 'none' }}
                                type='radio'
                                name='em_preffered_contact'
                                checked={em_preffered_contact === 'email'}
                                value="email"
                                onChange={(e) => { this.setState({ em_preffered_contact: e.target.value }) }}
                            />
                            <span className='radiomark'></span>
                        </label>
                        <label className="container fontsizee">
                            SMS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                 <input
                                style={{ display: 'none' }}
                                type='radio'
                                name='em_preffered_contact'
                                checked={em_preffered_contact === 'sms'}
                                value='sms'
                                onChange={(e) => { this.setState({ em_preffered_contact: e.target.value }) }}
                            />
                            <span className='radiomark'></span>
                        </label>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            className={classes.prevBtn}
                            onClick={backHandler}
                        >
                            previous
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.btn}
                            onClick={this.handleClickNext}
                        >
                            Next
                    </Button>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withStyles(styles)(Donation);