import React, { Component } from "react";
import {
	Container,
	Grid,
	withStyles,
	Divider,
	CircularProgress,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Personal from "./Components/Personal";
import Preview from "./Components/Preview";
import Payment from "./Components/Payment";
import Method from "./Components/Method";
import ContextAPI from "../../ContextAPI/contextAPI";

import logo from "../../Assets/Images/logo.png";

const styles = (theme) => ({
	stepperCard: {
		padding: 25,
		background: "#fff",
		borderRadius: 4,
		boxShadow: "0px 0px 15px 3px rgba(0,0,0,0.15)",
		fontFamily: "'Roboto', sans-serif",
		fontSize: 16,
		fontWeight: "300",
		color: "#888",
		textAlign: "center",
		[theme.breakpoints.down("sm")]: {
			padding: 5,
			boxShadow: "none",
		},
	},
	formHeading1: {
		fontSize: 25,
		color: "#09926e",
		margin: "0 0 5px",
		textAlign: "center",
	},
	text: {
		fontFamily: "'Roboto', sans-serif",
		lineHeight: "2",
		fontSize: 16,
		color: "#888888",
		margin: "0 0 10px",
	},
	list: {
		fontFamily: "'Roboto', sans-serif",
		lineHeight: "2",
		fontSize: 16,
		color: "#fff",
		listStyle: "none",
	},
	formWizard: {
		margin: "auto",
		overflow: "hidden",
		position: "relative",
		marginTop: 20,
	},
	stepBoxes: {
		width: "21%",
		display: "inline-block",
		listStyle: "none",
		paddingTop: 10,
		border: "2px solid #fff",
		background: "#ccc",
		msTransform: "skewX(-30deg)",
		webkitTransform: "skewX(-30deg)",
		transform: "skewX(-30deg)",
		[theme.breakpoints.down("sm")]: {
			width: "20%",
		},
	},
	activeStepBoxes: {
		width: "21%",
		display: "inline-block",
		listStyle: "none",
		paddingTop: 10,
		border: "2px solid #fff",
		background: "#09926E",
		msTransform: "skewX(-30deg)",
		webkitTransform: "skewX(-30deg)",
		transform: "skewX(-30deg)",
		[theme.breakpoints.down("sm")]: {
			width: "20%",
		},
	},
	iconContainer: {
		backgroundColor: "#DDDDDD",
		borderRadius: "50%",
		height: 40,
		width: 40,
		marginTop: 4,
		transform: "skewX(30deg)",
		display: "inline-block",
	},
	activeIconContainer: {
		backgroundColor: "#fff",
		borderRadius: "50%",
		height: 40,
		width: 40,
		marginTop: 4,
		transform: "skewX(30deg)",
		display: "inline-block",
	},
	stepTitle: {
		color: "#fff",
		transform: "skewX(30deg)",
	},
	formHeader: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		marginTop: 10,
	},
	formHeading: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#09926E",
		margin: "0 0 10px",
		textAlign: "left",
	},
	formNo: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#888888",
		margin: "0 0 10px",
	},
	inputLabel: {
		fontWeight: "normal",
		marginBottom: 15,
		textAlign: "left",
	},
	textField: {
		fontFamily: "'Roboto', sans-serif",
		backgroundColor: "#ECEFF1",
		color: "black",
		// height: 30,
		width: "100%",
		marginBottom: 20,
		"&:hover": {
			"& $notchedOutline": {
				borderColor: "#ddd",
			},
		},
	},
	notchedOutline: {
		borderColor: "#ddd",
		"&:hover": {
			borderColor: "#ddd",
		},
	},
	focused: {
		"& $notchedOutline": {
			borderColor: "#ddd",
		},
	},
	btn: {
		backgroundColor: "#DC3545",
		color: "#fff",
		padding: "10px 35px",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#DC3545",
			color: "#fff",
			boxShadow: "none",
		},
	},
	infoCard: {
		backgroundColor: "rgba(36, 35, 35, 0.33)",
		boxShadow: "0px 0px 15px 3px rgba(0,0,0,0.15)",
		borderRadius: 4,
		textAlign: "center",
		padding: "20px 30px",
		[theme.breakpoints.up("md")]: {
			height: 700,
		},
	},
});

class Donation extends Component {
	state = {
		activeStep: 0,
		step1Status: false,
		step2Status: false,
		step3Status: false,
		step4Status: false,
		donationData: {},

		showModal: false,
		loader: true,
	};

	static contextType = ContextAPI;

	componentDidMount() {
		if (localStorage.getItem("isProfile") === "no") {
			this.props.history.push("/edit-profile");
		}
		setTimeout(() => {
			const { user, isGuestUser } = this.context;
			if (isGuestUser) {
				this.setState({ loader: false });
			} else {
				this.setState({ loader: false });
				if (!user.email) {
					this.props.history.push("/login");
				}
			}
		}, 3000);
	}

	handleNext = (step, data) => {
		const { activeStep, donationData } = this.state;
		// console.log(step, data)
		this.setState({
			activeStep: activeStep + 1,
			[`step${step}Status`]: true,
			donationData: { ...donationData, ...data },
		});
	};

	handleBack = () => {
		const { activeStep } = this.state;

		this.setState({
			activeStep: activeStep - 1,
		});
	};

	renderComponents = () => {
		const { activeStep, donationData } = this.state;

		switch (activeStep) {
			case 0:
				return (
					<Personal
						nextHandler={this.handleNext}
						backHandler={this.handleBack}
						stepNumber={activeStep}
						data={donationData && donationData.firstName && donationData}
					/>
				);
			case 1:
				return (
					<Method
						nextHandler={this.handleNext}
						backHandler={this.handleBack}
						stepNumber={activeStep}
						data={donationData && donationData.total && donationData}
						option={this.props.match.params.option}
					/>
				);
			case 2:
				return (
					<Payment
						nextHandler={this.handleNext}
						backHandler={this.handleBack}
						stepNumber={activeStep}
						total={donationData && donationData.total && donationData.total}
					/>
				);
			case 3:
				return (
					<Preview
						nextHandler={this.handleNext}
						backHandler={this.handleBack}
						stepNumber={activeStep}
						data={donationData && donationData.total && donationData}
					/>
				);
			default:
				return "Uknown Stpe";
		}
	};

	render() {
		const { classes } = this.props;
		const {
			activeStep,
			step1Status,
			step2Status,
			step3Status,
			step4Status,
			loader,
		} = this.state;

		// console.log("this.state.donationData", this.state.donationData);

		if (loader) {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						height: 500,
					}}
				>
					<CircularProgress
						style={{ color: "#09926E", width: 80, height: 80 }}
					/>
				</div>
			);
		} else {
			return (
				<Container maxWidth="lg">
					<div style={{ height: 50 }} />
					<Grid container spacing={1}>
						<Grid item sm={12} md={1}></Grid>
						<Grid item xs={12} sm={12} md={6}>
							<div className={classes.stepperCard}>
								<h3 className={classes.formHeading1}>DONATION FORM</h3>
								<p className={classes.text}>
									Fill all form field to go next step
								</p>
								<div className={classes.formWizard}>
									<ul style={{ padding: 0 }}>
										<li
											className={
												activeStep >= 0
													? classes.activeStepBoxes
													: classes.stepBoxes
											}
										>
											<div
												className={
													activeStep >= 0
														? classes.activeIconContainer
														: classes.iconContainer
												}
											>
												<PersonIcon
													style={
														activeStep >= 0
															? { color: "#09926E", marginTop: 6 }
															: { marginTop: 6 }
													}
												/>
											</div>
											<p
												style={{ color: "#fff" }}
												className={classes.stepTitle}
											>
												Personal
											</p>
										</li>
										<li
											className={
												activeStep >= 1
													? classes.activeStepBoxes
													: classes.stepBoxes
											}
										>
											<div
												className={
													activeStep >= 1
														? classes.activeIconContainer
														: classes.iconContainer
												}
											>
												<LocalMallIcon
													style={
														activeStep >= 1
															? { color: "#09926E", marginTop: 6 }
															: { marginTop: 6 }
													}
												/>
											</div>
											<p
												style={{ color: "#fff" }}
												className={classes.stepTitle}
											>
												Method
											</p>
										</li>
										<li
											className={
												activeStep >= 2
													? classes.activeStepBoxes
													: classes.stepBoxes
											}
										>
											<div
												className={
													activeStep >= 2
														? classes.activeIconContainer
														: classes.iconContainer
												}
											>
												<LocalAtmIcon
													style={
														activeStep >= 2
															? { color: "#09926E", marginTop: 6 }
															: { marginTop: 6 }
													}
												/>
											</div>
											<p
												style={{ color: "#fff" }}
												className={classes.stepTitle}
											>
												Payment
											</p>
										</li>
										<li
											className={
												activeStep >= 3
													? classes.activeStepBoxes
													: classes.stepBoxes
											}
										>
											<div
												className={
													activeStep >= 3
														? classes.activeIconContainer
														: classes.iconContainer
												}
											>
												<VisibilityIcon
													style={
														activeStep >= 3
															? { color: "#09926E", marginTop: 6 }
															: { marginTop: 6 }
													}
												/>
											</div>
											<p
												style={{ color: "#fff" }}
												className={classes.stepTitle}
											>
												Preview
											</p>
										</li>
									</ul>
								</div>
								{this.renderComponents()}
							</div>
						</Grid>
						<Grid item sm={12} md={1}></Grid>
						<Grid item sm={12} md={4}>
							<div className={classes.infoCard}>
								<div>
									<img src={logo} alt="logo" />
								</div>
								<div>
									<p className={classes.text} style={{ color: "white" }}>
										Payments can be made easily & securely through bank
										deposits.
									</p>
									<br />
									<ul style={{ margin: 0, padding: 0 }}>
										<li className={classes.list}>Bank: WESTPAC</li>
										<li className={classes.list}>
											ACCOUNT NAME : ABDUL SATTAR EDHI
										</li>
										<li className={classes.list}>INTERNATIONAL FOUNDATION</li>
										<li className={classes.list}>BSB : 032-173</li>
										<li className={classes.list}>ACCOUNT NO : 526867</li>
									</ul>
									<Divider style={{ margin: "5px 0px" }} />
									<ul style={{ margin: 0, padding: 0 }}>
										<li className={classes.list}>BANK : ANZ</li>
										<li className={classes.list}>
											ACCOUNT NAME : ABDUL SATTAR EDHI
										</li>
										<li className={classes.list}>INTERNATIONAL FOUNDATION</li>
										<li className={classes.list}>BSB : 012-003</li>
										<li className={classes.list}>ACCOUNT NO : 2555-31858</li>
									</ul>
									<p className={classes.text} style={{ color: "white" }}>
										For tax invoice, please send bank deposit details to email
										address{" "}
										<a
											href="mailto:edhi@edhi.org.au"
											style={{ color: "#0275D8" }}
										>
											edhi@edhi.org.au
										</a>
									</p>
								</div>
							</div>
						</Grid>
					</Grid>
					<div style={{ height: 50 }} />
				</Container>
			);
		}
	}
}

export default withStyles(styles)(Donation);
