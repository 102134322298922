import React from 'react';

const contextAPI = React.createContext(
    {
        siteInfo: {},
        user: {},
        token: null,
        isFirebaseUser: false,
        isGuestUser: false,
        authStatChange: () => { },
        firebaseUserHandler: () => { },
        guestUserHandler: () => { },
        logoutHandler: () => { },
        firebaseLogout: () => { },
    }
);

export default contextAPI;