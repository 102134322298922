import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, Avatar, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    card: {
        padding: ' 80px 20px 30px',
        backgroundColor: 'rgb(255, 255, 255)',
        color: '#646464',
        boxShadow: 'rgba(0, 0, 0, 0.11) 0px 4px 9px',
        borderRadius: 9,
        height: 150,
        position: 'relative',
        cursor: 'pointer',
        transition: 'all 0.4s ease 0s',
        margin: '55px 20px 20px 0px',
        textAlign: 'center',
        "&:hover": {
            backgroundColor: 'red',
            color: '#fff'
        },
        "&:hover .hoverCard": {
            color: '#fff'
        },
    },
    avatar: {
        display: 'flex',
        webkitBoxAlign: 'center',
        alignItems: 'center',
        webkitBoxPack: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        width: 87,
        height: 87,
        position: 'absolute',
        top: 'calc(-43.5px)',
        left: 'calc(50% - 43.5px)',
        backgroundColor: 'rgb(242, 18, 34)',
        transition: 'all 0.3s ease 0s'
    },
    heading2: {
        fontFamily: "'Vidaloka', serif",
        fontSize: 20,
        margin: "5px 0px 10px",
        fontWeight: 'bold'
    },
    text: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 16,
        color: 'inherit'
    },
}));

export default function TestimonialCard(props) {
    const classes = useStyles();
    const { data } = props;

    return (
        <Fragment>
            <div className={classes.card}>
                <div className={classes.avatar}>
                    <img src={data.image} alt='two' style={{ display: "block", width: "100%", height: "100%", objectFit: "fill", borderRadius: "50%" }} />
                </div>
                <div className={classes.cardContentBox}>
                    <h3 className={`${classes.heading2} hoverCard`}>
                        {data.title}
                    </h3>
                    <p className={classes.text}>{data.description}</p>
                </div>
            </div>
        </Fragment>
    )
}