import React, { useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Description from '../../Components/Markdown';

const useStles = makeStyles(theme => ({
    imageBox: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 70
        }
    },
    heading: {
        fontFamily: "'vidaloka', serif",
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            marginBottom: 50,
            fontSize: 40
        },
        marginBottom: 10,
        fontSize: 30
    },
    text: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 16,
        color: '#646464'
    }
}));

var description = '<p><strong>Edhi</strong><br> <strong>Account Deletion Instructions</strong></p> <p>We understand that you may want to delete your account on our app. Below are the step-by-step instructions to guide you through the process:</p> <p>1. Open the App:<br> – Launch the Edhi App on your mobile device.</p> <p>2. Access Account Settings:<br> – Navigate to the “Settings” or “Account Settings” section. This is usually represented by a gear icon.</p> <p>3. Locate Account Deletion Option:<br> – Look for an option such as “Account,” “Privacy,” or “Security.”<br> – Within this section, you should find an option related to account deletion.</p> <p>4. Initiate Deletion Process:<br> – Select the “Delete Account” or similar option.<br> – You may be prompted to enter your password for verification.</p> <p>5. Provide Feedback (Optional):<br> – Some apps may ask for feedback. If you’re willing, share your reasons for deleting the account. This helps us improve our services.</p> <p>6. Confirm Deletion:<br> – A confirmation message will appear, informing you about the consequences of account deletion.<br> – Confirm that you want to proceed with the deletion process.</p> <p>7. Additional Verification (if applicable):<br> – In some cases, you might receive an email or SMS with a verification code. Enter this code to confirm your identity.</p> <p>8. Final Confirmation:<br> – Once all steps are completed, you will receive a final confirmation that your account has been deleted.</p> <p>Note:<br> – Deleting your account will permanently erase your data and cannot be undone.</p> <p>Thank you for using Edhi App. We appreciate your feedback and hope to serve you better in the future.</p>';

export default function AccountDeletionInstruction() {
    const classes = useStles();

        return (
            <Container maxWidth="lg">
                <h1 className={classes.heading}>
                    {'Account Deletion Instructions'}
                </h1>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={8}>
                        <div>
                            <Description description={description} />
                        </div>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </Container>
        )
}
