import React, { Fragment } from 'react';
import { makeStyles, Button, Dialog, IconButton, } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const useStles = makeStyles(theme => ({
    card: {
        border: '1px solid rgba(167, 167, 167, 0.5)',
        color: '#444',
        textAlign: 'center',
        padding: '10px 10px 0px 10px',
        minHeight: 350,
        // margin: 0.5
        "&:hover": {
            border: '1px solid #161616',
            cursor: 'pointer'
        }
    },
    image: {
        display: 'block',
        width: '100%',
        height: 'auto'
    },
    title: {
        fontFamily: '"IBM Plex Sans", sans serif',
        fontSize: 22,
        color: '#646464',
        margin: 0,
        textAlign: 'center'
    },
    closeButton: {
        float: 'right'
    },
    text: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 16,
        color: '#646464',
        whiteSpace: 'pre-line',
        overflowWrap: 'anywhere'
    },
    heading2: {
        fontFamily: '"IBM Plex Sans", sans serif',
        fontSize: 20,
        margin: "5px 0px 10px",
        fontWeight: 'bold'
    },
}));

export default function GalleryCard(props) {
    const classes = useStles();
    const { data, children } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <div onClick={handleClickOpen}>
                {children}
            </div>
            <Dialog
                fullWidth
                maxWidth="sm"
                onClose={handleClose}
                open={open}
            >
                <div style={{ padding: "20px 20px 50px 20px" }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div style={{ padding: '30px 30px 0 34px', backgroundColor: '#f0f8ff' }}>
                        <div style={{ padding: '30px 30px 0 34px', backgroundColor: '#faebd7' }}>
                            <div className="section-title">
                                <span className="span-border">ABOUT EDHI FOUNDATION</span>
                                <h2>HOW CAN YOU HELP?</h2>
                            </div>
                            <div>
                                <h5 className={classes.heading2}>Reasons have day were meat dominion they're moving setting great very us hath the multiply evening towse</h5>
                                <br />
                                <p className={classes.text}>Edhi Foundation Pakistan is the largest and most organized social welfare organization in Pakistan. Foundation works round the clock, without any discrimination on the basis of colour, race, language, religion or politics. .</p>
                            </div>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
}