import React, { Component, Fragment } from 'react';
import { Grid, withStyles, InputLabel, TextField, Button, Container, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import baseUrl from '../../Utils/baseUrl';
import swal from 'sweetalert2';

const styles = theme => ({
    container: {
        // maxWidth: "838px !important",
        // "& .MuiContainer-maxWidthMd": {
        //     maxWidth: "838px !important"
        // }
    },
    card: {
        borderRadius: 10,
        boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.15)',
        boxSizing: 'border-box',
    },
    formHeader: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#09926E',
        color: '#fff',
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        padding: '20px 0'
    },
    formHeading: {
        fontFamily: '"IBM Plex Sans", sans serif',
        fontSize: 24,
        fontWeight: '700',
        color: '#fff',
        margin: 0,
        textTransform: 'uppercase'
    },
    cardBody: {
        padding: 10,
        [theme.breakpoints.up("sm")]: {
            padding: '50px 80px 35px',
            paddingBottom: 73
        }
    },
    formNo: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#888888',
        margin: '0 0 10px',
    },
    inputLabel: {
        fontWeight: 'bold',
        marginTop: 18,
        textAlign: 'left',
        width: 100
    },
    required: {
        color: 'red'
    },
    textField: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#f9f9f9',
        color: 'black',
        // height: 50,
        padding: "7px 0px 7px 0px",
        width: '100%',
        borderRadius: 5,
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutline": {
                borderColor: 'transparent',
            }
        }
    },
    notchedOutline: {
        borderColor: 'transparent',
        '&:hover': {
            borderColor: 'transparent',
        }
    },
    focused: {
        "& $notchedOutline": {
            borderColor: 'transparent !important',
        }
    },
    btn: {
        backgroundColor: '#09926E',
        color: '#fff',
        padding: '14px 50px',
        fontWeight: 'bold',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: '#F44336',
            color: '#fff',
            boxShadow: 'none',
        }
    }
});

class ResetPassword extends Component {

    state = {
        email: "",
        password: "",
        confirmPassword: "",
        token: "",
        loading: false,
    }

    handleResetPassword = () => {
        const {
            email,
            password,
            confirmPassword,
            token
        } = this.state;

        this.setState({ loading: true });

        if (password === confirmPassword) {

            Axios({
                url: `${baseUrl}/password/reset`,
                method: "POST",
                data: {
                    email,
                    password,
                    password_confirmation: confirmPassword,
                    code: token
                }
            }).then(res => {
                console.log(res.data);
                if (res.data.code === "200") {
                    this.setState({ loading: false }, () => {
                        this.props.history.push('/login');
                    })
                } else {
                    swal.fire({
                        icon: 'error',
                        text: `${res.data.message}`,
                    })
                }

            }).catch(err => {
                console.log(err)
                this.setState({ loading: false })
                if (err && err.response) {
                    console.log(err.response.data)
                    swal.fire({
                        icon: 'error',
                        text: `${err.response.data.message}`,
                    })
                }
            })
        } else {
            alert("Password does not match")
        }
    }

    render() {
        const { classes } = this.props;
        const { contactMode, loading } = this.state;

        return (
            <Container maxWidth="sm" className={`${classes.container} register`}>

                <h1 className={classes.formHeading}>REGISTRATION FORM</h1>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                        <InputLabel className={classes.inputLabel} >Code: <span className={classes.required}>*</span></InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <TextField
                            variant='outlined'
                            size='small'
                            type="number"
                            placeholder="copy & paste the code which is sent to your email"
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                    focused: classes.focused,
                                }
                            }}
                            onChange={(e) => { this.setState({ token: e.target.value }) }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                        <InputLabel className={classes.inputLabel} >Email: <span className={classes.required}>*</span></InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <TextField
                            variant='outlined'
                            size='small'
                            placeholder="Eamil@example.com"
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                    focused: classes.focused,
                                }
                            }}
                            onChange={(e) => { this.setState({ email: e.target.value }) }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                        <InputLabel className={classes.inputLabel} >Password : <span className={classes.required}>*</span></InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <TextField
                            variant='outlined'
                            size='small'
                            type="password"
                            placeholder="Password"
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                    focused: classes.focused,
                                }
                            }}
                            onChange={(e) => { this.setState({ password: e.target.value }) }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                        <InputLabel className={classes.inputLabel} >Confirm: <span className={classes.required}>*</span></InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <TextField
                            variant='outlined'
                            size='small'
                            type="password"
                            placeholder="Confirm Password"
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                    focused: classes.focused,
                                }
                            }}
                            onChange={(e) => { this.setState({ confirmPassword: e.target.value }) }}
                        />
                    </Grid>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 40 }}>
                    <Button
                        variant="contained"
                        className={classes.btn}
                        style={loading ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                        onClick={!loading && this.handleResetPassword}
                    >
                        {
                            !loading ?
                                "Reset Password"
                                :
                                <CircularProgress style={{ color: '#fff', width: 25, height: 25 }} />
                        }
                    </Button>
                </div>
            </Container>
        )
    }
}

export default withStyles(styles)(ResetPassword);