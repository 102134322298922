import React, { useState, useEffect, Fragment } from "react";
import { InputLabel, TextField, Button, makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
	formHeader: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		marginTop: 10,
	},
	formHeading: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#09926E",
		margin: "0 0 10px",
		textAlign: "left",
	},
	formNo: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 20,
		color: "#888888",
		margin: "0 0 10px",
	},
	inputLabel: {
		fontWeight: "normal",
		marginBottom: 15,
		textAlign: "left",
	},
	textField: {
		fontFamily: "'Roboto', sans-serif",
		backgroundColor: "#ECEFF1",
		color: "black",
		// height: 30,
		width: "100%",
		marginBottom: 20,
		"&:hover": {
			"& $notchedOutline": {
				borderColor: "#ddd",
			},
		},
	},
	notchedOutline: {
		borderColor: "#ddd",
		"&:hover": {
			borderColor: "#ddd",
		},
	},
	focused: {
		"& $notchedOutline": {
			borderColor: "#ddd !important",
		},
	},
	btn: {
		backgroundColor: "#DC3545",
		color: "#fff",
		padding: "10px 35px",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#DC3545",
			color: "#fff",
			boxShadow: "none",
		},
	},
	prevBtn: {
		backgroundColor: "#BBBBBB",
		color: "#fff",
		padding: "10px 35px",
		boxShadow: "none",
		marginRight: 10,
		"&:hover": {
			backgroundColor: "#BBBBBB",
			color: "#fff",
			boxShadow: "none",
		},
	},
}));

export default function Payment(props) {
	const classes = styles();
	const [adminFee, setAdminFee] = useState(true);
	const [subTotal, setSubTotal] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [paymentMethod, setPaymentMethod] = useState("Credit Card");
	const { nextHandler, backHandler, total } = props;
	const [merchantFee, setMerchantFee] = useState(0);

	useEffect(() => {
		if (adminFee) {
			let tempTotal = 0;
			let tempAdminFee = 0;
			let tempMerchantFee = 0;
			let merchantFeePercent = 0.015;
			if (paymentMethod === "Pay with Paypal") {
				merchantFeePercent = 0.03;
			}

			tempAdminFee = parseFloat(total) * 0.05;
			tempAdminFee = Number(Math.round(tempAdminFee + "e" + 2) + "e-" + 2);

			tempTotal = parseFloat(total) + tempAdminFee;
			tempTotal = Number(Math.round(tempTotal + "e" + 2) + "e-" + 2);

			tempMerchantFee = parseFloat(tempTotal) * merchantFeePercent;
			tempMerchantFee = Number(
				Math.round(tempMerchantFee + "e" + 2) + "e-" + 2
			);

			tempTotal = parseFloat(total) + tempAdminFee + tempMerchantFee;
			tempTotal = Number(Math.round(tempTotal + "e" + 2) + "e-" + 2);

			setSubTotal(total);
			setMerchantFee(tempMerchantFee);
			setTotalAmount(tempTotal);
		} else {
			let tempTotal = 0;
			let tempMerchantFee = 0;
			let merchantFeePercent = 0.015;
			if (paymentMethod === "Pay with Paypal") {
				merchantFeePercent = 0.03;
			}

			tempMerchantFee = parseFloat(total) * merchantFeePercent;
			tempMerchantFee = Number(
				Math.round(tempMerchantFee + "e" + 2) + "e-" + 2
			);

			tempTotal = parseFloat(total) + tempMerchantFee;
			tempTotal = Number(Math.round(tempTotal + "e" + 2) + "e-" + 2);

			setSubTotal(total);
			setMerchantFee(tempMerchantFee);
			setTotalAmount(tempTotal);
		}

		// console.log(total)
	}, [adminFee, paymentMethod]);

	const handleClickNext = () => {
		nextHandler("3", { subTotal, adminFee, paymentMethod });
	};

	return (
		<Fragment>
			<div className={classes.formHeader}>
				<h4 className={classes.formHeading}>Payment:</h4>
				<h4 className={classes.formNo}>Step 3 - 4</h4>
			</div>
			<div>
				<InputLabel className={classes.inputLabel}>
					Payment Method: *
				</InputLabel>
				<div style={{ textAlign: "left" }}>
					<lable>
						<input
							type="radio"
							name="method"
							value="Credit Card"
							checked={paymentMethod === "Credit Card" && true}
							onChange={(e) => {
								setPaymentMethod("Credit Card");
							}}
						/>{" "}
						Credit Card
					</lable>
					{/* <div style={{ height: 10 }} />
					<lable>
						<input
							type="radio"
							name="method"
							value="Credit Card (Recurring)"
							checked={paymentMethod === "Credit Card (Recurring)" && true}
							onChange={(e) => {
								setPaymentMethod("Credit Card (Recurring)");
							}}
						/>{" "}
						Credit Card (Recurring)
					</lable> */}
					<div style={{ height: 10 }} />
					<label>
						<input
							type="radio"
							value="Pay with Paypal"
							name="method"
							checked={paymentMethod === "Pay with Paypal" && true}
							onChange={(e) => {
								setPaymentMethod("Pay with Paypal");
							}}
						/>
						Pay with Paypal
					</label>
				</div>
				<div style={{ textAlign: "left", marginTop: 20 }}>
					<input
						name="input_16.1"
						type="checkbox"
						value="Include Admin Fee (5.0%)"
						checked={adminFee}
						id="choice_1_16_1"
						onChange={(e) => {
							setAdminFee(!adminFee);
						}}
					/>
					<label for="choice_1_16_1" id="label_1_16_1">
						Include Admin Fee (5.0%)
					</label>
				</div>
				<p
					style={{
						textAlign: "left",
						lineHeight: "1.8",
						fontFamily: "'Roboto', sans-serif",
					}}
				>
					Please note admin fee is optional. It is donors discretion to pay
					admin fee. By paying admin fee you are helping in managing
					administration cost. (untick excluding admin fee)
				</p>
				<p
					style={{
						textAlign: "left",
						lineHeight: "1.8",
						fontFamily: "'Roboto', sans-serif",
						marginTop: -10,
					}}
				>
					{`Merchant Fee (${paymentMethod === "Pay with Paypal" ? 3 : 1.5}%)`}
				</p>
				<p
					style={{
						textAlign: "left",
						lineHeight: "1.8",
						fontFamily: "'Roboto', sans-serif",
						marginTop: -10,
					}}
				>
					${merchantFee.toFixed(2)}
				</p>
				<br />
				<br />
				<InputLabel className={classes.inputLabel}>Total Amount: *</InputLabel>
				<TextField
					variant="outlined"
					size="small"
					placeholder="First Name"
					className={classes.textField}
					disabled
					value={totalAmount.toFixed(2)}
					InputProps={{
						classes: {
							notchedOutline: classes.notchedOutline,
							focused: classes.focused,
						},
					}}
				/>
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button
						variant="contained"
						className={classes.prevBtn}
						onClick={backHandler}
					>
						previous
					</Button>
					<Button
						variant="contained"
						className={classes.btn}
						onClick={handleClickNext}
					>
						Next
					</Button>
				</div>
			</div>
		</Fragment>
	);
}
