import React, { Component, Fragment } from 'react';
import { Grid, withStyles, InputLabel, TextField, Button } from '@material-ui/core';
import Axios from 'axios';
import baseUrl from '../../../Utils/baseUrl';

const styles = theme => ({
    formHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10
    },
    formHeading: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#09926E',
        margin: '0 0 10px',
        textAlign: 'left'
    },
    formNo: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#888888',
        margin: '0 0 10px',
    },
    inputLabel: {
        fontWeight: 'normal',
        marginBottom: 15,
        textAlign: 'left'
    },
    required: {
        color: 'red'
    },
    textField: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ECEFF1',
        color: 'black',
        // height: 30,
        width: '100%',
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutline": {
                borderColor: '#ddd',
            }
        }
    },
    notchedOutline: {
        borderColor: '#ddd',
        '&:hover': {
            borderColor: '#ddd',
        }
    },
    focused: {
        "& $notchedOutline": {
            borderColor: '#ddd !important',
        }
    },
    textFieldError: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ECEFF1',
        color: 'black',
        width: '100%',
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutlineError": {
                borderColor: 'red !important',
            }
        }
    },
    notchedOutlineError: {
        borderColor: 'red',
        '&:hover': {
            borderColor: 'red !important',
        }
    },
    focusedError: {
        "& $notchedOutlineError": {
            borderColor: '#ddd !important',
        }
    },
    btn: {
        backgroundColor: '#09926E',
        color: '#fff',
        padding: '10px 35px',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: '#F44336',
            color: '#fff',
            boxShadow: 'none',
        }
    }
});

class Personal extends Component {

    state = {
        fullName: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        country: "Australia",
        contactNumber: "",
        email: "",
        preferredContact: 'email',
        errorObj: {},
        states: [],
    }

    componentDidMount() {
        this.getStoredData();
        this.getAustralianStates();
    }

    componentDidUpdate(prevProps) {
        if (prevProps != this.props) {
            this.getStoredData();
            this.getAustralianStates();
        }
    }

    getAustralianStates = () => {
        Axios({
            url: `${baseUrl}/state/3`,
            method: "GET"
        }).then(res => {
            // console.log(res.data);
            this.setState({ states: res.data.data });
        }).catch(err => {
            console.log(err)
        })
    }

    getStoredData = () => {
        if (this.props.data) {
            const {
                fullName,
                address,
                city,
                state,
                zipCode,
                country,
                contactNumber,
                email,
                preferredContact
            } = this.props.data;

            this.setState({
                fullName: fullName,
                address: address,
                city: city,
                state: state,
                zipCode: zipCode,
                country: country,
                contactNumber: contactNumber,
                email: email,
                preferredContact: preferredContact
            })
        }
    }

    handleClickNext = () => {
        const {
            fullName,
            address,
            city,
            state,
            zipCode,
            country,
            contactNumber,
            email,
            preferredContact
        } = this.state;

        console.log(state)

        // if (fullName && address && city && state && zipCode && country && contactNumber && email && preferredContact) {
        //     this.props.nextHandler('1', { fullName, address, city, state, zipCode, country, contactNumber, email, preferredContact })
        // } else {
        //     this.handleValidation();
        // }
    }

    handleChange = e => {
        const { errorObj } = this.state;

        if (errorObj[e.target.name])
            errorObj[e.target.name] = false;

        this.setState({
            [e.target.name]: e.target.value,
            errorObj
        })
    }

    handleValidation = () => {
        const {
            fullName,
            address,
            city,
            state,
            zipCode,
            country,
            contactNumber,
            email
        } = this.state;
        let tempErrorObj = {}

        if (!fullName) {
            tempErrorObj.fullName = true
        } else {
            tempErrorObj.fullName = false
        }
        if (!address) {
            tempErrorObj.address = true
        } else {
            tempErrorObj.address = false
        }
        if (!city) {
            tempErrorObj.city = true
        } else {
            tempErrorObj.city = false
        }
        if (!state) {
            tempErrorObj.state = true
        } else {
            tempErrorObj.state = false
        }
        if (!zipCode) {
            tempErrorObj.zipCode = true
        } else {
            tempErrorObj.zipCode = false
        }
        // if (!country) {
        //     tempErrorObj.country = true
        // } else {
        //     tempErrorObj.country = false
        // }
        if (!contactNumber) {
            tempErrorObj.contactNumber = true
        } else {
            tempErrorObj.contactNumber = false
        }
        if (!email) {
            tempErrorObj.email = true
        } else {
            tempErrorObj.email = false
        }

        this.setState({ errorObj: tempErrorObj })

    }

    render() {
        const { classes } = this.props;
        const {
            fullName,
            address,
            city,
            state,
            zipCode,
            country,
            contactNumber,
            email,
            preferredContact,
            errorObj,
            states
        } = this.state;

        return (
            <Fragment>
                <div className={classes.formHeader}>
                    <h4 className={classes.formHeading}>Volunteer Information:</h4>
                    <h4 className={classes.formNo}>Step 1 - 4</h4>
                </div>
                <div>
                    <InputLabel className={classes.inputLabel} >Name: <span className={classes.required}>*</span></InputLabel>
                    <TextField
                        variant='outlined'
                        size='small'
                        name="fullName"
                        placeholder="Name"
                        value={fullName}
                        className={errorObj.fullName ? classes.textFieldError : classes.textField}
                        InputProps={{
                            classes: {
                                notchedOutline: errorObj.fullName ? classes.notchedOutlineError : classes.notchedOutline,
                                focused: errorObj.fullName ? classes.focusedError : classes.focused,
                            }
                        }}
                        onChange={this.handleChange}
                    />
                    <InputLabel className={classes.inputLabel} >Street Address: <span className={classes.required}>*</span></InputLabel>
                    <TextField
                        variant='outlined'
                        size='small'
                        name="address"
                        placeholder="Street Address"
                        value={address}
                        className={errorObj.address ? classes.textFieldError : classes.textField}
                        InputProps={{
                            classes: {
                                notchedOutline: errorObj.address ? classes.notchedOutlineError : classes.notchedOutline,
                                focused: errorObj.address ? classes.focusedError : classes.focused,
                            }
                        }}
                        onChange={this.handleChange}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={classes.inputLabel} >City: <span className={classes.required}>*</span></InputLabel>
                            <TextField
                                variant='outlined'
                                size='small'
                                name="city"
                                placeholder="City"
                                value={city}
                                className={errorObj.city ? classes.textFieldError : classes.textField}
                                InputProps={{
                                    classes: {
                                        notchedOutline: errorObj.city ? classes.notchedOutlineError : classes.notchedOutline,
                                        focused: errorObj.city ? classes.focusedError : classes.focused,
                                    }
                                }}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={classes.inputLabel} >State: <span className={classes.required}>*</span></InputLabel>
                            {/* <TextField
                                variant='outlined'
                                size='small'
                                name="state"
                                placeholder="State"
                                value={state}
                                className={errorObj.state ? classes.textFieldError : classes.textField}
                                InputProps={{
                                    classes: {
                                        notchedOutline: errorObj.state ? classes.notchedOutlineError : classes.notchedOutline,
                                        focused: errorObj.state ? classes.focusedError : classes.focused,
                                    }
                                }}
                                onChange={this.handleChange}
                            />
                                                        <InputLabel className={classes.inputLabel} >Donation Type: *</InputLabel> */}
                            <select
                                style={{
                                    backgroundColor: '#ECEFF1',
                                    height: 41,
                                    borderColor: '#ddd',
                                    width: '100%',
                                    marginBottom: 20
                                }}
                                name='state'
                                className='form-control required'
                                aria-invalid='false'
                                onChange={this.handleChange}
                            // value={donationType}
                            >
                                <option
                                    value=''
                                    selected='selected'
                                    className='gf_placeholder'
                                >
                                    ** Select State **
                                </option>
                                {
                                    states.length > 0 &&
                                    states.map((el, index) => (
                                        <option key={index} value={el.name}>
                                            {el.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={classes.inputLabel} >Zip/Post Code: <span className={classes.required}>*</span></InputLabel>
                            <TextField
                                variant='outlined'
                                size='small'
                                name="zipCode"
                                placeholder="Zip"
                                value={zipCode}
                                className={errorObj.zipCode ? classes.textFieldError : classes.textField}
                                InputProps={{
                                    classes: {
                                        notchedOutline: errorObj.zipCode ? classes.notchedOutlineError : classes.notchedOutline,
                                        focused: errorObj.zipCode ? classes.focusedError : classes.focused,
                                    }
                                }}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: "5" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={classes.inputLabel} >Country: <span className={classes.required}>*</span></InputLabel>
                            <TextField
                                variant='outlined'
                                size='small'
                                name="country"
                                placeholder="Country"
                                disabled
                                value={country}
                                className={errorObj.country ? classes.textFieldError : classes.textField}
                                InputProps={{
                                    classes: {
                                        notchedOutline: errorObj.country ? classes.notchedOutlineError : classes.notchedOutline,
                                        focused: errorObj.country ? classes.focusedError : classes.focused,
                                    }
                                }}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={classes.inputLabel} >Contact Number: <span className={classes.required}>*</span></InputLabel>
                            <TextField
                                variant='outlined'
                                size='small'
                                name="contactNumber"
                                placeholder="Contact Number"
                                value={contactNumber}
                                className={errorObj.contactNumber ? classes.textFieldError : classes.textField}
                                InputProps={{
                                    classes: {
                                        notchedOutline: errorObj.contactNumber ? classes.notchedOutlineError : classes.notchedOutline,
                                        focused: errorObj.contactNumber ? classes.focusedError : classes.focused,
                                    }
                                }}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: "11" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={classes.inputLabel} >Email: <span className={classes.required}>*</span></InputLabel>
                            <TextField
                                variant='outlined'
                                size='small'
                                name="email"
                                placeholder="Email"
                                value={email}
                                className={errorObj.email ? classes.textFieldError : classes.textField}
                                InputProps={{
                                    classes: {
                                        notchedOutline: errorObj.email ? classes.notchedOutlineError : classes.notchedOutline,
                                        focused: errorObj.email ? classes.focusedError : classes.focused,
                                    }
                                }}
                                onChange={this.handleChange}
                            />
                        </Grid>
                    </Grid>
                    <div>
                        <InputLabel className={classes.inputLabel} >Preferred Contact: <span className={classes.required}>*</span></InputLabel>
                        <label className="container fontsizee">
                            Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                 <input
                                style={{ display: 'none' }}
                                type='radio'
                                name='preferredContact'
                                checked={preferredContact === 'email'}
                                value="email"
                                onChange={(e) => { this.setState({ preferredContact: e.target.value }) }}
                            />
                            <span className='radiomark'></span>
                        </label>
                        <label className="container fontsizee">
                            SMS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                 <input
                                style={{ display: 'none' }}
                                type='radio'
                                name='preferredContact'
                                checked={preferredContact === 'sms'}
                                value='sms'
                                onChange={(e) => { this.setState({ preferredContact: e.target.value }) }}
                            />
                            <span className='radiomark'></span>
                        </label>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            className={classes.btn}
                            onClick={this.handleClickNext}
                        >
                            Next
                    </Button>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withStyles(styles)(Personal);