import React, { Fragment, useEffect } from 'react';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import fundRaising from "../../../Assets/Images/hands-in-the-air.jpg";
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

import { IoFastFoodOutline, IoHome } from 'react-icons/io5';
import { BsHouseDoor } from 'react-icons/bs';
import HouseOutlinedIcon from '@material-ui/icons/HouseOutlined';
import schoolIcon from '../../../Assets/Icons/1.png';
import healthIcon from '../../../Assets/Icons/2.png';
import emergencyIcon from '../../../Assets/Icons/3.png';
import educationIcon from '../../../Assets/Icons/4.png';

import SchoolIcon from '@material-ui/icons/School';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        // background: "#F8F8F8",
        paddingBottom: 30,
        fontSize: "62.5%",
        [theme.breakpoints.down('xs')]: {
            fontSize: "55%",
        }
    },
    punchLinesWrapper: {
        transform: "translateY(50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    heading2: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important',
        }
    },
    heading3: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important',
        }
    },
    placeholder: {
        [theme.breakpoints.down('sm')]: {
            height: 50
        }
    },
    iconsDiv: {
        backgroundColor: '#c2ebe0',
        height: 50,
        width: 50,
        borderRadius: '50%',
        marginTop: 13
    },
    iconLeft: {
        fontSize: 45,
        position: 'absolute',
        top: 13,
        left: 20
    },
    iconRight: {
        fontSize: 45,
        position: 'absolute',
        top: 15,
        right: 155,
        [theme.breakpoints.down('sm')]: {
            top: 158,
            right: 130,
        }
    }
}))

const ContactUs = (props) => {
    const classes = useStyles();
    const { dropdownData } = props;
    const [schoolGraduate, setSchoolGraduate] = React.useState(0);
    const [healthService, setHealthService] = React.useState(0);
    const [medical, setMedical] = React.useState(0);
    const [education, setEducation] = React.useState(0);
    const counterRef = React.useRef();
    const [option, setOption] = React.useState("");

    const increase = async (to, time, method) => {
        let init = 0;
        let interval = setInterval(async () => {
            await method(init);
            init++;

            if (init === to + 1)
                clearInterval(interval)
        }, time);
    }

    const scrollCallback = () => {
        let wrapper = counterRef.current;
        const parentNode = props.parentNode ? props.parentNode : { current: window };
        let running = false;
        parentNode.current.addEventListener('scroll', () => {
            if (wrapper.getBoundingClientRect().y < (window.innerHeight / 1.5) && running === false) {
                increase(100, 20, setSchoolGraduate);
                increase(45, 25, setHealthService);
                increase(200, 12, setMedical);
                increase(100, 20, setEducation);
                running = true;
            }
            if (wrapper.getBoundingClientRect().y > window.innerHeight / 1.5) {
                setSchoolGraduate(0)
                setHealthService(0)
                setMedical(0)
                setEducation(0)
                running = false;
            }
        })
    }

    useEffect(scrollCallback, [props.parentNode]);

    const handleClick = () => {
        props.history.push(`/donation/${option}`);
    }

    return (
			<Fragment>
				<Container maxWidth="md">
					<div
						className={`${classes.wrapper} funds-raising`}
						ref={props.refProp}
					>
						<div className={classes.punchLinesWrapper} ref={counterRef}>
							<Grid container spacing={1}>
								<Grid item xs={6} sm={6} md={3}>
									<div>
										{/* <img src={schoolIcon} style={{ height: 80 }} /> */}
										<div className={classes.iconsDiv}></div>
										<IoFastFoodOutline className={classes.iconLeft} />
									</div>
									{/* <div className="counter-text">
                                    <h2 className={`${classes.heading2} counter`}>{schoolGraduate}</h2>
                                    <h3 className={classes.heading3}>School Graduate</h3>
                                </div> */}
									<h1 style={{ fontSize: 30 }}>Fight against Hunger</h1>
								</Grid>
								<Grid item xs={6} sm={6} md={3}>
									<div>
										<img src={healthIcon} style={{ height: 80 }} />
									</div>
									{/* <div className="counter-text">
                                    <h2 className={`${classes.heading2} counter`}>{healthService}</h2>
                                    <h3 className={classes.heading3}>Health Service</h3>
                                </div> */}
									<h1 style={{ fontSize: 30 }}>Patient Care</h1>
								</Grid>
								<Grid item xs={6} sm={6} md={3}>
									<div>
										<img src={emergencyIcon} style={{ height: 80 }} />
									</div>
									{/* <div className="counter-text">
                                    <h2 className={`${classes.heading2} counter`}>{medical}</h2>
                                    <h3 className={classes.heading3}>Medicaln Check</h3>
                                </div> */}
									<h1 style={{ fontSize: 30 }}>Rehabilitation Center</h1>
								</Grid>
								<Grid item xs={6} sm={6} md={3}>
									<div>
										<div className={classes.iconsDiv}></div>
										{/* <img src={educationIcon} style={{ height: 80 }} /> */}
										<BsHouseDoor className={classes.iconRight} />
									</div>
									<h1 style={{ fontSize: 30 }}>Home and Orphanage</h1>
									{/* <div className="counter-text">
                                    <h2 className={`${classes.heading2} counter`}>{education}</h2>
                                    <h3 className={classes.heading3}>Education Books</h3>
                                </div> */}
								</Grid>
							</Grid>
						</div>
					</div>
				</Container>
				<div className={classes.placeholder} />
				<div
					className="funds-select"
					style={{
						// backgroundImage: `url(${fundRaising})`
						backgroundImage: `url(${fundRaising})`,
						height: 173,
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "top",
						marginTop: 100,
					}}
				>
					<Container maxWidth="sm">
						<div style={{ background: "#0000002E", padding: 41 }}>
							<select
								className="ambulanceservices"
								style={{ fontSize: "20px" }}
								name="input_3"
								id="input_1_3"
								className="medium gfield_select"
								aria-invalid="false"
								onChange={(e) => {
									setOption(e.target.value);
								}}
							>
								<option
									className="ambulanceservices"
									style={{ fontSize: "20px" }}
									value=""
									selected="selected"
									className="gf_placeholder"
								>
									** Donation **
								</option>
								{dropdownData &&
									dropdownData.length > 0 &&
									dropdownData.map(function (opt, i) {
										if (opt.category_type == "donation") {
											return (
												<option
													key={i}
													className="ambulanceservices"
													value={opt.category_title}
												>
													{opt.category_title}
												</option>
											);
										}
									})}
							</select>
							<Button onClick={handleClick} className="btn">
								Donate Now
							</Button>
						</div>
					</Container>
				</div>
			</Fragment>
		);
}

export default ContactUs;
