import React, { Fragment, useEffect, useState } from 'react';
import { Container, makeStyles, Button, Grid, Divider, CircularProgress } from '@material-ui/core';

import Axios from 'axios';
import baseUrl from '../../Utils/baseUrl';
import Description from '../../Components/Markdown';

import pic from '../../Assets/Images/Text_Banner_Web-01.jpg';

const useStles = makeStyles(theme => ({
    imageBoxParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginBottom: 40
    },
    imageBox: {
        paddingTop: 5,
        [theme.breakpoints.up('md')]: {
            paddingTop: 30,
        }
    },
    image: {
        display: 'block',
        width: '100%',
        height: 'auto'
    },
    heading: {
        fontFamily: "'vidaloka', serif",
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            marginBottom: 50,
        },
        fontSize: 40
    },
    heading2: {
        fontFamily: "'vidaloka', serif",
        fontSize: 31,
        margin: "50px 0px 15px",
        // fontWeight: 'bold'
    },
    heading3: {
        fontFamily: "'vidaloka', serif",
        textAlign: 'center',
        fontSize: 17,
        color: '#161616',
        fontWeight: 'bold'
    },
    list: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 16,
        color: '#646464',
        listStyle: 'none',
        paddingBottom: 10
    },
    links: {
        fontFamily: '"IBM Plex Sans", sans serif',
        fontSize: 16,
        lineHeight: '2',
        color: '#1696e7',
        textDecoration: 'none',
        "&:hover": {
            color: '#fd5a5a'
        }
    },
    text: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 16,
        color: '#646464'
    },
    iframeContainer: {
        visibility: 'hidden',
        [theme.breakpoints.up('sm')]: {
            border: '2px solid rgba(167, 167, 167, 0.5)',
            padding: 20,
            margin: "10px auto 0px",
            width: '66%',
            visibility: 'visible',
        },
    },
    linkContainer: {
        backgroundColor: '#f8f7f7',
        padding: '1px 0px 30px 0px',
        marginTop: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    btn: {
        backgroundColor: '#09926e',
        color: '#fff',
        border: '2px solid transparent',
        boxShadow: 'none',
        borderRadius: 0,
        padding: '14px 22px',
        marginTop: 10,
        "&:hover": {
            backgroundColor: '#fff',
            color: '#161616',
            border: '2px solid #161616',
        }
    }
}));

export default function AmbulanceFunds() {
    const classes = useStles();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        Axios({
            url: `${baseUrl}/edhi-world`,
            method: "GET",
            data: {
                type: "recognition"
            }
        }).then(res => {
            // console.log(res.data.data);
            setData(res.data.data);
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })

    }, []);

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: 500
                }}
            >
                <CircularProgress style={{ color: '#09926E', width: 80, height: 80 }} />
            </div>
        )
    }
    else {
        return (
            <Fragment>
                <h1 className={classes.heading}>
                    {data.title}
                </h1>
                <div style={{ backgroundColor: '#f8f7f7' }}>
                    <Container maxWidth="lg">
                        <Grid container spacing={1}>
                            <Grid item sm={12} md={8}>
                                <h4 className={classes.heading2}>Related articles</h4>
                                <ul style={{ margin: 0, padding: 0 }}>
                                    {
                                        data.related_articles && data.related_articles.length > 0 &&
                                        data.related_articles.map((article, index) => (
                                            <li key={index} className={classes.list}>
                                                <a href={article.link} className={classes.links}>{article.anchor_name}</a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <div className={classes.imageBox}>
                                    <img
                                        className={classes.image}
                                        style={{ width: 290 }}
                                        src={data.image}
                                        alt='banner'
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                        <div style={{ textAlign: 'center' }}>
                            <a href={data.pdf_link} target="blank" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" className={classes.btn}>Download PDF</Button>
                            </a>
                            <p className={classes.text}>A Life Well Lived: A Tribute to Abdul Sattar Edhi</p>
                            <div style={{ height: 2 }} />
                        </div>
                    </Container >
                </div>
                <br />
                <Container maxWidth="md">
                    <div className={classes.iframeContainer}>
                        <Container maxWidth="xs">
                            <p className={classes.heading3}>{data.video_title}</p>
                        </Container>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <iframe
                                width='600'
                                height='342'
                                src={data.video_link}
                                frameborder='0'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                </Container>
                <div className={classes.linkContainer}>
                    <Container maxWidth="sm">
                        <h4 style={{ textDecoration: 'underline', textAlign: 'center' }} className={classes.heading2}>Edhi Links:</h4>
                        <div className={classes.buttonContainer}>
                            <a href={data.edhi_pakistn_link} target="blank" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" className={classes.btn}>Edhi Pakistan</Button>
                            </a>
                            <a href={data.edhi_america_link} target="blank" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" className={classes.btn}>Edhi America</Button>
                            </a>
                        </div>
                    </Container>
                </div >
            </Fragment >
        )
    }
}