import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, makeStyles, Button, Grid } from "@material-ui/core";
import Axios from "axios";
import baseUrl from "../../Utils/baseUrl";
import Moment from "react-moment";
import "moment-timezone";
import useSound from "use-sound";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
	FaFacebookF,
	FaTwitter,
	FaBell,
	FaRegBell,
	FaRegBellSlash,
} from "react-icons/fa";
import { TiSocialGooglePlus, TiSocialDribbble } from "react-icons/ti";

import logo from "../../Assets/Images/logo.png";
import notificationSound from "../../Assets/notification.mp3";
import acnc from "../../Assets/Images/acnc.png";
import navbar from "./Navbar.module.css";

import ContextAPI from "../../ContextAPI/contextAPI";

const useStyles = makeStyles((theme) => ({
	upperBar: {
		backgroundColor: "#09926e",
		color: "#fff",
		position: "relative",
		width: "100%",
		height: 50,
	},
	leftSideLinks: {
		float: "left",
		margin: "5px 0px 0px 0px",
	},
	rightSideLinks: {
		float: "right",
		margin: 0,
	},
	listItems: {
		display: "inline-block",
		listStyle: "none",
		fontSize: 14,
		padding: 10,
	},
	rightBorderedLinks: {
		borderRight: "1px solid hsla(0,0%,100%,.2)",
		textDecoration: "none",
		color: "#ffffffcc",
		"&:hover": {
			color: "#fff",
		},
		paddingRight: 12,
	},
	links: {
		textDecoration: "none",
		color: "#fff",
		cursor: "pointer",
		"&:hover": {
			color: "#fff",
		},
		paddingRight: 12,
	},
	socialLinks: {
		fontSize: 16,
		margin: "5px 0px -3px 10px",
		color: "#e0e0e0",
		cursor: "pointer",
		"&:hover": {
			color: "#fd5a5a",
		},
	},
	btn: {
		color: "#fff",
		backgroundColor: "#b55555",
		height: 50,
		borderRadius: 0,
		boxShadow: "none",
		padding: "0px 20px",
		"&:hover": {
			color: "#fff",
			backgroundColor: "#fd5a5a",
		},
	},
	btn2: {
		color: "#fff",
		backgroundColor: "rgb(120, 150, 110)",
		height: 50,
		borderRadius: 0,
		boxShadow: "none",
		padding: "0px 20px",
		"&:hover": {
			color: "#fff",
			backgroundColor: "rgb(60, 150, 110)",
		},
	},
}));

// Set the timezone for every instance.
Moment.globalTimezone = "Australia/Sydney";

// Set the output timezone for local for every instance.
Moment.globalLocal = true;

export default function Navbar(props) {
	const classes = useStyles();
	const [playSound] = useSound(notificationSound);
	var [data, setData] = useState([{}]);
	const {
		siteInfo,
		user,
		isFirebaseUser,
		logoutHandler,
		firebaseLogout,
		isGuestUser,
	} = useContext(ContextAPI);

	const getNotifications = async () => {
		Axios({
			url: `${baseUrl}/get-notifications`,
			method: "GET",
		})
			.then((res) => {
				// console.log(res.data.data);
				// console.log(data[0].message);
				// console.log(res.data.data[0].message);
				if (data && data[0].message !== res.data.data[0].message) {
					setData(res.data.data);
					console.log("Flag C:" + data[0].message);

					var target = document.querySelector(".customTrigger");
					var target2 = document.querySelector(".customTrigger2");
					var target3 = document.querySelector(".customTrigger3");

					target3.setAttribute("style", "opacity:0.9;");
					playSound();

					target.addEventListener("click", showHide, false);
					function showHide() {
						if (target2.style.display === "none") {
							target2.style.display = "block";
							target3.setAttribute("style", "opacity:0;");
						} else {
							target2.style.display = "none";
						}
					}
				}
			})
			.catch((err) => {
				// console.log(err.response);
			});
	};

	useEffect(() => {
		if (window.innerWidth >= 960) {
			getNotifications();

			const interval = setInterval(() => {
				getNotifications();
			}, 60000);

			return () => clearInterval(interval);
		}
	}, [data]);

	return (
		<Fragment>
			<div style={{ backgroundColor: "#09926e" }}>
				<Container maxWidth="lg">
					<div className={classes.upperBar}>
						<ul className={classes.leftSideLinks}>
							<li className={classes.listItems}>
								<PhoneIcon
									style={{ fontSize: 16, margin: "5px 0px -3px 0px" }}
								/>
								{siteInfo && siteInfo.phone && (
									<a
										href={`tel:${siteInfo.phone}`}
										className={classes.rightBorderedLinks}
									>
										{siteInfo.phone}
									</a>
								)}
							</li>
							<li className={classes.listItems}>
								<MailIcon
									style={{ fontSize: 16, margin: "5px 5px -3px 10px" }}
								/>
								{siteInfo && siteInfo.email && (
									<a
										href={`mailto:${siteInfo.email}`}
										className={classes.links}
									>
										{siteInfo.email}
									</a>
								)}
							</li>
						</ul>
						<ul className={classes.rightSideLinks}>
							<li className={classes.listItems}>
								<FaFacebookF className={classes.socialLinks} />
							</li>
							<li className={classes.listItems}>
								<FaTwitter className={classes.socialLinks} />
							</li>
							<li
								className={classes.listItems}
								style={{ padding: "0px 5px 0px 5px" }}
							>
								<TiSocialGooglePlus
									className={classes.socialLinks}
									style={{ fontSize: 25, marginBottom: -7 }}
								/>
							</li>
							<li
								className={classes.listItems}
								style={{ padding: "0px 10px 0px 5px" }}
							>
								<TiSocialDribbble
									className={classes.socialLinks}
									style={{ fontSize: 22, marginBottom: -6 }}
								/>
							</li>
							<li className={classes.listItems} style={{ padding: 0 }}>
								<Button
									className={classes.btn}
									onClick={() => {
										props.history.push("/donation");
									}}
								>
									DONATE NOW
								</Button>
								{/* <Link to="/my-account/profile">DONATE NOW</Link> */}
							</li>
							{(user && user.first_name) || isGuestUser ? (
								<li className={classes.listItems} style={{ padding: 0 }}>
									<Button
										className={classes.btn2}
										onClick={() => {
											props.history.push("/donation-history");
										}}
									>
										Donation History
									</Button>
									{/* <Link to="/donation-history" className={classes.links}>
										Donation History
									</Link> */}
								</li>
							) : (
								""
							)}
							{(user && user.first_name) || isGuestUser ? (
								<li className={classes.listItems}>
									<Link to="/profile" className={classes.links}>
										{isGuestUser ? "GUEST" : user.first_name.toUpperCase()}
									</Link>
								</li>
							) : (
								<li className={classes.listItems}>
									<Link to="/login" className={classes.links}>
										Login
									</Link>
								</li>
							)}
							{(user && user.first_name) || isGuestUser ? (
								<li className={classes.listItems}>
									<Link
										onClick={isFirebaseUser ? firebaseLogout : logoutHandler}
										className={classes.links}
									>
										Logout
									</Link>
								</li>
							) : (
								<li className={classes.listItems}>
									<Link to="/register" className={classes.links}>
										Register
									</Link>
								</li>
							)}
							<li className={classes.listItems}>
								<div className={navbar.dropdown2 + " customTrigger"}>
									<span>
										<FaBell
											title="Notifications"
											className={
												data.length > 0
													? navbar.notifications + " " + navbar.animation
													: navbar.notifications
											}
										/>
										<span
											className={
												data.length > 0
													? navbar.redDot +
													  " " +
													  navbar.show +
													  " customTrigger3"
													: navbar.redDot
											}
										></span>
									</span>
									<div className={navbar.dropdownContent2 + " customTrigger2"}>
										<h3
											style={{
												padding: "1em",
												fontFamily: "unset",
												fontWeight: "bold",
											}}
										>
											Notifications
										</h3>
										<SimpleBar
											forceVisible="y"
											autoHide={false}
											style={{ maxHeight: 420 }}
										>
											<ul>
												{data.length === 0 ? (
													<li>
														<FaRegBellSlash
															style={{
																marginRight: 5,
																marginLeft: -19,
																color: "#09926e",
															}}
														/>
														<span style={{ color: "#929494" }}>
															No New Notifications
														</span>
													</li>
												) : (
													data.length > 0 &&
													data.map((val, index) => (
														<li>
															<FaRegBell
																style={{
																	marginRight: 5,
																	marginLeft: -19,
																	color: "#09926e",
																}}
															/>
															<span
																style={{
																	color: "#666",
																	fontWeight: 600,
																	fontSize: "0.9rem",
																}}
															>
																{val.title}
															</span>
															<br />
															<span
																style={{ color: "#929494", fontSize: "0.9rem" }}
															>
																{val.message}
															</span>
															<br />
															<span
																style={{
																	color: "#b3c3c3",
																	fontSize: "0.75rem",
																}}
															>
																<Moment toNow ago tz="Australia/Sydney">
																	{new Date(val.created_at).setHours(
																		new Date(val.created_at).getHours() + 6
																	)}
																</Moment>{" "}
																ago
															</span>
														</li>
													))
												)}
											</ul>
										</SimpleBar>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</Container>
			</div>
			<Container>
				<Grid container spacing={1}>
					<Grid item md={3}>
						<div className={navbar.logo}>
							<Link to="/">
								<img src={logo} alt="logo" />
							</Link>
						</div>
					</Grid>
					<Grid item md={8}>
						<div className={navbar.mainMenu}>
							<ul style={{ float: "right" }}>
								<li className={navbar.menuItem}>
									<Link to="/">Home </Link>
								</li>
								<li className={navbar.menuItem}>
									<div className={navbar.dropdown}>
										<span className={navbar.dropdownbutton}>
											Organization{" "}
											<ArrowDropDownIcon style={{ marginBottom: -5 }} />
										</span>
										<div className={navbar.dropdownContent}>
											<Link to="/chairman-message">
												Chairman's&nbsp;Message
											</Link>
											<Link to="/recognition">Recognition</Link>
											<Link to="/vision">Vision</Link>
										</div>
									</div>
								</li>
								<li className={navbar.menuItem}>
									<div className={navbar.dropdown}>
										<span className={navbar.dropdownbutton}>
											Services{" "}
											<ArrowDropDownIcon style={{ marginBottom: -5 }} />
										</span>
										<div className={navbar.dropdownContent}>
											<Link to="/ambulance-fund">Ambulance&nbsp;Fund</Link>
											<Link to="/fight-against-hunger">
												Fight&nbsp;against&nbsp;Hunger
											</Link>
											<Link to="/home-and-orphanage">
												Home&nbsp;And&nbsp;Orphanage
											</Link>
											<Link to="/patient-care">Patient&nbsp;Care</Link>
											<Link to="/rehabilitation-center">
												{" "}
												Rehabilitation&nbsp;Center
											</Link>
											<Link to="/flood-relief"> Flood Relief</Link>
										</div>
									</div>
								</li>
								<li className={navbar.menuItem}>
									<Link to="/edhi-world">Edhi World</Link>
								</li>
								<li className={navbar.menuItem}>
									<Link to="/events">Events</Link>
								</li>
								<li className={navbar.menuItem}>
									<Link to="/gallery">Gallery</Link>
								</li>
								<li className={navbar.menuItem}>
									<Link to="/contact">Contact</Link>
								</li>
							</ul>
						</div>
					</Grid>
					<Grid item md={1}>
						<div>
							<Link to="/">
								<img src={acnc} alt="acnc" />
							</Link>
						</div>
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
}
