import React, { Fragment } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    btn: {
        backgroundColor: '#fd5a5a',
        borderRadius: '50%',
        display: 'block',
        position: 'fixed',
        height: 40,
        width: 40,
        padding: 5,
        cursor: 'pointer',
        opacity: 1,
        right: 5,
        bottom: 20,
        zIndex: 9999,
        transition: 'opacity 0.5s ease-in-out 0s, left',
        "&:hover": {
            backgroundColor: '#000',
        }
    },
    icon: {
        color: '#fff',
        fontSize: '2em',
        borderRadius: 18,
        margin: 5
    }
}));

export default function ChatIconButton() {
    const classes = useStyles();

    // console.log(window.clientHeight)
    // if (window.clientHeight === 100) {
    //     console.log("test")
    // }

    return (
        <Fragment >
            <span
                className={classes.btn}
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }}
            >
                <ArrowUpwardIcon className={classes.icon} />
            </span>
        </Fragment>
    )
}