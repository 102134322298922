import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import baseUrl from '../../Utils/baseUrl';
import Description from '../../Components/Markdown';

const useStles = makeStyles(theme => ({
    imageBox: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 70
        }
    },
    heading: {
        fontFamily: "'vidaloka', serif",
        textAlign: 'center',
        marginBottom: 50
    },
    text: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 16,
        color: '#646464'
    },
    description: {
        "& p": {
            fontFamily: '"IBM Plex Sans", sans serif',
            lineHeight: '1.7',
            fontSize: 16,
            color: '#646464'
        }
    }
}));

export default function ChairmanMessage() {
    const classes = useStles();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        Axios({
            url: `${baseUrl}/general-pages`,
            method: "POST",
            data: {
                type: "msg_chairman"
            }
        }).then(res => {
            setData(res.data.data[0]);
            setLoading(false)
        }).catch(err => {
            console.log(err.response.data);
            setLoading(false)
        })

    }, []);

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: 500
                }}
            >
                <CircularProgress style={{ color: '#09926E', width: 80, height: 80 }} />
            </div>
        )
    }
    else {
        return (
            <Container maxWidth="lg">
                <h1 className={classes.heading}>
                    {data.title}
                </h1>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={4}>
                        <img
                            className={classes.imageBox}
                            style={{ width: '365px' }}
                            src={data && data.image}
                            alt='banner'
                        />
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid className={classes.description} item sm={12} md={6}>
                        <Description description={data.description} />
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </Container >
        )
    }
}