import React from 'react';
import { makeStyles, ListItem, ListItemText } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        "& .MuiPaper-elevation1": {
            boxShadow: 'none'
        },
        "& .MuiAccordion-root:before": {
            backgroundColor: '#fff'
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    listItem: {
        backgroundColor: '#fff',
        color: '#646464',
        '&:hover': {
            backgroundColor: '#09926E',
            color: '#fff',
        },
    },
}));

export default function SimpleAccordion(props) {
    const classes = useStyles();

    const handleButtonPress = (path) => {
        props.history.push(path);
        props.handleClose();
    }

    return (
			<div className={classes.root}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>Organization</Typography>
					</AccordionSummary>
					<AccordionDetails style={{ display: "block" }}>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								handleButtonPress("/chairman-message");
							}}
						>
							<ListItemText primary="Chairman's Message" />
						</ListItem>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								handleButtonPress("/recognition");
							}}
						>
							<ListItemText primary="Recognition" />
						</ListItem>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								handleButtonPress("/vision");
							}}
						>
							<ListItemText primary="Vision" />
						</ListItem>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography className={classes.heading}>Services</Typography>
					</AccordionSummary>
					<AccordionDetails style={{ display: "block" }}>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								handleButtonPress("/ambulance-fund");
							}}
						>
							<ListItemText primary="Ambulance Fund" />
						</ListItem>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								handleButtonPress("/fight-against-hunger");
							}}
						>
							<ListItemText primary="Fight Against Hunger" />
						</ListItem>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								handleButtonPress("/home-and-orphanage");
							}}
						>
							<ListItemText primary="Home&nbsp;And&nbsp;Orphanage" />
						</ListItem>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								handleButtonPress("/patient-care");
							}}
						>
							<ListItemText primary="Patient&nbsp;Care" />
						</ListItem>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								handleButtonPress("/rehabilitation-center");
							}}
						>
							<ListItemText primary="Rehabilitation&nbsp;Center" />
						</ListItem>
						<ListItem
							button
							className={classes.listItem}
							onClick={() => {
								handleButtonPress("/flood-relief");
							}}
						>
							<ListItemText primary="Flood&nbsp;Relief" />
						</ListItem>
					</AccordionDetails>
				</Accordion>
			</div>
		);
}
