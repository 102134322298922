import React, { Fragment } from 'react';
import { makeStyles, Container } from '@material-ui/core';

import success from '../../../Assets/Images/success.png';

const styles = makeStyles(theme => ({
    formHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10
    },
    formHeading: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#09926E',
        margin: '0 0 10px',
        textAlign: 'left'
    },
    formNo: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#888888',
        margin: '0 0 10px',
    },
}));

export default function Completed() {
    const classes = styles();

    return (
        <Fragment>
            <div className={classes.formHeader}>
                <h4 className={classes.formHeading}>Completed</h4>
                <h4 className={classes.formNo}>Finish</h4>
            </div>
            <h2 style={{ fontSize: 20, fontWeight: 'bold', color: '#09926E', textAlign: 'center' }}>
                <strong>SUCCESS !</strong>
            </h2>
            <br />
            <Container maxWidth="xs">
                <div style={{ margin: "0px auto", width: '100%', maxWidth: 120 }}>
                    <img src={success} style={{ display: 'block', width: 120, objectFit: 'cover' }} />
                </div>
                <br />
                <br />
                <div>
                    <h5 style={{ fontSize: 12.5, color: '#09926E', textAlign: 'center' }}>
                        You Have Successfully Applied
                        </h5>
                </div>
            </Container>
        </Fragment>
    )
}