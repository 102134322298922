import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import classes from '../Landing.module.css';

export default function CustomCards(props) {
    const { data } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // if (data.title == "COVID-19 Appeal") {

    return (
        <Fragment>
            <div className={classes.card}>
                <div className='single-work-img' onClick={handleClickOpen}>
                    <img src={data.image} alt={data.image} />
                </div>
                <div className={classes.cardContentBox}>
                    <h3 className={classes.cardTitle}>
                        <Link to='#' style={{ textDecoration: 'none', color: ' inherit' }}>{data.title}</Link>
                    </h3>
                    <div className={classes.cardContent}>
                        <p>{data.description.substring(0, 80)}...
                            <span style={{ color: "#000", fontWeight: 'bold', float: 'right'}} onClick={handleClickOpen}> read more</span>
                        </p>
                    </div>
                    <Link to='/donation' style={{ textDecoration: 'none', color: ' inherit' }}>
                        <Button className={classes.cardButton}>
                            Donate Now
                        </Button>
                    </Link>
                </div>
            </div>
            <Dialog
                fullWidth
                maxWidth="sm"
                onClose={handleClose}
                open={open}
            >
                <div style={{ padding: 20 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div style={{ padding: 15 }}>
                        <h4 className={classes.cardTitle}>{data.title}</h4>
                    </div>
                    <p className={classes.cardContent}>{data.description}</p>
                </div>
            </Dialog>
        </Fragment>
    )
    // }
    // else {
    //     let path = "/";

    //     if (data.title == "Ambulance Fund") {
    //         path = "/ambulance-fund";
    //     }
    //     else if (data.title == "Fight against hunger") {
    //         path = "/fight-against-hunger";
    //     }
    //     else if (data.title == "Home and Orphanage") {
    //         path = "/home-and-orphanage";
    //     }
    //     else if (data.title == "Patient Care") {
    //         path = "/patient-care";
    //     }
    //     else if (data.title == "Rehabilitation Centers") {
    //         path = "/rehabilitation-center";
    //     }

    //     return (
    //         <Fragment>
    //             <div className={classes.card}>
    //                 <Link to={`${path}`} style={{ textDecoration: 'none', color: ' inherit' }}>
    //                     <div className='single-work-img'>
    //                         <img src={data.image} alt={data.image} />
    //                     </div>
    //                 </Link>
    //                 <div className={classes.cardContentBox}>
    //                     <h3 className={classes.cardTitle}>
    //                         <Link to='#' style={{ textDecoration: 'none', color: ' inherit' }}>{data.title}</Link>
    //                     </h3>
    //                     <div className={classes.cardContent}>
    //                         <p>{data.description}
    //                             <Link to={`${path}`} style={{ textDecoration: 'none', color: ' #000', fontWeight: 'bold' }}> read more</Link>
    //                         </p>
    //                     </div>
    //                     <Link to='/donation' style={{ textDecoration: 'none', color: ' inherit' }}>
    //                         <Button className={classes.cardButton}>
    //                             Donate Now
    //                         </Button>
    //                     </Link>
    //                 </div>
    //             </div>
    //         </Fragment>
    //     )
    // }
}
