import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import Navbar from '../Components/Navbar/Navbar';
import NavMenu from '../Components/Navbar/NavMenu';
import Routes from '../Routes/Routes';
import Footer from '../Components/Footer';
import ScrollButton from '../Components/ScrollToTop';

import Axios from 'axios';
import baseUrl from '../Utils/baseUrl';
import firebase from '../Utils/FirebaseConfig';

import ContextAPI from '../ContextAPI/contextAPI';

const styles = makeStyles(theme => ({
    navbar: {
        marginBottom: 5,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

function Layout(props) {
	const classes = styles();
	const theme = useTheme();
	const largeScreens = useMediaQuery(theme.breakpoints.up("md"));
	const [showScrollUpButton, setShowScrollUpButton] = useState(false);
	const [siteInfo, setSiteInfo] = useState({});
	const [user, setUser] = useState({});
	const [token, setToken] = useState(null);
	const [isGuestUser, setGuestUser] = useState(false);
	const [isFirebaseUser, setFirebaseUser] = useState(false);

	useEffect(() => {
		handleUserAuthStateChange();

		Axios({
			url: `${baseUrl}/site-info`,
			method: "POST",
		})
			.then((res) => {
				let tempSiteInfo = {
					email: res.data.data[0].email,
					phone: res.data.data[0].phone,
					address: res.data.data[0].address,
				};
				setSiteInfo(tempSiteInfo);
			})
			.catch((err) => {
				if (err && err.response) {
					// console.log(err.response.data)
				}
			});
	}, []);

	useEffect(() => {
		const listenScroll = document.addEventListener("scroll", handleScroll);

		return () => {
			document.removeEventListener("scroll", listenScroll);
		};
	});

	const handleScroll = () => {
		const winScroll =
			document.body.scrollTop || document.documentElement.scrollTop;

		if (winScroll > 0) {
			setShowScrollUpButton(true);
		} else {
			if (showScrollUpButton) {
				setShowScrollUpButton(false);
			}
		}
	};

	const handleUserAuthStateChange = () => {
		const token = localStorage.getItem("token");
		const userId = localStorage.getItem("userId");
		console.log(userId, token);
		if (userId) {
			Axios({
				url: `${baseUrl}/user`,
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: {
					user_id: userId,
				},
			})
				.then((res) => {
					console.log("userdata: " + res.data.data);
					setUser(res.data.data);
					setToken(token);
				})
				.catch((err) => {
					if (err && err.response) {
						// console.log(err.response.data)
					}
				});
		} else {
			handleFirebaaseLogin();
		}
	};

	const handleFirebaaseLogin = () => {
		firebase.auth().onAuthStateChanged(function (user) {
			// console.log(user)
			if (user) {
				setUser({
					email: user.email,
					first_name: user.displayName,
				});
				setFirebaseUser(true);
			} else {
				// No user is signed in.
			}
		});
	};

	const handleGuestUser = () => {
		localStorage.removeItem("token");
		setUser({});
		setGuestUser(true);
	};

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("userId");
		setUser({});
		setToken(null);
		window.location.replace("/");
	};

	const handleFirebaseLogout = () => {
		firebase
			.auth()
			.signOut()
			.then(() => {
				setUser({});
				setFirebaseUser(false);
				window.location.replace("/");
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<Fragment>
			{showScrollUpButton && <ScrollButton />}
			<ContextAPI.Provider
				value={{
					siteInfo: siteInfo,
					user: user,
					token: token,
					isFirebaseUser: isFirebaseUser,
					isGuestUser: isGuestUser,
					authStatChange: handleUserAuthStateChange,
					firebaseUserHandler: handleFirebaaseLogin,
					guestUserHandler: handleGuestUser,
					logoutHandler: handleLogout,
					firebaseLogout: handleFirebaseLogout,
				}}
			>
				{largeScreens ? <Navbar {...props} /> : <NavMenu {...props} />}

				<Routes />

				<Footer />
			</ContextAPI.Provider>
		</Fragment>
	);
}

export default withRouter(Layout);
