import React, { Fragment, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, makeStyles } from "@material-ui/core";
import { FaCalendarAlt } from "react-icons/fa";

import ContextAPI from "../ContextAPI/contextAPI";

const useStles = makeStyles((theme) => ({
	heading: {
		fontFamily: "'vidaloka', serif",
		textAlign: "center",
		fontSize: 40,
		marginBottom: 0,
	},
	heading2: {
		fontFamily: "'vidaloka', serif",
		fontSize: 16,
		margin: "5px 0px 10px",
		fontWeight: "bold",
		marginBottom: 30,
	},
	text: {
		fontFamily: '"IBM Plex Sans", sans serif',
		lineHeight: "1.7",
		fontSize: 19,
		color: "#646464",
		padding: "0px 10px",
		margin: 0,
	},
	upperFooter: {
		backgroundColor: "#fff",
		textAlign: "center",
		padding: "40px 0px 100px",
	},
	list: {
		listStyle: "none",
	},
	links: {
		fontFamily: "'vidaloka', serif",
		fontSize: 31,
		color: "#191919",
		fontWeight: "bold",
		textDecoration: "none",
		"&:hover": {
			color: "#fd5a5a",
		},
	},
	bottomFooter: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		color: "#fff",
	},
	menuSide: {
		position: "fixed",
		right: "9.8em",
		top: "35%",
		width: 0,
		zIndex: 99,
		"& a": {
			fontWeight: "bold",
			position: "relative",
			display: "block",
			color: "#151d41",
			padding: "10px 20px 30px 12px",
			backgroundColor: "rgba(0, 0, 0, 0.1)",
			borderRadius: "50px 0 0 50px",
			width: 220,
			height: 10,
			marginBottom: 5,
			fontSize: "1.5em",
			transform: "translateX(110px)",
			transition: "all 0.5s ease",
		},
		"& a:hover": {
			backgroundColor: "rgb(9, 146, 110)",
			color: "white",
			textDecoration: "unset",
			transform: "translateX(-90px)",
		},
		"& a:hover > span": {
			opacity: 1,
		},
		"& a svg": {
			paddingRight: 5,
		},
		"& a span": {
			fontSize: "0.7em",
			verticalAlign: 3,
			opacity: 0,
		},
	},
}));

export default function AmbulanceFunds() {
	const classes = useStles();
	const { siteInfo } = useContext(ContextAPI);

	useEffect(() => {
		// var target1 = document.querySelector(".sideTrigger a");
		// var target2 = document.querySelector(".sideTrigger a span");
		// target1.addEventListener("mouseover", show, false);
		// target1.addEventListener("mouseout", hide, false);
		// function show() {
		// 	target1.setAttribute(
		// 		"style",
		// 		"background-color:rgb(9, 146, 110); color:white; text-decoration:unset; transform:translateX(-90px);"
		// 	);
		// 	target2.setAttribute("style", "opacity:1;");
		// }
		// function hide() {
		// 	target1.setAttribute(
		// 		"style",
		// 		"background-color:rgba(0, 0, 0, 0.1); color:#151d41; text-decoration:unset; transform:translateX(110px);"
		// 	);
		// 	target2.setAttribute("style", "opacity:0;");
		// }
		// setTimeout(() => {
		// 	show();
		// }, 2000);
		// setTimeout(() => {
		// 	hide();
		// }, 10000);
	}, []);

	return (
		<Fragment>
			<div className={classes.upperFooter}>
				<Container maxWidth="xs">
					<h6 className={classes.heading2}>EDHI WELFARE FOUNDATION</h6>
					<h1 className={classes.heading}>Contact Us</h1>
					{siteInfo && siteInfo.address && (
						<p className={classes.text}>{siteInfo.address}</p>
					)}
					<ul style={{ margin: 0, padding: 0 }}>
						<li className={classes.list}>
							{siteInfo && siteInfo.phone && (
								<a href={`tel:${siteInfo.phone}`} className={classes.links}>
									Phone: {siteInfo.phone}
								</a>
							)}
						</li>
						<li className={classes.list}>
							{siteInfo && siteInfo.email && (
								<a href={`mailto:${siteInfo.email}`} className={classes.links}>
									Email: {siteInfo.email}
								</a>
							)}
						</li>
					</ul>
				</Container>
			</div>
			{/* <div className={classes.menuSide + " sideTrigger"}>
				<Link to="/ramadan-calendar">
					<FaCalendarAlt />
					<span>Ramadan Calendar 2022</span>
				</Link>
			</div> */}
			<div style={{ backgroundColor: "#09926e" }}>
				<Container maxWidth="lg">
					<div className={classes.bottomFooter}>
						<p style={{ margin: "7px 0" }}>
							©2022 EDHI INTERNATIONAL FOUNDATION AUSTRALIA.
						</p>
						<p style={{ margin: "7px 0", display: "inline-block" }}>
							ALL RIGHTS RESERVED.{" "}
							<span style={{ display: "none" }}>
								{" "}
								POWERED BY{" "}
								<a
									href="https://celerinnovations.com/"
									target="blank"
									style={{ textDecoration: "none", fontWeight: "bold" }}
								>
									<span style={{ color: "indianred" }}>CELER</span>
									<span style={{ color: "white" }}>INNOVATIONS</span>
								</a>
							</span>
						</p>
					</div>
				</Container>
			</div>
		</Fragment>
	);
}
