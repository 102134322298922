import React, { Fragment, useEffect, useState } from 'react';
import { Container, makeStyles, Grid, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import baseUrl from '../../Utils/baseUrl';
import Card from './Components/Card';

const useStles = makeStyles(theme => ({
    heading: {
        fontFamily: "'vidaloka', serif",
        textAlign: 'center',
        marginBottom: 50
    },
    cardContainer: {
        padding: 10
    }
}));

export default function AmbulanceFunds() {
    const classes = useStles();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        Axios({
            url: `${baseUrl}/gallery`,
            method: "GET"
        }).then(res => {
            // console.log(res.data)
            setData(res.data.data);
            setLoading(false)
        }).catch(err => {
            console.log(err.response.data);
            setLoading(false)
        })

    }, []);

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: 500
                }}
            >
                <CircularProgress style={{ color: '#09926E', width: 80, height: 80 }} />
            </div>
        )
    }
    else {

        return (
            <Fragment>
                <h1 className={classes.heading}>Gallery </h1>
                <Container maxWidth="lg">
                    <div className={classes.cardContainer}>
                        <Grid container spacing={0}>
                            {
                                data.length > 0 && data.map((val, index) => (
                                    <Grid item xs={12} sm={6} md={3} key={index}>
                                        <Card data={val} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </div>
                </Container >
            </Fragment>
        )
    }
}
