import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, withStyles, InputLabel, TextField, Button, List, ListItem, ListItemIcon, ListItemText, Paper } from '@material-ui/core';
//Icons
import { FaRegUserCircle } from 'react-icons/fa';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import LockIcon from '@material-ui/icons/Lock';

import ContextAPI from '../../ContextAPI/contextAPI';

const styles = theme => ({
    profileNavHeading: {
        listStyle: 'none',
        textDecoration: 'none',
        color: '#008081',
        fontSize: '21px',
        marginBottom: '20px',
    },
    profileNav: {
        listStyle: 'none',
        textDecoration: 'none',
        color: '#474747',
        fontSize: '17px',
        margin: '5px',
        cursor: 'pointer'
    },
    listItem: {
        backgroundColor: '#fff',
        color: '#666666',
        margin: 3,
        '&:hover': {
            backgroundColor: '#09926E',
            color: '#fff',
        },
    },
    listItemActive: {
        margin: 3,
        backgroundColor: '#087059',
        color: '#fff',
    },
    formHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10
    },
    formHeading: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#09926E',
        margin: '0 0 10px',
        textAlign: 'left'
    },
    formNo: {
        fontFamily: '"IBM Plex Sans", sans serif',
        lineHeight: '1.7',
        fontSize: 20,
        color: '#888888',
        margin: '0 0 10px',
    },
    inputLabel: {
        fontWeight: 'normal',
        marginBottom: 15,
        textAlign: 'left'
    },
    textField: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ECEFF1',
        color: 'black',
        // height: 30,
        width: '100%',
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutline": {
                borderColor: '#ddd',
            }
        }
    },
    notchedOutline: {
        borderColor: '#ddd',
        '&:hover': {
            borderColor: '#ddd',
        }
    },
    focused: {
        "& $notchedOutline": {
            borderColor: '#ddd !important',
        }
    },
    textFieldError: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: '#ECEFF1',
        color: 'black',
        width: '100%',
        marginBottom: 20,
        '&:hover': {
            "& $notchedOutlineError": {
                borderColor: 'red !important',
            }
        }
    },
    notchedOutlineError: {
        borderColor: 'red',
        '&:hover': {
            borderColor: 'red !important',
        }
    },
    focusedError: {
        "& $notchedOutlineError": {
            borderColor: '#ddd !important',
        }
    },
    btn: {
        backgroundColor: '#DC3545',
        color: '#fff',
        padding: '10px 35px',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: '#DC3545',
            color: '#fff',
            boxShadow: 'none',
        }
    },
    hide: {
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    }
});

class EditProfile extends Component {

    static contextType = ContextAPI;

    componentDidMount() {
        setTimeout(() => {
            const { user, isGuestUser } = this.context;

            if (!user.email) {
                this.props.history.push("/login")
            }
        }, 3000)
    }

    render() {
        const { classes } = this.props;
        const { user } = this.context;
        console.log(user)
        return (
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item className={classes.hide} sm={4} md={3}>
                        <List>
                            <Link to="/profile" style={{ textDecoration: 'none', color: '#666666' }}>
                                <ListItem className={window.location.pathname === '/profile' ? classes.listItemActive : classes.listItem}>
                                    <ListItemIcon style={{ minWidth: '40px', width: '40px', color: 'inherit' }}>
                                        <FaRegUserCircle style={{ fontSize: 21, color: 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="My Profile" />
                                </ListItem>
                            </Link>
                            <Link to="/edit-profile" style={{ textDecoration: 'none', color: '#666666' }}>
                                <ListItem className={window.location.pathname === '/edit-profile' ? classes.listItemActive : classes.listItem}>
                                    <ListItemIcon style={{ minWidth: '40px', width: '40px', color: 'inherit' }}>
                                        <EditIcon style={{ fontSize: 21, color: 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit Profile" />
                                </ListItem>
                            </Link>
                            <Link to="/change-password" style={{ textDecoration: 'none', color: '#666666' }}>
                                <ListItem className={window.location.pathname === '/change-password' ? classes.listItemActive : classes.listItem}>
                                    <ListItemIcon style={{ minWidth: '40px', width: '40px', color: 'inherit' }}>
                                        <LockIcon style={{ fontSize: 21, color: 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Change Password" />
                                </ListItem>
                            </Link>
                            <Link to="/donation-history" style={{ textDecoration: 'none', color: '#666666' }}>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon style={{ minWidth: '40px', width: '40px', color: 'inherit' }}>
                                        <HistoryIcon style={{ fontSize: 21, color: 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Donation History" />
                                </ListItem>
                            </Link>
                        </List>
                    </Grid>
                    <Grid xs={12} sm={8} md={9}>
                        <Paper elevation={3} style={{ padding: "50px 30px" }}>
                            <div className={classes.formHeader}>
                                <h4 className={classes.formHeading}>My Profile:</h4>
                            </div>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <strong>Name:</strong> {user.first_name && `${user.first_name} ${user.last_name}`}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <strong> E-Mail Address:</strong> {user.email && user.email}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <strong> Contact Number:</strong>  {user.mobile && user.mobile}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <strong> State:</strong>  {user.state && user.state}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <strong> Country:</strong>  {user.country && user.country}
                                </Grid>
                                <Grid item xs={12} sm={6}></Grid>
                                <Grid item xs={12} sm={6}></Grid>
                                <Grid item xs={12} sm={6}></Grid>
                            </Grid>
                            <div style={{ height: 150 }} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container >
        )
    }
}

export default withStyles(styles)(EditProfile);